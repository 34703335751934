import './Error.scss';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import Button from '../Button';
import TextTitle from '../TextTitle';
import TextBody from '../TextBody';

const Error = (props) => {
  const { onClose, label, errorLabel = null } = props;

  return (
    <div className="alert-error" onClick={onClose}>
      <div className="alert-error__content">
        <div className="alert-error__header">
          <TextTitle type="sub" className="alert-error__label">
            {errorLabel || 'An error has occurred'}
          </TextTitle>
          <CloseIcon className="alert-error__close" onClick={onClose} />
        </div>
        <TextBody>{label}</TextBody>
        <Button onClick={onClose}>OK</Button>
      </div>
    </div>
  );
};

Error.propTypes = {
  onClose: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  errorLabel: PropTypes.string,
};

export default Error;
