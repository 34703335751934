import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import TextTitle from '../../elements/TextTitle';

dayjs.extend(advancedFormat);

const PatientNoShow = () => {
  return (
    <div className="appointment-no-show">
      <TextTitle type="sub">Appointment Finished</TextTitle>
      Our records indicate that you have missed your appointment. If you have
      any questions, please email us at{' '}
      <a href="mailto:clinic@healthandher.com">clinic@healthandher.com</a>
    </div>
  );
};

export default PatientNoShow;
