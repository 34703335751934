import './PreAppointment.scss';
import React, { useState } from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { useDispatch } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import TextTitle from '../../elements/TextTitle';
import TextBody from '../../elements/TextBody';
import Button from '../../elements/Button';
import AlertConfirm from '../../elements/alert/Confirm';
import AlertInfo from '../../elements/alert/Info';
import {
  AppointmentStatuses,
  replaceAppointment,
} from '../../../slices/appointmentsSlice';
import { replaceSchedule } from '../../../slices/scheduleSlice';
import { cancel } from '../../../services/api/apiAppointment';

dayjs.extend(localizedFormat);
dayjs.extend(advancedFormat);

const PreAppointment = (props) => {
  const { appointment } = props;
  const dispatch = useDispatch();
  const [showCancelConfirm, setShowCancelConfirm] = useState(false);
  const [infoAlertText, setInfoAlertText] = useState('');
  const history = useHistory();
  const [apiError, setApiError] = useState();
  const [showAlertConfirm, setShowAlertConfirm] = React.useState(false);

  const onClickJoinAppointment = () => {
    history.push(`/appointments/${appointment._id}/in-progress`);
  };

  const onClickCancelAppointment = () => {
    setShowCancelConfirm(true);
  };

  const onRescheduleAppointment = () => {
    setShowAlertConfirm(true);
  };

  const onCancelConfirmNo = () => {
    setShowCancelConfirm(false);
  };

  const hoursUntilAppointment = dayjs(appointment.startAt).diff(
    dayjs(),
    'hour'
  );

  const onCancelConfirmYes = async () => {
    try {
      const details = {
        appointmentId: appointment._id,
      };
      const { appointment: cancelledAppointment, schedule } = await cancel(
        details
      );
      dispatch(replaceAppointment(cancelledAppointment));
      dispatch(replaceSchedule(schedule));
    } catch (err) {
      setApiError(
        'Unable to cancel appointment. If the problem continues please email clinic@healthandher.com.'
      );
    }
  };

  const onCloseAlertInfo = () => {
    setInfoAlertText('');
  };

  const onCloseAlertApiInfo = () => {
    setApiError(null);
  };

  const renderJoinAppointmentButton = () => {
    const attendAppointmentLink = '/attend';

    return (
      <a
        href={attendAppointmentLink}
        target="_blank"
        rel="noreferrer noopener"
        onClick={onClickJoinAppointment}
      >
        <Button color="primary">Begin Appointment</Button>
      </a>
    );
  };

  return (
    <div className="appointment-pre-appointment">
      {infoAlertText.length > 0 && (
        <AlertInfo
          title="Begin Appointment"
          label={infoAlertText}
          onClose={onCloseAlertInfo}
        />
      )}

      {apiError && (
        <AlertInfo
          title="Cannot cancel appointment"
          label={apiError}
          onClose={onCloseAlertApiInfo}
        />
      )}

      <Link
        to="/files/health-and-her-clinic-video-consultation-guide.pdf"
        target="_blank"
      >
        <Button color="primary-link">Video Consultation Guide</Button>
      </Link>

      <TextBody>
        Please arrive at your appointment 15 minutes before the start time to
        allow the system to check that you have good internet connectivity,
        audio and video. You will then proceed to the virtual waiting room where
        your doctor will meet you.
      </TextBody>

      {renderJoinAppointmentButton()}

      <TextBody className="appointment-pre-appointment__another-window">
        (Your video call will open in another window.)
      </TextBody>
      <TextTitle type="body">Using a Phone or Tablet?</TextTitle>

      <TextBody>
        If you are using a phone or tablet, we recommend you hold your device in
        landscape orientation to ensure the best video calling experience.
      </TextBody>
      <TextTitle type="body">After the consultation</TextTitle>

      <TextBody>
        When the call has finished, the doctor will write up your notes along
        with a treatment recommendation you can use with your GP (if required).
        You will be notified via email when this is ready to view and download.
      </TextBody>

      <TextBody>
        If you have any questions or require support, please drop us an email at{' '}
        <a href="mailto:clinic@healthandher.com">clinic@healthandher.com</a>.
      </TextBody>
      <div className="appointment-pre-appointment__action-links">
        {appointment.status === AppointmentStatuses.BOOKED && (
          <Button
            color="primary-link"
            onClick={onRescheduleAppointment}
            className="appointment-pre-appointment__action-cancel"
          >
            Reschedule Appointment
          </Button>
        )}

        {/* Patient can only cancel while the appointment is BOOKED */}
        {appointment.status === AppointmentStatuses.BOOKED && (
          <Button
            color="primary-link"
            onClick={onClickCancelAppointment}
            className="appointment-pre-appointment__action-cancel"
          >
            Cancel Appointment
          </Button>
        )}
      </div>

      {showCancelConfirm && (
        <AlertConfirm
          title="Confirm appointment cancel"
          label="Are you sure you want to cancel the appointment? If your appointment is less than 24 hours away you will not receive a refund for the appointment."
          onNo={onCancelConfirmNo}
          onYes={onCancelConfirmYes}
        />
      )}

      {showAlertConfirm && hoursUntilAppointment > 24 && (
        <AlertConfirm
          title="Confirm appointment reschedule"
          label={`Your appointment is due to take place on ${dayjs(
            appointment.startAt
          ).format('LLLL')}. Are you sure you'd like to reschedule?`}
          onNo={() => {
            setShowAlertConfirm(false);
          }}
          onYes={() => {
            setShowAlertConfirm(false);
            history.push(`/rebook-appointment/${appointment._id}`);
          }}
          yesLabel="Yes - reschedule"
          noLabel="Cancel"
        />
      )}

      {showAlertConfirm && hoursUntilAppointment < 24 && (
        <AlertConfirm
          title="Your appointment is within 24 hours"
          label="We are unable to reschedule your appointment at this time. For more information please contact us at clinic@healthandher.com."
          onNo={() => {
            setShowAlertConfirm(false);
          }}
          noLabel="Close"
        />
      )}
    </div>
  );
};

PreAppointment.propTypes = {
  appointment: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    startAt: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,
};

export default PreAppointment;
