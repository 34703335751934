import { useState } from 'react';
import PropTypes from 'prop-types';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import TextInput from '../elements/form/TextInput';
import TextBody from '../elements/TextBody';
import Radio from '../elements/form/Radio';
import ConfirmShareWithPatientOwnGp from '../auth/register/confirm/ShareWithPatientOwnGp';
import EmergencyContactInfoModal from '../auth/register/confirm/EmergencyContactInfoModal';
import FormConfirm from '../elements/form/Confirm';
import {
  PatientRegisteredWithGp,
  PatientGpDetailsToHand,
} from '../../slices/patientSlice';

const confirmViews = {
  SHARE_WITH_PATIENT_OWN_GP: 'SHARE_WITH_PATIENT_OWN_GP',
  EMERGENCY_CONTACT_INFO: 'EMERGENCY_CONTACT_INFO',
};

const GpDetails = (props) => {
  const {
    errors,
    register,
    setValue,
    watch,
    patientName,
    showGpDetailsToHandQuestion,
  } = props;
  const [currentConfirmView, setCurrentConfirmView] = useState(null);

  const registeredWithGp = watch('registeredWithGp');
  const gpDetailsToHand = watch('gpDetailsToHand');

  const onClickConfirmViewAccept = () => {
    const acceptAttributes = {
      SHARE_WITH_PATIENT_OWN_GP: 'confirmSharePatientOwnGp',
    };

    const attribute = acceptAttributes[currentConfirmView];
    if (attribute) {
      setValue(attribute, true, { shouldValidate: true });
    }

    setCurrentConfirmView(null);
  };

  const onClickConfirmViewClose = () => {
    setCurrentConfirmView(null);
  };

  const onClickViewShareGp = () => {
    setCurrentConfirmView(confirmViews.SHARE_WITH_PATIENT_OWN_GP);
  };

  const showEmergencyContactInfoPopup = () => {
    setCurrentConfirmView(confirmViews.EMERGENCY_CONTACT_INFO);
  };

  const resetCurrentConfirmView = () => {
    setCurrentConfirmView(null);
  };

  const emergencyContactInfoIcon = (
    <a
      className={'form-text-input__icon-link'}
      onClick={showEmergencyContactInfoPopup}
    >
      <InfoOutlinedIcon />
    </a>
  );

  const renderGpDetails = () => {
    return (
      <>
        <TextInput
          label="Name of GP"
          type="text"
          name="gpName"
          error={errors.gpName}
          autoComplete="off"
          registerRef={register({
            maxLength: {
              value: 200,
              message: 'Too many characters (max 200).',
            },
            validate: {
              notPatientName: (value) =>
                value.toLowerCase() !== patientName.toLowerCase() ||
                'Please enter the name of your GP',
            },
          })}
        />

        <TextInput
          label="Name of surgery"
          type="text"
          name="surgeryName"
          error={errors.surgeryName}
          registerRef={register({
            required: 'This field is required.',
            maxLength: {
              value: 200,
              message: 'Too many characters (max 200).',
            },
          })}
          required
        />

        <TextInput
          label="Surgery Address - Line 1"
          type="text"
          name="surgeryAddress.line1"
          error={errors.surgeryAddress && errors.surgeryAddress.line1}
          registerRef={register({
            required: 'This field is required.',
            maxLength: {
              value: 200,
              message: 'Too many characters (max 200).',
            },
          })}
          required
        />

        <TextInput
          label="Surgery Address - Line 2"
          type="text"
          name="surgeryAddress.line2"
          error={errors.surgeryAddress && errors.surgeryAddress.line2}
          registerRef={register({
            maxLength: {
              value: 200,
              message: 'Too many characters (max 200).',
            },
          })}
        />

        <TextInput
          label="Surgery City/Town"
          type="text"
          name="surgeryAddress.city"
          error={errors.surgeryAddress && errors.surgeryAddress.city}
          registerRef={register({
            required: 'This field is required.',
            maxLength: {
              value: 200,
              message: 'Too many characters (max 200).',
            },
          })}
          required
        />

        <TextInput
          label="Surgery County"
          type="text"
          name="surgeryAddress.region"
          error={errors.surgeryAddress && errors.surgeryAddress.region}
          registerRef={register({
            required: 'This field is required.',
            maxLength: {
              value: 200,
              message: 'Too many characters (max 200).',
            },
          })}
          required
        />

        <TextInput
          label="Surgery Postcode"
          type="text"
          name="surgeryAddress.postcode"
          error={errors.surgeryAddress && errors.surgeryAddress.postcode}
          registerRef={register({
            maxLength: {
              value: 200,
              message: 'Too many characters (max 200).',
            },
          })}
        />

        <FormConfirm
          labelExtra="How will my information be shared?"
          name="confirmSharePatientOwnGp"
          error={!!errors.confirmSharePatientOwnGp}
          registerRef={register()}
          onClickView={onClickViewShareGp}
        >
          Share my details with my GP
        </FormConfirm>
        <TextInput
          label="Emergency Contact Name"
          labelAfter={emergencyContactInfoIcon}
          type="text"
          name="emergencyContact.name"
          error={errors.emergencyContact && errors.emergencyContact.name}
          registerRef={register({
            required: 'This field is required.',
            maxLength: {
              value: 200,
              message: 'Too many characters (max 200).',
            },
          })}
          required
        />

        <TextInput
          label="Emergency Contact Relationship to you"
          type="text"
          name="emergencyContact.relationship"
          error={
            errors.emergencyContact && errors.emergencyContact.relationship
          }
          registerRef={register({
            required: 'This field is required.',
            maxLength: {
              value: 200,
              message: 'Too many characters (max 200).',
            },
          })}
          required
        />

        <TextInput
          label="Emergency Contact Telephone Number"
          type="tel"
          name="emergencyContact.tel"
          error={errors.emergencyContact && errors.emergencyContact.tel}
          registerRef={register({
            required: 'This field is required.',
            maxLength: {
              value: 15,
              message: 'Too many characters (max 15).',
            },
          })}
          required
        />
      </>
    );
  };

  const renderConfirmView = () => {
    let confirmView;
    switch (currentConfirmView) {
      case confirmViews.SHARE_WITH_PATIENT_OWN_GP:
        confirmView = (
          <ConfirmShareWithPatientOwnGp
            onClickAccept={onClickConfirmViewAccept}
            onClickClose={onClickConfirmViewClose}
            patientName={patientName}
          />
        );
        break;
      case confirmViews.EMERGENCY_CONTACT_INFO:
        confirmView = (
          <EmergencyContactInfoModal
            onClickAccept={resetCurrentConfirmView}
            onClickClose={resetCurrentConfirmView}
          />
        );
        break;
      default:
        confirmView = null;
    }

    return confirmView;
  };

  const shouldShowGpDetailsToHand =
    showGpDetailsToHandQuestion &&
    registeredWithGp === PatientRegisteredWithGp.YES;

  const shouldShowGpDetails =
    (showGpDetailsToHandQuestion &&
      registeredWithGp === PatientRegisteredWithGp.YES &&
      gpDetailsToHand === PatientGpDetailsToHand.YES) ||
    (!showGpDetailsToHandQuestion &&
      registeredWithGp === PatientRegisteredWithGp.YES);

  return (
    <>
      {renderConfirmView()}
      <TextBody>Are you registered with a GP?</TextBody>
      <Radio
        name="registeredWithGp"
        values={[
          { label: 'Yes', value: PatientRegisteredWithGp.YES },
          { label: 'No', value: PatientRegisteredWithGp.NO },
        ]}
        error={errors.registeredWithGp}
        registerRef={register({
          required: 'This field is required.',
        })}
      />

      {shouldShowGpDetailsToHand && (
        <>
          <TextBody>Do you have your GP details to hand?</TextBody>
          <Radio
            name="gpDetailsToHand"
            values={[
              { label: 'Yes', value: PatientGpDetailsToHand.YES },
              { label: 'No', value: PatientGpDetailsToHand.NO },
            ]}
            error={errors.gpDetailsToHand}
            registerRef={register({
              required: 'This field is required.',
            })}
          />
        </>
      )}

      {shouldShowGpDetails && renderGpDetails()}
    </>
  );
};

GpDetails.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  errors: PropTypes.object.isRequired,
  register: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  patientName: PropTypes.string.isRequired,
  showGpDetailsToHandQuestion: PropTypes.bool.isRequired,
};

export default GpDetails;
