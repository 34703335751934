import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import TextTitle from '../../elements/TextTitle';

dayjs.extend(advancedFormat);

const Cancelled = (props) => {
  const { appointment } = props;

  const { cancelled = {} } = appointment;
  const { refund = {} } = cancelled;
  const { isEligibleForRefund = false } = refund;

  const cancelledAtFormatted = dayjs(cancelled.cancelledAt).format(
    'Do MMMM YYYY [at] h:mma'
  );

  const getRefundEligibleText = () => {
    return (
      <>
        As you cancelled your appointment at least 24 hours in advance, you will
        receive a full refund. Please allow up to 14 days for your refund to be
        processed. If you have any questions, please email us at{' '}
        <a href="mailto:clinic@healthandher.com">clinic@healthandher.com</a>.
      </>
    );
  };

  const getRefundNotEligibleText = () => {
    return (
      <>
        Please see our{' '}
        <a
          href="https://healthandher.com/terms-conditions-clinic/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms and Conditions
        </a>{' '}
        for further details or email our support team at{' '}
        <a href="mailto:clinic@healthandher.com">clinic@healthandher.com</a>.
      </>
    );
  };

  return (
    <div className="appointment-cancelled">
      <TextTitle type="sub">Appointment Cancelled</TextTitle>
      This appointment was cancelled on {cancelledAtFormatted}.{' '}
      {isEligibleForRefund
        ? getRefundEligibleText()
        : getRefundNotEligibleText()}
    </div>
  );
};

Cancelled.propTypes = {
  appointment: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    cancelled: PropTypes.object.isRequired,
  }).isRequired,
};

export default Cancelled;
