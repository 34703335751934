import './Medication.scss';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import TextBody from '../../elements/TextBody';
import Content from '../../elements/Content';
import PageHeader from '../../elements/PageHeader';
import Button from '../../elements/Button';
import Radio from '../../elements/form/Radio';
import FormModal from '../../elements/FormModal';
import DataPill from '../../elements/pill/DataPill';
import LoadingSpinner from '../../elements/LoadingSpinner';
import { patch } from '../../../services/api/apiPatient';
import {
  selectPatient,
  replacePatient,
  PatientTakingMedication,
  PatientHasAllergies,
} from '../../../slices/patientSlice';
import AlertError from '../../elements/alert/Error';
import FieldError from '../../elements/form/FieldError';
import TextTitle from '../../elements/TextTitle';

const Medication = (props) => {
  const { onClickBack, showQuestion } = props;
  const patient = useSelector(selectPatient);
  const [medicationItems, setMedicationItems] = useState(
    patient.takingMedicationDetails.map((el) => {
      return {
        id: uuidv4(),
        value: el,
        isNew: false,
      };
    })
  );
  const [medicationModalData, setMedicationModalData] = useState(null);
  const [allergyItems, setAllergyItems] = useState(
    patient.hasAllergiesDetails.map((el) => {
      return {
        id: uuidv4(),
        value: el,
        isNew: false,
      };
    })
  );
  const [allergyModalData, setAllergyModalData] = useState(null);
  const { register, handleSubmit, watch, errors } = useForm({
    defaultValues: {
      hasMedication: patient.takingMedication,
      hasAllergies: patient.hasAllergies,
    },
  });
  const [showMedicationNoItemsAddedError, setMedicationShowNoItemsAddedError] =
    useState(false);
  const [showAllergyNoItemsAddedError, setAllergyShowNoItemsAddedError] =
    useState(false);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [apiError, setApiError] = useState();
  const dispatch = useDispatch();

  const hasMedication = watch('hasMedication');
  const hasAllergies = watch('hasAllergies');

  const patchRequest = async (data) => {
    setRequestInProgress(true);

    try {
      const response = await patch(data);
      dispatch(replacePatient(response.patient));
      onClickBack();
    } catch (err) {
      setApiError(
        'There was an error updating your medical details. Please try again.'
      );

      window.scrollTo(0, 0);
      setRequestInProgress(false);
    }
  };

  const onSubmitForm = (data) => {
    setMedicationShowNoItemsAddedError(false);
    setAllergyShowNoItemsAddedError(false);

    if (requestInProgress) {
      return;
    }

    let defaultHasMedication;
    if (!showQuestion) {
      defaultHasMedication = PatientTakingMedication.YES;
    } else {
      defaultHasMedication = data.hasMedication;
    }

    let defaultHasAllergies;
    if (!showQuestion) {
      defaultHasAllergies = PatientHasAllergies.YES;
    } else {
      defaultHasAllergies = data.hasAllergies;
    }

    let showError = false;

    let takingMedicationDetails = [];
    if (defaultHasMedication === PatientTakingMedication.YES) {
      takingMedicationDetails = medicationItems.map((el) => el.value);
      if (takingMedicationDetails.length === 0) {
        setMedicationShowNoItemsAddedError(true);
        showError = true;
      }
    }

    let hasAllergiesDetails = [];
    if (defaultHasAllergies === PatientHasAllergies.YES) {
      hasAllergiesDetails = allergyItems.map((el) => el.value);
      if (hasAllergiesDetails.length === 0) {
        setAllergyShowNoItemsAddedError(true);
        showError = true;
      }
    }

    if (showError) {
      window.scrollTo(0, 0);
      return;
    }

    patchRequest({
      attributesToUpdate: {
        takingMedication: defaultHasMedication,
        takingMedicationDetails,
        hasAllergies: defaultHasAllergies,
        hasAllergiesDetails,
      },
    });
  };

  const onClickAddMedication = () => {
    setMedicationModalData({
      id: uuidv4(),
      value: '',
      isNew: true,
    });
  };

  const onClickViewMedicationItem = (id) => {
    const medication = medicationItems.find((el) => {
      return el.id === id;
    });

    setMedicationModalData(medication);
  };

  const onClickRemoveMedicationItem = (id) => {
    const updatedMedicationItems = medicationItems.filter((el) => {
      return el.id !== id;
    });

    setMedicationItems(updatedMedicationItems);
  };

  const renderMedicationItem = (el) => {
    return (
      <DataPill
        key={el.id}
        label={el.value}
        id={el.id}
        onView={onClickViewMedicationItem}
        onRemove={onClickRemoveMedicationItem}
      />
    );
  };

  const onClickAddAllergy = () => {
    setAllergyModalData({
      id: uuidv4(),
      value: '',
      isNew: true,
    });
  };

  const onClickViewAllergyItem = (id) => {
    const allergy = allergyItems.find((el) => {
      return el.id === id;
    });

    setAllergyModalData(allergy);
  };

  const onClickRemoveAllergyItem = (id) => {
    const updatedAllergyItems = allergyItems.filter((el) => {
      return el.id !== id;
    });

    setAllergyItems(updatedAllergyItems);
  };

  const renderAllergyItem = (el) => {
    return (
      <DataPill
        key={el.id}
        label={el.value}
        id={el.id}
        onView={onClickViewAllergyItem}
        onRemove={onClickRemoveAllergyItem}
      />
    );
  };

  const renderMedicationQuestion = () => {
    return (
      <>
        <TextBody>Are you currently taking any medication?</TextBody>
        <Radio
          name="hasMedication"
          values={[
            { label: 'Yes', value: PatientTakingMedication.YES },
            { label: 'No', value: PatientTakingMedication.NO },
          ]}
          error={errors.hasMedication}
          registerRef={register({ required: 'This field is required.' })}
        />
      </>
    );
  };

  const renderMedication = () => {
    const medicationItemList = medicationItems.map((el) => {
      return renderMedicationItem(el);
    });

    return (
      <>
        <TextBody>Which medications are you currently taking?</TextBody>
        {showMedicationNoItemsAddedError && (
          <FieldError
            label="You must add at least one medication."
            className="medical-medications__field-error"
          />
        )}

        {medicationItemList}

        <div>
          <Button
            className="book-medical-medications__add-medication"
            color="secondary"
            onClick={onClickAddMedication}
          >
            Add Medication
          </Button>
        </div>
      </>
    );
  };

  const renderAllergiesQuestion = () => {
    return (
      <>
        <TextBody>Are you allergic to any medications?</TextBody>
        <Radio
          name="hasAllergies"
          values={[
            { label: 'Yes', value: PatientHasAllergies.YES },
            { label: 'No', value: PatientHasAllergies.NO },
          ]}
          error={errors.hasAllergies}
          registerRef={register({ required: 'This field is required.' })}
        />
      </>
    );
  };

  const renderAllergies = () => {
    const allergyItemList = allergyItems.map((el) => {
      return renderAllergyItem(el);
    });

    return (
      <>
        <TextBody>
          Tell us about your allergies so our doctors can make better
          recommendations.
        </TextBody>
        {showAllergyNoItemsAddedError && (
          <FieldError
            label="You must add at least one allergy."
            className="medical-medications__field-error"
          />
        )}

        {allergyItemList}

        <div>
          <Button
            className="book-medical-medications__add-allergy"
            color="secondary"
            onClick={onClickAddAllergy}
          >
            Add Allergy
          </Button>
        </div>
      </>
    );
  };

  const onClickMedicationModalClose = () => {
    setMedicationModalData(null);
  };

  const onClickAllergyModalClose = () => {
    setAllergyModalData(null);
  };

  const onAddMedicationFormSubmitted = (data) => {
    let updatedMedicationItems;
    if (data.isNew) {
      updatedMedicationItems = [
        ...medicationItems,
        {
          ...data,
          isNew: false,
        },
      ];
    } else {
      updatedMedicationItems = medicationItems.map((el) => {
        if (el.id === data.id) {
          return data;
        }
        return el;
      });
    }
    setMedicationItems(updatedMedicationItems);
    setMedicationModalData(null);
  };

  const renderShowAddMedicationModal = () => {
    return (
      <FormModal
        label="Medication"
        onClose={onClickMedicationModalClose}
        onFormSubmitted={onAddMedicationFormSubmitted}
        data={medicationModalData}
      />
    );
  };

  const onAddAllergyFormSubmitted = (data) => {
    let updatedAllergyItems;
    if (data.isNew) {
      updatedAllergyItems = [
        ...allergyItems,
        {
          ...data,
          isNew: false,
        },
      ];
    } else {
      updatedAllergyItems = allergyItems.map((el) => {
        if (el.id === data.id) {
          return data;
        }
        return el;
      });
    }
    setAllergyItems(updatedAllergyItems);
    setAllergyModalData(null);
  };

  const renderShowAddAllergyModal = () => {
    return (
      <FormModal
        label="Allergy"
        onClose={onClickAllergyModalClose}
        onFormSubmitted={onAddAllergyFormSubmitted}
        data={allergyModalData}
      />
    );
  };

  const onCloseAlertError = () => {
    setApiError(null);
  };

  return (
    <>
      <PageHeader showBack onClickBack={onClickBack} />
      <div className="medical-medications">
        <Content>
          {apiError && (
            <AlertError label={apiError} onClose={onCloseAlertError} />
          )}

          <TextTitle type="sub">Medication</TextTitle>

          <form onSubmit={handleSubmit(onSubmitForm)}>
            {showQuestion && renderMedicationQuestion()}

            {hasMedication === PatientTakingMedication.YES &&
              renderMedication()}

            {showQuestion && renderAllergiesQuestion()}

            {hasAllergies === PatientHasAllergies.YES && renderAllergies()}

            {requestInProgress === true ? (
              <LoadingSpinner />
            ) : (
              <Button type="submit">Save</Button>
            )}
          </form>

          {medicationModalData !== null && renderShowAddMedicationModal()}
          {allergyModalData !== null && renderShowAddAllergyModal()}
        </Content>
      </div>
    </>
  );
};

Medication.propTypes = {
  onClickBack: PropTypes.func.isRequired,
  showQuestion: PropTypes.bool,
};

Medication.defaultProps = {
  showQuestion: true,
};

export default Medication;
