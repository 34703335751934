import { baseApiUrl, jsonGet, jsonPatch, jsonPost, jsonDelete } from './api';

export const getScheduleForPatients = (practitionerId = null) => {
  let url;
  if (practitionerId) {
    url = `${baseApiUrl}/appointment/available/${practitionerId}`;
  } else {
    url = `${baseApiUrl}/appointment/available`;
  }

  return jsonGet(url);
};

export const bookHoldAppointment = (data) => {
  const url = `${baseApiUrl}/appointment/book/hold`;
  return jsonPost(url, data);
};

export const bookSetAppointmentDetails = (data) => {
  const url = `${baseApiUrl}/appointment/book/details`;
  return jsonPatch(url, data);
};

export const bookSetAppointmentPaymentSessionCreate = (data) => {
  const url = `${baseApiUrl}/appointment/book/payment/session-create`;
  return jsonPost(url, data);
};

export const bookAppointmentWithPrepaidCode = (data) => {
  const url = `${baseApiUrl}/appointment/book/payment/prepaid`;
  return jsonPost(url, data);
};

export const cancel = (data) => {
  const url = `${baseApiUrl}/appointment/${data.appointmentId}`;
  return jsonDelete(url);
};

export const getPaymentDetails = (data) => {
  const url = `${baseApiUrl}/appointment/${data.appointmentId}/payment-details`;
  return jsonGet(url, data);
};

export const moveAppointment = (data) => {
  const url = `${baseApiUrl}/appointment/${data.appointmentId}`;
  return jsonPatch(url, data);
};

export const getGpLetter = (appointmentId) => {
  const url = `${baseApiUrl}/appointment/${appointmentId}/gpletter`;
  return jsonGet(url);
};
