import './About.scss';
import { useHistory } from 'react-router-dom';
import TextTitle from '../elements/TextTitle';
import TextBody from '../elements/TextBody';
import Content from '../elements/Content';
import PageHeader from '../elements/PageHeader';
import NavBar from '../navigation/NavBar';

const About = () => {
  const history = useHistory();

  const onClickBack = () => {
    history.push('/help');
  };

  return (
    <>
      <PageHeader showBack onClickBack={onClickBack} />
      <div className="help-about">
        <Content>
          <TextTitle type="sub">About Health &amp; Her</TextTitle>

          <TextBody>
            Health &amp; Her was founded by a blue-chip FMCG marketer and
            entrepreneur, Kate Bache, and fellow entrepreneur and former
            Healthcare Nutricentre Head Buyer, Gervase Fay. Kate explains what
            inspired our mission:
          </TextBody>

          <TextBody>
            “Over the years, we’ve seen friends, family and colleagues really
            struggle with menopausal symptoms. We were really shocked to
            discover how few resources are out there for women at this life
            stage, and it inspired us to see what we could do to make a
            difference. Given our backgrounds in buying and marketing, we
            spotted an opportunity to take our talents and put our money where
            our mouth is to help!”
          </TextBody>

          <TextTitle type="body">Address</TextTitle>
          <TextBody>
            Health &amp; Her Ltd
            <br />
            Tramshed Tech
            <br />
            Unit D
            <br />
            Pendyris St
            <br />
            Cardiff
            <br />
            CF11 6BH
          </TextBody>

          <TextTitle type="body">Telephone</TextTitle>
          <TextBody>0333 305 5903</TextBody>

          <TextTitle type="body">Email</TextTitle>
          <TextBody>
            <a href="mailto:clinic@healthandher.com">clinic@healthandher.com</a>
          </TextBody>
        </Content>
      </div>
      <NavBar active="HELP" />
    </>
  );
};

export default About;
