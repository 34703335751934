import './Book.scss';
import { useState, useEffect, useCallback } from 'react';
import { useHistory, useParams, Redirect } from 'react-router-dom';
import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux';
import PageHeader from '../../elements/PageHeader';
import SelectDate from './SelectDate';
import SelectTime from './SelectTime';
import YourAddress from './YourAddress';
import AboutGp from './AboutGp';
import Details from './Details';
import PayType from './PayType';
import Pay from './Pay';
import { bookSetAppointmentDetails } from '../../../services/api/apiAppointment';
import {
  selectBookingHeldAppointment,
  replaceAppointment,
  selectPastAppointments,
} from '../../../slices/appointmentsSlice';
import { replacePatient } from '../../../slices/patientSlice';
import { ScheduleTypes } from '../../../slices/scheduleSlice';
import NavBar from '../../navigation/NavBar';
import Contraception from './medical/Contraception';
import History from './medical/History';
import Hrt from './medical/Hrt';
import Medication from './medical/Medication';
import MedicalProfile from './medical/MedicalProfile';
import MenstrualCycle from './medical/MenstrualCycle';
import MedicalProcedures from './medical/MedicalProcedures';
import AlertInfo from '../../elements/alert/Info';

const stages = {
  SELECT_DATE: {
    id: 'SELECT_DATE',
    url: 'select-date',
  },
  SELECT_TIME: {
    id: 'SELECT_TIME',
    url: 'select-time',
  },
  YOUR_ADDRESS: {
    id: 'YOUR_ADDRESS',
    url: 'your-address',
  },
  ABOUT_GP: {
    id: 'ABOUT_GP',
    url: 'about-gp',
  },
  MEDICAL_PROFILE: {
    id: 'MEDICAL_PROFILE',
    url: 'medical-profile',
  },
  HISTORY: {
    id: 'HISTORY',
    url: 'medical-history',
  },
  MEDICATION: {
    id: 'MEDICATION',
    url: 'medication',
  },
  HRT: {
    id: 'HRT',
    url: 'hrt',
  },
  MENSTRUAL_CYCLE: {
    id: 'MENSTRUAL_CYCLE',
    url: 'menstrual-cycle',
  },
  CONTRACEPTION: {
    id: 'CONTRACEPTION',
    url: 'contraception',
  },
  MEDICAL_PROCEDURES: {
    id: 'MEDICAL_PROCEDURES',
    url: 'medical-procedures',
  },
  DETAILS: {
    id: 'DETAILS',
    url: 'details',
  },
  PAY_TYPE: {
    id: 'PAY_TYPE',
    url: 'pay-type',
  },
  PAY: {
    id: 'PAY',
    url: 'pay',
  },
};

const stageOrder = [
  stages.SELECT_DATE.id,
  stages.SELECT_TIME.id,
  stages.YOUR_ADDRESS.id,
  stages.ABOUT_GP.id,
  stages.MEDICAL_PROFILE.id,
  stages.HISTORY.id,
  stages.MEDICATION.id,
  stages.HRT.id,
  stages.MENSTRUAL_CYCLE.id,
  stages.CONTRACEPTION.id,
  stages.MEDICAL_PROCEDURES.id,
  stages.DETAILS.id,
  stages.PAY_TYPE.id,
  stages.PAY.id,
];

const Book = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const bookingHeldAppointment = useSelector(selectBookingHeldAppointment);

  let defaultSelectedDate;
  if (bookingHeldAppointment) {
    defaultSelectedDate = dayjs(bookingHeldAppointment.startAt);
  }
  const pastAppointments = useSelector(selectPastAppointments);

  const [selectedDate, setSelectedDate] = useState(defaultSelectedDate);
  const [apiError, setApiError] = useState();
  const [payType, setPayType] = useState();
  const pracId = pastAppointments.find((past) => !!past.practitionerId);
  const [practitionerId, setPractitionerId] = useState(
    pracId ? pracId.practitionerId : null
  );

  const scheduleType = ScheduleTypes.INITIAL;

  const getIndexForStage = (stageId) => {
    return stageOrder.findIndex((el) => el === stageId);
  };

  // Workout the current stage from the stage param in
  // the url, a default is set if it doesn't exist
  const { stage: stageParam } = useParams();
  const [stageCompleteTo, setStageCompleteTo] = useState();
  const currentStage = Object.keys(stages).find(
    (el) => stages[el].url === stageParam
  );

  const onClickStageGoBack = useCallback(() => {
    const stageIndex = getIndexForStage(currentStage);
    const previousStage = stageOrder[stageIndex - 1];
    if (previousStage !== undefined) {
      const { url } = stages[previousStage];
      setStageCompleteTo(previousStage);
      history.push(`/book-appointment/${url}`);
    } else {
      history.push('/');
    }
  }, [currentStage, history]);

  useEffect(() => {
    // Detect the pop state of the browser - (back button being clicked)
    // and handle the routing to work the same as the in app back button.
    // This is important to ensure back navigation works when the user
    // click back from the stripe checkout or cancels the strip checkout.
    // We need to ensure the user can navigate back from the pay stage to previous
    // stages using the browser back button
    window.addEventListener('popstate', onClickStageGoBack);
    return () => {
      window.removeEventListener('popstate', onClickStageGoBack);
    };
  }, [onClickStageGoBack]);

  // Invalid stage from stageParam, redirect to first stage
  if (!currentStage) {
    const firstStage = stageOrder[0];
    const { url } = stages[firstStage];
    return <Redirect to={`/book-appointment/${url}`} />;
  }

  // Ensure user isn't trying to access a later stage in appointment booking without
  // first passing through a previous stage, ie direct linking to stage 2 url
  // should not work unless landing on the last stage (ie, /pay).
  // We need to allow this as users can cancel or click back from the stripe
  // checkout and we want the booking process to pick up back on the /pay stage
  let stageCompletedToIndex = getIndexForStage(stageCompleteTo);
  if (stageCompletedToIndex === -1) {
    stageCompletedToIndex = 0;
  }

  const currentStageIndex = getIndexForStage(currentStage);
  const allowDirectAccessToPayStage =
    bookingHeldAppointment && currentStage === stages.PAY.id;

  if (allowDirectAccessToPayStage && stageCompleteTo !== stages.PAY.id) {
    setStageCompleteTo(stages.PAY.id);
  }

  if (
    !allowDirectAccessToPayStage &&
    stageCompletedToIndex < currentStageIndex
  ) {
    const firstStage = stageOrder[stageCompletedToIndex];
    const { url } = stages[firstStage];
    return <Redirect to={`/book-appointment/${url}`} />;
  }

  const onChangeSelectedDate = (date) => {
    setSelectedDate(date);
  };

  const onStageComplete = async (stageData) => {
    // eslint-disable-next-line default-case
    switch (currentStage) {
      case stages.SELECT_DATE.id:
        setSelectedDate(stageData);
        break;
      case stages.PAY_TYPE.id:
        setPayType(stageData.type);
        break;
      case stages.DETAILS.id: {
        try {
          const details = {
            appointmentId: bookingHeldAppointment._id,
            ...stageData,
          };
          const { appointment, patient } = await bookSetAppointmentDetails(
            details
          );
          dispatch(replaceAppointment(appointment));
          dispatch(replacePatient(patient));
          window.scrollTo(0, 0);
        } catch (err) {
          setApiError(
            'Unable to update appointment, please try again or go back and select another time'
          );
          return;
        }
        break;
      }
    }

    window.scrollTo(0, 0);
    const stageIndex = getIndexForStage(currentStage);
    const nextStage = stageOrder[stageIndex + 1];
    if (nextStage !== undefined) {
      const { url } = stages[nextStage];
      setStageCompleteTo(nextStage);
      history.push(`/book-appointment/${url}`);
    }
  };

  const renderStage = () => {
    let content;
    switch (currentStage) {
      case stages.SELECT_DATE.id:
        content = (
          <SelectDate
            onStageComplete={onStageComplete}
            scheduleType={scheduleType}
            practitionerId={practitionerId}
            setPractitionerId={setPractitionerId}
          />
        );
        break;
      case stages.SELECT_TIME.id:
        content = (
          <SelectTime
            onStageComplete={onStageComplete}
            scheduleType={scheduleType}
            selectedDate={selectedDate}
            onChangeSelectedDate={onChangeSelectedDate}
            practitionerId={practitionerId}
            setPractitionerId={setPractitionerId}
          />
        );
        break;
      case stages.YOUR_ADDRESS.id:
        content = <YourAddress onStageComplete={onStageComplete} />;
        break;
      case stages.ABOUT_GP.id:
        content = <AboutGp onStageComplete={onStageComplete} />;
        break;
      case stages.MEDICAL_PROFILE.id:
        content = <MedicalProfile onStageComplete={onStageComplete} />;
        break;
      case stages.HISTORY.id:
        content = <History onStageComplete={onStageComplete} />;
        break;
      case stages.MEDICATION.id:
        content = <Medication onStageComplete={onStageComplete} />;
        break;
      case stages.HRT.id:
        content = <Hrt onStageComplete={onStageComplete} />;
        break;
      case stages.MENSTRUAL_CYCLE.id:
        content = <MenstrualCycle onStageComplete={onStageComplete} />;
        break;
      case stages.CONTRACEPTION.id:
        content = <Contraception onStageComplete={onStageComplete} />;
        break;
      case stages.MEDICAL_PROCEDURES.id:
        content = <MedicalProcedures onStageComplete={onStageComplete} />;
        break;
      case stages.DETAILS.id:
        content = (
          <Details
            onStageComplete={onStageComplete}
            formData={{
              patientPreAppointmentNotes:
                bookingHeldAppointment.patientPreAppointmentNotes,
              patientPreAppointmentReason:
                bookingHeldAppointment.patientPreAppointmentReason,
              patientPreAppointmentQuestions:
                bookingHeldAppointment.patientPreAppointmentQuestions,
            }}
          />
        );
        break;
      case stages.PAY_TYPE.id:
        content = (
          <PayType
            onStageComplete={onStageComplete}
            scheduleType={scheduleType}
            selectedDate={dayjs(bookingHeldAppointment.startAt)}
            appointment={{
              _id: bookingHeldAppointment._id,
              practitionerId: bookingHeldAppointment.practitionerId,
              startAt: dayjs(bookingHeldAppointment.startAt),
              endAt: dayjs(bookingHeldAppointment.endAt),
            }}
          />
        );
        break;

      case stages.PAY.id:
        content = (
          <Pay
            type={payType}
            onStageComplete={onStageComplete}
            scheduleType={scheduleType}
            selectedDate={dayjs(bookingHeldAppointment.startAt)}
            appointment={{
              _id: bookingHeldAppointment._id,
              practitionerId: bookingHeldAppointment.practitionerId,
              startAt: dayjs(bookingHeldAppointment.startAt),
              endAt: dayjs(bookingHeldAppointment.endAt),
            }}
          />
        );
        break;
      default:
        throw new Error(`stage not handled: ${currentStage}`);
    }

    return content;
  };

  const onCloseAlertInfo = () => {
    setApiError(null);
  };

  return (
    <>
      <PageHeader showBack onClickBack={onClickStageGoBack} />
      {apiError && (
        <AlertInfo
          title="Cannot book appointment"
          label={apiError}
          onClose={onCloseAlertInfo}
        />
      )}
      <div className="book">{renderStage()}</div>
      <NavBar desktopOnly />
    </>
  );
};

export default Book;
