import './Home.scss';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import NavBar from '../navigation/NavBar';
import PageHeader from '../elements/PageHeader';
import TextTitle from '../elements/TextTitle';
import TextBody from '../elements/TextBody';
import Button from '../elements/Button';
import Content from '../elements/Content';
import InfoBanner from '../elements/InfoBanner';
import Appointment from './Appointment';
import { selectPatient } from '../../slices/patientSlice';
import {
  selectUpcomingAppointments,
  selectPastAppointments,
  selectCancelledAppointments,
} from '../../slices/appointmentsSlice';
import packageInfo from '../../../package.json';

const Home = () => {
  const { version } = packageInfo;
  const patient = useSelector(selectPatient);
  const upcomingAppointments = useSelector(selectUpcomingAppointments);
  const pastAppointments = useSelector(selectPastAppointments);
  const cancelledAppointments = useSelector(selectCancelledAppointments);
  const [welcomeBannerIsDismissed, setWelcomeBannerIsDismissed] =
    useState(false);

  const onClickInfoBannerDismiss = () => {
    window.localStorage.setItem('preference.home.welcome-banner', true);
    setWelcomeBannerIsDismissed(true);
  };

  useEffect(() => {
    const welcomeBanner = window.localStorage.getItem(
      'preference.home.welcome-banner'
    );
    // eslint-disable-next-line no-console
    console.log(`Clinic App: v${version}`);
    setWelcomeBannerIsDismissed(welcomeBanner === 'true');
  }, []);

  const renderUpcomingAppointments = () => {
    const upcomingAppointmentsList = upcomingAppointments.map((el) => {
      return <Appointment key={el._id} appointment={el} />;
    });

    return (
      <>
        <div className="home-upcoming-appointments">
          <Content>
            <TextTitle>Upcoming Appointments</TextTitle>
            {upcomingAppointmentsList}
          </Content>
        </div>
        <Content>
          <Link to="/book-appointment" className="home__book-appointment">
            <Button>Book Appointment</Button>
          </Link>
        </Content>
      </>
    );
  };

  const renderPastAppointments = () => {
    const pastAppointmentsList = pastAppointments.map((el) => {
      return <Appointment key={el._id} appointment={el} />;
    });

    return (
      <div className="home-past-appointments">
        <Content>
          <TextTitle>Recent Appointments</TextTitle>
          {pastAppointmentsList}
        </Content>
      </div>
    );
  };

  const renderCancelledAppointments = () => {
    const cancelledAppointmentsList = cancelledAppointments.map((el) => {
      return <Appointment key={el._id} appointment={el} />;
    });

    return (
      <div className="home-cancelled-appointments">
        <Content>
          <TextTitle>Cancelled Appointments</TextTitle>
          {cancelledAppointmentsList}
        </Content>
      </div>
    );
  };

  return (
    <>
      <PageHeader />
      <div className="home">
        <Content>
          <TextTitle className="home__title">Hi {patient.firstName},</TextTitle>
          <TextTitle className="home__sub-title" type="sub">
            Welcome to the clinic
          </TextTitle>
        </Content>

        {welcomeBannerIsDismissed !== true && (
          <InfoBanner onClickDismiss={onClickInfoBannerDismiss}>
            <strong>
              <u>This is your home screen.</u>
            </strong>
            <br />
            From here, you can manage your appointments, update your medical
            profile and access our help centre.
          </InfoBanner>
        )}

        {upcomingAppointments.length > 0 && renderUpcomingAppointments()}

        <Content>
          {upcomingAppointments.length === 0 && (
            <>
              <TextBody>Get started by booking an appointment.</TextBody>
              <Link
                to="/book-appointment"
                className="home__book-appointment home__book-appointment--margin-bottom"
              >
                <Button>Book Appointment</Button>
              </Link>
            </>
          )}
          {upcomingAppointments.length === 0 &&
          pastAppointments.length === 0 ? (
            <Link to="/help">
              <Button color="primary-link">How does the clinic work?</Button>
            </Link>
          ) : null}
        </Content>

        {pastAppointments.length > 0 && renderPastAppointments()}

        {cancelledAppointments.length > 0 && renderCancelledAppointments()}

        <div className="home-footer">
          Regulated by Healthcare Inspectorate Wales, Certificate number:
          HIW/01134
        </div>
      </div>
      <NavBar active="HOME" />
    </>
  );
};

export default Home;
