import './LoadingSpinner.scss';
import PropTypes from 'prop-types';

const LoadingSpinner = (props) => {
  const { className, fullscreen, ...rest } = props;

  const classes = ['loading-spinner'];

  if (className.length > 0) {
    classes.push(className);
  }

  if (fullscreen === true) {
    classes.push('loading-spinner--fullscreen');
  }

  return (
    <div
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      className={classes.join(' ')}
    >
      <div className="loading-spinner__dots">
        <div className="loading-spinner__bounce loading-spinner__bounce--1" />
        <div className="loading-spinner__bounce loading-spinner__bounce--2" />
        <div className="loading-spinner__bounce loading-spinner__bounce--3" />
      </div>
    </div>
  );
};

LoadingSpinner.propTypes = {
  className: PropTypes.string,
  fullscreen: PropTypes.bool,
};

LoadingSpinner.defaultProps = {
  className: '',
  fullscreen: false,
};

export default LoadingSpinner;
