import './MonthYearPicker.scss';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import FormFieldError from '../elements/form/FieldError';

dayjs.extend(isSameOrAfter);

const MonthYearPicker = (props) => {
  const { register, error, className, name } = props;

  const classes = ['form-month-year-picker'];

  if (className.length > 0) {
    classes.push(className);
  }

  let errorLabel = '';
  if (error) {
    const errorMonth = error.month && error.month.message.length > 0;
    const errorYear = error.year && error.year.message.length > 0;

    if (errorMonth || errorYear) {
      errorLabel = error.month ? error.month.message : error.year.message;
    }
  }

  const renderMonths = () => {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    const options = [
      <option key="label" value="">
        Month
      </option>,
      ...months.map((el, i) => (
        <option key={el} value={i}>
          {el}
        </option>
      )),
    ];

    return (
      <select
        className="form-month-year-picker__select form-month-year-picker__month"
        name={`${name}[month]`}
        ref={register({
          required: 'This field is required.',
        })}
      >
        {options}
      </select>
    );
  };

  const renderYears = () => {
    const startYear = dayjs().subtract(40, 'year');
    const endYear = dayjs();

    const years = [];
    let current = startYear;
    while (current.isSameOrBefore(endYear, 'year')) {
      years.push(current.format('YYYY'));
      current = current.add(1, 'year');
    }

    const options = [
      <option key="label" value="">
        Year
      </option>,
      ...years.map((el) => (
        <option key={el} value={el}>
          {el}
        </option>
      )),
    ];

    return (
      <select
        className="form-month-year-picker__select form-month-year-picker__year"
        name={`${name}[year]`}
        ref={register({
          required: 'This field is required.',
        })}
      >
        {options}
      </select>
    );
  };

  return (
    <div className={classes.join(' ')}>
      <div className="form-month-year-picker__selects">
        {renderMonths()}
        {renderYears()}
      </div>
      {errorLabel.length > 0 ? <FormFieldError label={errorLabel} /> : null}
    </div>
  );
};

MonthYearPicker.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  error: PropTypes.object,
  register: PropTypes.func.isRequired,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
};

MonthYearPicker.defaultProps = {
  error: null,
  className: '',
};

export default MonthYearPicker;
