import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectPatientIsAuthenticated } from '../slices/patientSlice';

// Wrap a Route with an authentication check and redirect to /login if the
// user isn't authenticated
function AuthenticationRequiredRoute({ component: Component, ...rest }) {
  const userIsAuthenticated = useSelector(selectPatientIsAuthenticated);

  const render = (props) => {
    if (userIsAuthenticated === true) {
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <Component {...props} />;
    }
    return <Redirect to={{ pathname: '/login' }} />;
  };

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Route {...rest} render={render} />;
}

AuthenticationRequiredRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.instanceOf(React.Component),
    PropTypes.func,
  ]).isRequired,
};

export default AuthenticationRequiredRoute;
