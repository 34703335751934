import './FieldError.scss';
import PropTypes from 'prop-types';

const FieldError = (props) => {
  const { label, className } = props;

  const classes = ['field-error'];

  if (className.length > 0) {
    classes.push(className);
  }

  if (label.length === 0) {
    return null;
  }

  const renderLabel = () => {
    const split = label.split('\n');
    if (split.length > 1) {
      return split.map((el, i) => {
        // eslint-disable-next-line react/no-array-index-key
        return <li key={i}>{el}</li>;
      });
    }
    return split[0];
  };

  return <div className={classes.join(' ')}>{renderLabel()}</div>;
};

FieldError.propTypes = {
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
};

FieldError.defaultProps = {
  className: '',
};

export default FieldError;
