import './TextTitle.scss';
import PropTypes from 'prop-types';

const TextTitle = (props) => {
  const { type, centerText, className, children, ...rest } = props;

  const classes = ['text-title'];
  classes.push(`text-title--${type}`);

  if (centerText) {
    classes.push('text-title--center');
  }

  if (className.length > 0) {
    classes.push(className);
  }

  return (
    <div
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      className={classes.join(' ')}
    >
      {children}
    </div>
  );
};

TextTitle.propTypes = {
  type: PropTypes.oneOf(['main', 'sub', 'body']),
  centerText: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

TextTitle.defaultProps = {
  type: 'main',
  centerText: false,
  className: '',
};

export default TextTitle;
