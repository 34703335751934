import './Pay.scss';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import TextTitle from '../../elements/TextTitle';
import TextBody from '../../elements/TextBody';
import Button from '../../elements/Button';
import Content from '../../elements/Content';
import { selectAllPractitioners } from '../../../slices/practitionersSlice';
import AlertError from '../../elements/alert/Error';
import { fetchPatient } from '../../../slices/patientSlice';
import { bookAppointmentWithPrepaidCode } from '../../../services/api/apiAppointment';
import LoadingSpinner from '../../elements/LoadingSpinner';

const Pay = (props) => {
  const { selectedDate, appointment } = props;
  const practitioners = useSelector(selectAllPractitioners);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [apiError, setApiError] = useState();
  const [apiErrorLabel, setApiErrorLabel] = useState();
  const [prepaidCode, setPrepaidCode] = React.useState();
  const dispatch = useDispatch();
  const history = useHistory();

  const practitioner = practitioners.find(
    (el) => el._id === appointment.practitionerId
  );
  if (!practitioner) {
    return <div>Cannot load appointment</div>;
  }

  const onCodeEntry = (e) => {
    setPrepaidCode(e.target.value);
  };

  const onCodeConfirm = async () => {
    setPrepaidCode(prepaidCode.replaceAll('-', ''));
    const checkResponseOk = (res) => {
      if (res !== 'OK') throw Error(res.errorMessage);
    };
    try {
      setRequestInProgress(true);
      const res = await bookAppointmentWithPrepaidCode({
        appointmentId: appointment._id,
        prepaidCode,
      });
      checkResponseOk(res);
      // Introduce a small delay before forwarding
      dispatch(fetchPatient());
      history.push(`/book-appointment/${appointment._id}/success`);
    } catch (err) {
      const errorMessage = err.message
        ? err.message
        : 'There was an error using the prepaid code you have specified. It may have been used or is unavailable.';
      setApiError(
        'If you believe it is valid, please try again. If the problem continues please email clinic@healthandher.com.'
      );
      setApiErrorLabel(errorMessage);
      window.scrollTo(0, 0);
      setRequestInProgress(false);
    }
  };

  const onCloseAlertError = () => {
    setApiError(null);
    setApiErrorLabel(null);
  };

  const startAt = dayjs(appointment.startAt);
  const endAt = dayjs(appointment.endAt);

  const practitionerFullname = [
    practitioner.title,
    practitioner.firstName,
    practitioner.lastName,
  ].join(' ');

  return (
    <div className="book-appointment-confirm">
      <Content>
        {apiError && (
          <AlertError
            errorLabel={apiErrorLabel}
            label={apiError}
            onClose={onCloseAlertError}
          />
        )}

        <TextTitle type="sub" className="book-appointment-confirm__sub-title">
          Confirm appointment &amp; pay
        </TextTitle>

        <TextTitle type="sub">Appointment date</TextTitle>
        <TextBody>{selectedDate.format('dddd Do MMMM YYYY')}</TextBody>

        <TextTitle type="sub">Appointment time</TextTitle>
        <TextBody>
          {startAt.format('h:mm A')} - {endAt.format('h:mm A')}
        </TextBody>

        <TextTitle type="sub">Doctor</TextTitle>
        <TextBody>{practitionerFullname}</TextBody>
        {requestInProgress === true ? (
          <LoadingSpinner />
        ) : (
          <>
            <TextTitle type="sub">Redeem a pre-paid voucher code</TextTitle>
            <TextBody>
              Please enter your 12-character voucher code below
            </TextBody>
            <div className="book-appointment-confirm__row">
              <input
                type="text"
                onChange={onCodeEntry}
                className="form-text-input__field"
                size={14}
                maxLength={14}
              />
              <Button
                onClick={onCodeConfirm}
                className="book-appointment-confirm__pay-code"
              >
                Confirm
              </Button>
            </div>
          </>
        )}
      </Content>
    </div>
  );
};

Pay.propTypes = {
  appointment: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    practitionerId: PropTypes.string.isRequired,
    startAt: PropTypes.instanceOf(dayjs).isRequired,
    endAt: PropTypes.instanceOf(dayjs).isRequired,
  }).isRequired,
  selectedDate: PropTypes.instanceOf(dayjs).isRequired,
  onStageComplete: PropTypes.func.isRequired,
};

export default Pay;
