import './Password.scss';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { Auth } from '@aws-amplify/auth';
import PageHeader from '../../elements/PageHeader';
import PasswordChangeForm from '../../forms/PasswordChangeForm';
import TextBody from '../../elements/TextBody';
import { changePasswordNotify } from '../../../services/api/apiAuth';

const Password = (props) => {
  const { onClickBack } = props;

  const onComplete = (
    setGlobalFormError,
    currentPassword,
    newPassword,
    setRequestInProgress
  ) => {
    setRequestInProgress(true);
    Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.changePassword(user, currentPassword, newPassword);
      })
      .then(async () => {
        await changePasswordNotify();
        await Auth.signOut({ global: true });
        setRequestInProgress(false);
        window.location.href = '/login';
      })
      .catch((err) => {
        setGlobalFormError(
          err.message ||
            'There was an error changing your password, please check your details and try again'
        );
        window.scrollTo(0, 0);
        setRequestInProgress(false);
      });
  };

  const beforePasswordForm = () => (
    <TextBody>
      Create a new strong password for your Health &amp; Her Clinic account.
      Once this is changed, you will be logged out from every device you’re
      logged in on and will need to sign in again.
    </TextBody>
  );

  return (
    <>
      <PageHeader showBack onClickBack={onClickBack} />
      <PasswordChangeForm
        beforePasswordForm={beforePasswordForm}
        onComplete={onComplete}
        useState={useState}
        useForm={useForm}
      />
    </>
  );
};

Password.propTypes = {
  onClickBack: PropTypes.func.isRequired,
};

export default Password;
