import PropTypes from 'prop-types';
import './ProgressionBar.scss';

const ProgressionBar = ({ steps = 10, currentStep = 0 }) => {
  const dots = [];
  for (let i = 0; i < steps; i += 1) {
    dots.push(
      <li
        key={`step-${i}`}
        className={i === currentStep - 1 ? 'current' : null}
      />
    );
  }
  return <ol className="progression-bar">{dots}</ol>;
};

ProgressionBar.propTypes = {
  currentStep: PropTypes.number,
  steps: PropTypes.number,
};

export default ProgressionBar;
