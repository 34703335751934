import './Register.scss';
import { useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import { Auth } from '@aws-amplify/auth';
import TextTitle from '../../elements/TextTitle';
import TextBody from '../../elements/TextBody';
import Button from '../../elements/Button';
import TextInput from '../../elements/form/TextInput';
import Content from '../../elements/Content';
import FormProfile from '../../forms/PatientProfile';
import FormPasswordAndConfirm from '../../forms/PasswordAndConfirm';
import { trackEvent, TrackEventNames } from '../../../services/tracking';
import FormConfirm from '../../elements/form/Confirm';
import ConfirmPrivacy from './confirm/Privacy';
import ConfirmEmailContact from './confirm/EmailContact';
import LoadingSpinner from '../../elements/LoadingSpinner';
import Logo from '../../elements/Logo';
import FormError from '../../elements/form/FormError';
import { register as registerApi } from '../../../services/api/apiAuth';
import { replacePatient } from '../../../slices/registerSlice';
import { fetchPatient } from '../../../slices/patientSlice';

dayjs.extend(utc);

const confirmViews = {
  TERMS: 'TERMS',
  PRIVACY: 'PRIVACY',
  CONTACT_EMAIL: 'CONTACT_EMAIL',
};

const defaultFormState = {};

const AboutYou = () => {
  const [currentConfirmView, setCurrentConfirmView] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const [userCache, setUserCache] = useState({});

  let defaultValueDob = { day: '', month: '', year: '' };
  if (defaultFormState.dob) {
    const dobDate = dayjs(defaultFormState.dob);
    defaultValueDob = {
      day: dobDate.date(),
      month: dobDate.month(),
      year: dobDate.year(),
    };
  }

  const {
    register,
    handleSubmit,
    watch,
    errors,
    getValues,
    setValue,
    trigger,
  } = useForm({
    defaultValues: { ...defaultFormState, dob: defaultValueDob },
  });

  const [requestInProgress, setRequestInProgress] = useState(false);
  const [apiError, setApiError] = useState();
  const [confirmEmail, setConfirmEmail] = useState();
  const [user, setUser] = useState(null);

  const registerRequest = async (data) => {
    setRequestInProgress(true);

    try {
      // 1st with Cognito
      const signup = await Auth.signUp({
        username: data.email,
        password: data.password,
      });
      setUserCache(data);

      data.userId = signup.userSub;
      const signUpObject = { ...data };
      delete signUpObject.password;

      // 2nd call registerApi to handle the thing
      await registerApi(signUpObject);

      // Now proceed to sign in

      await Auth.signIn(data.email, data.password).then((u) => {
        setUser(u);
      });

      // Store a local copy of the patient data so it can be accessed on the
      // next step (confirm-email)
      dispatch(replacePatient(data));

      trackEvent(TrackEventNames.REGISTER__STEP_1__ABOUT_YOU__COMPLETED);
      setConfirmEmail(data.email);
      setRequestInProgress(false);
      // history.push('/register/confirm-email');
    } catch (err) {
      trackEvent(TrackEventNames.REGISTER__STEP_1__ABOUT_YOU__FAILED);

      setApiError(
        err.name && err.name === 'UsernameExistsException' ? (
          <p>
            An account with this email address already exists.
            <br />
            Please try to <Link to="/login">log in</Link> instead.
          </p>
        ) : (
          <p>
            Error creating account. Please try again. If the problem continues
            please contact{' '}
            <a href="mailto:clinic@healthandher.com">clinic@healthandher.com</a>
          </p>
        )
      );
      setRequestInProgress(false);
      window.scrollTo(0, 0);
    }
  };

  const onSubmitConfirm = async (data) => {
    try {
      await Auth.sendCustomChallengeAnswer(user, data.login2FactorToken);
      trackEvent(TrackEventNames.REGISTER__STEP_2__CONF_EMAIL__COMPLETED);
      dispatch(fetchPatient());
      history.push('/');
    } catch (err) {
      // to help our user journey, and let them re-use the new persistent tokens, let's retry auth and quietly refresh the session if we have timed out
      if (err.toString().includes('NotAuthorizedException')) {
        try {
          // Try the process again using the cached creds
          await Auth.signIn(userCache.email, userCache.password).then((u) => {
            Auth.sendCustomChallengeAnswer(u, data.login2FactorToken).then(
              () => {
                console.log('Retrying challenge');
                trackEvent(
                  TrackEventNames.REGISTER__STEP_2__CONF_EMAIL__COMPLETED
                );
                dispatch(fetchPatient());
                history.push('/');
              },
              () => {
                setApiError(
                  <p>
                    {err.name && err.name === 'NotAuthorizedException'
                      ? 'Incorrect login code.'
                      : 'Something went wrong.'}{' '}
                    Please try again. If the problem continues please contact{' '}
                    <a href="mailto:clinic@healthandher.com">
                      clinic@healthandher.com
                    </a>
                  </p>
                );
              }
            );
          });

          return;
        } catch (err2) {
          console.log(err2);
          console.log('silent code rechallenge failed');
        }
      }

      setApiError(
        <p>
          {err.name && err.name === 'NotAuthorizedException'
            ? 'Incorrect login code.'
            : 'Something went wrong.'}{' '}
          Please try again. If the problem continues please contact{' '}
          <a href="mailto:clinic@healthandher.com">clinic@healthandher.com</a>
        </p>
      );
    }
  };

  const onSubmitForm = (data) => {
    const { dob, email, newPassword, confirmNewPassword, ...restOfAttributes } =
      data;

    const dobUtc = dayjs()
      .utc()
      .startOf('day')
      .month(dob.month)
      .year(dob.year)
      .date(dob.day)
      .toISOString();

    const emailLowerCase = email.toLowerCase();

    setApiError(null);
    registerRequest({
      ...restOfAttributes,
      password: newPassword,
      dob: dobUtc,
      email: emailLowerCase,
    });
  };

  const onClickConfirmViewAccept = () => {
    const acceptAttributes = {
      TERMS: 'confirmTerms',
      PRIVACY: 'confirmPrivacy',
      CONTACT_EMAIL: 'confirmAllowContactByEmail',
      MARKETING: 'confirmMarketing',
    };

    const attribute = acceptAttributes[currentConfirmView];
    if (attribute) {
      setValue(attribute, true, { shouldValidate: true });
    }

    setCurrentConfirmView(null);
  };

  const onClickConfirmViewClose = () => {
    setCurrentConfirmView(null);
  };

  const onClickViewMarketing = () => {
    setCurrentConfirmView(confirmViews.MARKETING);
  };

  const renderMainView = () => {
    return (
      <>
        <div className="register__logo">
          <Logo />
        </div>

        <TextTitle centerText>Registration</TextTitle>

        <TextBody centerText>
          To get started and check availability, please register an account with
          the Health &amp; Her Clinic.
        </TextBody>

        <TextBody centerText>
          Already have an account? <Link to="/login">Login</Link>
        </TextBody>

        {apiError && <FormError>{apiError}</FormError>}

        <form onSubmit={handleSubmit(onSubmitForm)}>
          <FormProfile
            errors={errors}
            register={register}
            getValues={getValues}
          />

          <TextInput
            label="Email Address"
            type="text"
            name="email"
            error={errors.email}
            registerRef={register({
              required: 'This field is required.',
              maxLength: {
                value: 254,
                message: 'Too many characters (max 254).',
              },
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Must be a valid email address.',
              },
            })}
            required
          />

          <FormPasswordAndConfirm
            errors={errors}
            register={register}
            watch={watch}
            labels={['Password', 'Confirm Password']}
            trigger={trigger}
            getValues={getValues}
          />

          <FormConfirm
            name="confirmTerms"
            error={!!errors.confirmTerms}
            errorLabel="You must accept the Terms &amp; Conditions."
            registerRef={register({ required: true })}
            withoutBorder
            withoutArrow
          >
            I accept the{' '}
            <a
              href="https://healthandher.com/terms-conditions-clinic/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <em>Terms &amp; Conditions</em>
            </a>
            *.
          </FormConfirm>

          <FormConfirm
            name="confirmPrivacy"
            error={!!errors.confirmPrivacy}
            errorLabel="You must accept the Privacy Policy."
            registerRef={register({ required: true })}
            withoutBorder
            withoutArrow
          >
            I accept the{' '}
            <a
              href="https://healthandher.com/privacy-policy-clinic/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <em>Privacy Policy</em>
            </a>
            *.
          </FormConfirm>

          <FormConfirm
            name="confirmAllowContactByEmail"
            error={!!errors.confirmAllowContactByEmail}
            errorLabel="You must accept contact by email."
            registerRef={register({ required: true })}
            withoutBorder
            withoutArrow
          >
            I agree to be contacted by email with important information about my
            Health & Her Clinic account*.
          </FormConfirm>
          <FormConfirm
            name="confirmMarketing"
            registerRef={register()}
            onClickView={onClickViewMarketing}
            withoutBorder
            withoutArrow
          >
            I agree to be contacted by email to receive news, offers and expert
            advice related to menopause.
          </FormConfirm>

          <div className="register-actions">
            {requestInProgress === true ? (
              <LoadingSpinner />
            ) : (
              <Button type="submit">Continue</Button>
            )}
          </div>
        </form>
      </>
    );
  };

  const renderConfirmView = () => {
    let confirmView;
    switch (currentConfirmView) {
      case confirmViews.PRIVACY:
        confirmView = (
          <ConfirmPrivacy
            onClickAccept={onClickConfirmViewAccept}
            onClickClose={onClickConfirmViewClose}
          />
        );
        break;
      case confirmViews.CONTACT_EMAIL:
        confirmView = (
          <ConfirmEmailContact
            onClickAccept={onClickConfirmViewAccept}
            onClickClose={onClickConfirmViewClose}
          />
        );
        break;
      default:
        confirmView = null;
    }

    return confirmView;
  };

  const renderConfirmEmail = () => {
    return (
      <div className="register">
        <Content className="register-content">
          <div className="register-confirm-email">
            <div className="register__logo">
              <Logo />
            </div>

            <TextTitle centerText>Almost there!</TextTitle>

            <TextBody centerText>
              An email containing a verification code has been sent to{' '}
              <em>{confirmEmail}</em>. Please enter this code below to complete
              your registration.
            </TextBody>

            <TextBody centerText>
              <strong>Not received your email?</strong> If you do not receive
              your code within a few minutes, please check your junk or spam
              folder.
            </TextBody>

            <form onSubmit={handleSubmit(onSubmitConfirm)}>
              {apiError !== null && <FormError>{apiError}</FormError>}

              <TextInput
                type="text"
                name="login2FactorToken"
                autoCorrect="off"
                autoCapitalize="off"
                autoComplete="off"
                error={errors.login2FactorToken}
                registerRef={register({
                  required: 'This field is required.',
                  maxLength: {
                    value: 10,
                    message: 'Too many characters (max 10).',
                  },
                })}
              />

              {requestInProgress === true ? (
                <LoadingSpinner />
              ) : (
                <div className="register-actions">
                  <Button type="submit">Continue</Button>
                </div>
              )}
            </form>
          </div>
        </Content>
      </div>
    );
  };

  return (
    <div className="register">
      <Content className="register-content">
        <div className="register-about-you">
          {renderConfirmView()}
          {confirmEmail ? renderConfirmEmail() : renderMainView()}
        </div>
      </Content>
    </div>
  );
};

export default AboutYou;
