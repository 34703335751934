import { useState, useEffect } from 'react';
import './Dob.scss';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { v4 as uuidv4 } from 'uuid';
import FormFieldError from '../elements/form/FieldError';

dayjs.extend(isSameOrBefore);

const Dob = (props) => {
  const { register, required, getValues, error } = props;
  const id = uuidv4();
  const classes = ['form-dob'];
  const [numberOfDaysInMonth, setNumberOfDaysInMonth] = useState(31);

  const handleMonthYearChange = () => {
    const month = getValues('dob.month');
    const year = getValues('dob.year');

    if (month && year)
      setNumberOfDaysInMonth(dayjs().year(year).month(month).daysInMonth());
  };

  useEffect(() => {
    handleMonthYearChange();
  }, []);

  let errorLabel = '';
  if (error && error.year && error.year.message.length > 0) {
    errorLabel = error.year.message;
  }

  const renderDays = () => {
    const days = [];
    for (let i = 1; i <= numberOfDaysInMonth; i += 1) {
      days.push(i);
    }

    const options = [
      <option key="label" value="" disabled>
        Day
      </option>,
      ...days.map((el) => (
        <option key={el} value={el}>
          {el}
        </option>
      )),
    ];

    return (
      <select
        className="form-dob__select form-dob__day"
        name="dob.day"
        ref={register}
      >
        {options}
      </select>
    );
  };

  const renderMonths = () => {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    const options = [
      <option key="label" value="" disabled>
        Month
      </option>,
      ...months.map((el, i) => (
        <option key={el} value={i}>
          {el}
        </option>
      )),
    ];

    return (
      <select
        className="form-dob__select form-dob__month"
        name="dob.month"
        ref={register}
        onChange={handleMonthYearChange}
      >
        {options}
      </select>
    );
  };

  const renderYears = () => {
    const startYear = dayjs().subtract(110, 'year');
    const endYear = dayjs();

    const years = ['Year'];
    let current = startYear;
    while (current.isSameOrBefore(endYear, 'year')) {
      years.push(current.format('YYYY'));
      current = current.add(1, 'year');
    }

    const options = [
      <option key="label" value="" disabled>
        Year
      </option>,
      ...years.map((el) => (
        <option key={el} value={el}>
          {el}
        </option>
      )),
    ];

    return (
      <select
        className="form-dob__select form-dob__year"
        name="dob.year"
        ref={register({
          required: 'This field is required.',
          validate: () => {
            const day = getValues('dob.day');
            const month = getValues('dob.month');
            const year = getValues('dob.year');

            // Ensure all fields have selected values
            if (day === '' || month === '' || year === '') {
              return 'You must be 18 years or older';
            }

            const dob = dayjs()
              .startOf('day')
              .month(month)
              .year(year)
              .date(day);

            const age = dayjs().diff(dob, 'year');
            return age >= 18 || 'You must be 18 years or older';
          },
        })}
        onChange={handleMonthYearChange}
      >
        {options}
      </select>
    );
  };

  return (
    <div className={classes.join(' ')}>
      <label className="form-dob__label" htmlFor={id}>
        Date of Birth
        {required && '*'}
      </label>
      <div className="form-dob__selects">
        {renderDays()}
        {renderMonths()}
        {renderYears()}
      </div>
      {errorLabel.length > 0 ? <FormFieldError label={errorLabel} /> : null}
    </div>
  );
};

Dob.propTypes = {
  required: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  error: PropTypes.object,
  register: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
};

Dob.defaultProps = {
  required: false,
  error: null,
};

export default Dob;
