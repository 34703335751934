import { createSlice } from '@reduxjs/toolkit';

export const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
    recentBookings: 0,
  },
  reducers: {
    replaceSettings(state, action) {
      return action.payload;
    },
  },
});

export const getRecentBookings = (state) => {
  return state.settings.recentBookings;
};

export const { replaceSettings } = settingsSlice.actions;

export default settingsSlice.reducer;
