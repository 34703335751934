import './InfoBanner.scss';
import PropTypes from 'prop-types';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CloseIcon from '@material-ui/icons/Close';
import TextBody from './TextBody';

const InfoBanner = (props) => {
  const {
    className,
    onClickDismiss,
    showInfoIcon,
    showDismissIcon,
    children,
    ...rest
  } = props;

  const classes = ['info-banner'];

  if (className.length > 0) {
    classes.push(className);
  }

  return (
    <div
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      className={classes.join(' ')}
    >
      <div className="info-banner__content">
        {showInfoIcon && <InfoOutlinedIcon className="info-banner__info" />}
        <TextBody className="info-banner__text">{children}</TextBody>
        {showDismissIcon && (
          <CloseIcon
            className="info-banner__dismiss"
            onClick={onClickDismiss}
          />
        )}
      </div>
    </div>
  );
};

InfoBanner.propTypes = {
  className: PropTypes.string,
  showInfoIcon: PropTypes.bool,
  showDismissIcon: PropTypes.bool,
  onClickDismiss: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

InfoBanner.defaultProps = {
  className: '',
  showInfoIcon: true,
  showDismissIcon: true,
  onClickDismiss: null,
};

export default InfoBanner;
