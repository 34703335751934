import './Account.scss';
import { useState } from 'react';
import { Auth } from '@aws-amplify/auth';
import NavBar from '../navigation/NavBar';
import PageHeader from '../elements/PageHeader';
import TextTitle from '../elements/TextTitle';
import Content from '../elements/Content';
import MenuList from '../elements/menu/List';
import MenuListItem from '../elements/menu/ListItem';
import Button from '../elements/Button';

import Profile from './about-you/Profile';
import Email from './about-you/Email';
import Address from './about-you/Address';
import Password from './security/Password';

const editStage = {
  PROFILE: 'PROFILE',
  EMAIL: 'EMAIL',
  ADDRESS: 'ADDRESS',
  PASSWORD: 'PASSWORD',
};

const Account = () => {
  const [currentEditStage, setCurrentEditStage] = useState(null);

  const onClickEditProfile = () => {
    setCurrentEditStage(editStage.PROFILE);
  };

  const onClickEditAddress = () => {
    setCurrentEditStage(editStage.ADDRESS);
  };

  const onClickEditPassword = () => {
    setCurrentEditStage(editStage.PASSWORD);
  };

  const onClickLogout = async () => {
    try {
      await Auth.signOut();
      // Perform a full page refresh here so we clear out all in memory
      // data stored in redux
      window.location = '/login';
    } catch (err) {
      console.log('API: Error logging out');
    }
  };

  const onEditStageComplete = () => {
    setCurrentEditStage(null);
  };

  const onEditStageClose = () => {
    setCurrentEditStage(null);
  };

  const renderEditStage = () => {
    let content;
    switch (currentEditStage) {
      case editStage.PROFILE:
        content = (
          <Profile
            onStageComplete={onEditStageComplete}
            onClickBack={onEditStageClose}
          />
        );
        break;
      case editStage.EMAIL:
        content = (
          <Email
            onStageComplete={onEditStageComplete}
            onClickBack={onEditStageClose}
          />
        );
        break;
      case editStage.ADDRESS:
        content = (
          <Address
            onStageComplete={onEditStageComplete}
            onClickBack={onEditStageClose}
          />
        );
        break;
      case editStage.PASSWORD:
        content = (
          <Password
            onStageComplete={onEditStageComplete}
            onClickBack={onEditStageClose}
          />
        );
        break;
      default:
        throw new Error(`stage not handled: ${currentEditStage}`);
    }

    window.scrollTo(0, 0);
    return content;
  };

  const renderMainContent = () => {
    return (
      <>
        <PageHeader title="Account" />
        <div className="account">
          <Content>
            <TextTitle type="sub">About You</TextTitle>

            <MenuList>
              <MenuListItem label="Your Profile" onClick={onClickEditProfile} />
              <MenuListItem label="Your Address" onClick={onClickEditAddress} />
            </MenuList>

            <TextTitle type="sub" className="account__title">
              Security
            </TextTitle>

            <MenuList>
              <MenuListItem
                label="Change Password"
                onClick={onClickEditPassword}
              />
            </MenuList>

            <Button
              color="primary"
              onClick={onClickLogout}
              className="account__logout"
            >
              Logout
            </Button>
          </Content>
        </div>
      </>
    );
  };

  const content =
    currentEditStage === null ? renderMainContent() : renderEditStage();

  return (
    <>
      {content}
      <NavBar active="ACCOUNT" />
    </>
  );
};

export default Account;
