import './AboutGp.scss';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import TextTitle from '../../elements/TextTitle';
import Button from '../../elements/Button';
import Content from '../../elements/Content';
import FormGpDetails from '../../forms/GpDetails';
import LoadingSpinner from '../../elements/LoadingSpinner';
import StepHeader from '../../elements/StepHeader';
import AlertError from '../../elements/alert/Error';
import { trackEvent, TrackEventNames } from '../../../services/tracking';
import {
  selectPatient,
  replacePatient,
  PatientRegisteredWithGp,
} from '../../../slices/patientSlice';
import { patch } from '../../../services/api/apiPatient';

const AboutGp = (props) => {
  const { onStageComplete } = props;
  const patient = useSelector(selectPatient);

  const { register, handleSubmit, setValue, watch, errors } = useForm({
    defaultValues: {
      registeredWithGp: patient.registeredWithGp
        ? patient.registeredWithGp
        : PatientRegisteredWithGp.YES,
      gpName: patient.gp && patient.gp.gpName,
      surgeryName: patient.gp && patient.gp.surgeryName,
      surgeryAddress: patient.gp && patient.gp.surgeryAddress,
      emergencyContact: patient.gp && patient.gp.emergencyContact,
      confirmSharePatientOwnGp:
        patient.gp && patient.gp.confirmSharePatientOwnGpAt,
    },
  });
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [apiError, setApiError] = useState();
  const dispatch = useDispatch();

  const patchRequest = async (data) => {
    setRequestInProgress(true);

    try {
      const response = await patch(data);
      dispatch(replacePatient(response.patient));

      trackEvent(TrackEventNames.BOOK_V2__STEP_4__YOUR_GP__COMPLETED);

      onStageComplete();
    } catch (err) {
      setApiError(
        'There was an error updating your GP details. Please try again.'
      );

      window.scrollTo(0, 0);
      setRequestInProgress(false);
    }
  };

  const onSubmitForm = (data) => {
    if (requestInProgress) {
      return;
    }

    const { registeredWithGp, ...gpDetails } = data;
    patchRequest({
      attributesToUpdate: {
        registeredWithGp,
        gp: registeredWithGp === PatientRegisteredWithGp.YES ? gpDetails : null,
      },
    });
  };

  const onCloseAlertError = () => {
    setApiError(null);
  };

  const patientName = patient.middleName
    ? [patient.firstName, patient.middleName, patient.lastName].join(' ')
    : [patient.firstName, patient.lastName].join(' ');

  const renderMainView = () => {
    return (
      <>
        <Content>
          <StepHeader currentStep={2} />
          <TextTitle type="sub" className="book-about-gp__sub-title">
            Tell us about your GP
          </TextTitle>
        </Content>
        <Content>
          {apiError && (
            <AlertError label={apiError} onClose={onCloseAlertError} />
          )}

          <form onSubmit={handleSubmit(onSubmitForm)}>
            <FormGpDetails
              errors={errors}
              register={register}
              watch={watch}
              setValue={setValue}
              patientName={patientName}
              showGpDetailsToHandQuestion={false}
            />

            <div className="book-actions">
              {requestInProgress === true ? (
                <LoadingSpinner />
              ) : (
                <Button type="submit">Continue</Button>
              )}
            </div>
          </form>
        </Content>
      </>
    );
  };

  return <div className="book-about-gp">{renderMainView()}</div>;
};

AboutGp.propTypes = {
  onStageComplete: PropTypes.func.isRequired,
};

export default AboutGp;
