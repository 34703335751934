import './PasswordChangeForm.scss';
import PropTypes from 'prop-types';
import Content from '../elements/Content';
import Button from '../elements/Button';
import TextInput from '../elements/form/TextInput';
import FormError from '../elements/form/FormError';
import FormPasswordAndConfirm from './PasswordAndConfirm';
import LoadingSpinner from '../elements/LoadingSpinner';
import TextTitle from '../elements/TextTitle';

const PasswordChangeForm = (props) => {
  const { beforePasswordForm, useForm, useState, onComplete } = props;
  const { register, handleSubmit, watch, trigger, getValues, errors } =
    useForm();
  const [globalFormError, setGlobalFormError] = useState(null);
  const [requestInProgress, setRequestInProgress] = useState(false);

  const onSubmitForm = async (data) => {
    if (requestInProgress) {
      return;
    }
    const { currentPassword, newPassword } = data;
    await onComplete(
      setGlobalFormError,
      currentPassword,
      newPassword,
      setRequestInProgress
    );
  };

  return (
    <div className="account-password">
      <Content>
        <TextTitle type="sub">Change Password</TextTitle>

        {beforePasswordForm && beforePasswordForm()}

        <form onSubmit={handleSubmit(onSubmitForm)}>
          {globalFormError !== null && <FormError>{globalFormError}</FormError>}

          <TextInput
            label="Current Password"
            type="password"
            name="currentPassword"
            error={errors.currentPassword}
            registerRef={register({
              required: 'This field is required.',
            })}
            required
          />

          <FormPasswordAndConfirm
            errors={errors}
            register={register}
            watch={watch}
            trigger={trigger}
            getValues={getValues}
          />

          {requestInProgress === true ? (
            <LoadingSpinner />
          ) : (
            <Button type="submit">Save</Button>
          )}
        </form>
      </Content>
    </div>
  );
};

PasswordChangeForm.propTypes = {
  onComplete: PropTypes.func.isRequired,
  beforePasswordForm: PropTypes.func,
  useState: PropTypes.func.isRequired,
  useForm: PropTypes.func.isRequired,
};

export default PasswordChangeForm;
