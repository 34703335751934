import './Address.scss';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import Content from '../../elements/Content';
import PageHeader from '../../elements/PageHeader';
import Button from '../../elements/Button';
import { selectPatient, replacePatient } from '../../../slices/patientSlice';
import { patch } from '../../../services/api/apiPatient';
import AlertError from '../../elements/alert/Error';
import FormPatientAddress from '../../forms/PatientAddress';
import TextTitle from '../../elements/TextTitle';

const Address = (props) => {
  const { onClickBack } = props;
  const dispatch = useDispatch();
  const [apiError, setApiError] = useState();
  const patient = useSelector(selectPatient);
  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      line1: patient.address && patient.address.line1,
      line2: patient.address && patient.address.line2,
      city: patient.address && patient.address.city,
      region: patient.address && patient.address.region,
      postcode: patient.address && patient.address.postcode,
      confirmAddressResidentUk:
        patient.address && patient.address.country === 'GB',
    },
  });

  const saveRequest = async (data) => {
    try {
      const response = await patch(data);
      dispatch(replacePatient(response.patient));
      onClickBack();
    } catch (err) {
      console.log(err);
      setApiError(
        'There was an error updating your address. Please try again.'
      );
    }
  };

  const onSubmitForm = (data) => {
    const { confirmAddressResidentUk, ...restOfAddress } = data;
    const address = {
      address: {
        ...restOfAddress,
        country: 'GB',
      },
    };
    saveRequest({ attributesToUpdate: address });
  };

  const onCloseAlertError = () => {
    setApiError(null);
  };

  return (
    <>
      <PageHeader showBack onClickBack={onClickBack} />
      <div className="account-address">
        <Content>
          {apiError && (
            <AlertError label={apiError} onClose={onCloseAlertError} />
          )}

          <TextTitle type="sub">Your Address</TextTitle>

          <form onSubmit={handleSubmit(onSubmitForm)}>
            <FormPatientAddress errors={errors} register={register} />

            <Button type="submit">Save</Button>
          </form>
        </Content>
      </div>
    </>
  );
};

Address.propTypes = {
  onClickBack: PropTypes.func.isRequired,
};

export default Address;
