import PropTypes from 'prop-types';
import EmergencyContactInfo from '../../../content/EmergencyContactInfo';
import Modal from './Modal';

const EmergencyContactInfoModal = (props) => {
  const { onClickAccept, onClickClose } = props;
  return (
    <Modal
      title="Emergency Contact Details"
      acceptButtonLabel="Continue"
      onClickClose={onClickClose}
      onClickAccept={onClickAccept}
    >
      <EmergencyContactInfo />
    </Modal>
  );
};

EmergencyContactInfoModal.propTypes = {
  onClickAccept: PropTypes.func.isRequired,
  onClickClose: PropTypes.func.isRequired,
};

export default EmergencyContactInfoModal;
