import './Contraception.scss';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import TextBody from '../../elements/TextBody';
import Content from '../../elements/Content';
import PageHeader from '../../elements/PageHeader';
import Button from '../../elements/Button';
import Radio from '../../elements/form/Radio';
import Checkbox from '../../elements/form/Checkbox';
import TextInput from '../../elements/form/TextInput';
import LoadingSpinner from '../../elements/LoadingSpinner';
import { selectAll as contraceptionsSelectAll } from '../../../slices/contraceptionsSlice';
import {
  selectPatient,
  selectContraceptionIds,
  replacePatient,
  PatientTakingContraception,
} from '../../../slices/patientSlice';
import { patch } from '../../../services/api/apiPatient';
import AlertError from '../../elements/alert/Error';
import FieldError from '../../elements/form/FieldError';
import TextTitle from '../../elements/TextTitle';

const Contraception = (props) => {
  const { onClickBack, showQuestion } = props;
  const patient = useSelector(selectPatient);
  const contraceptions = useSelector(contraceptionsSelectAll);
  const contraceptionIds = useSelector(selectContraceptionIds);
  const { register, handleSubmit, watch, errors } = useForm({
    defaultValues: {
      usingContraception: patient.takingContraception,
      contraception: contraceptionIds.reduce((acc, cur) => {
        acc[cur] = true;
        return acc;
      }, {}),
      takingContraceptionOther: patient.takingContraceptionOther,
    },
  });
  const [showNoItemsAddedError, setShowNoItemsAddedError] = useState(false);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [apiError, setApiError] = useState();
  const dispatch = useDispatch();

  const usingContraception = watch('usingContraception');

  const patchRequest = async (data) => {
    setRequestInProgress(true);

    try {
      const response = await patch(data);
      dispatch(replacePatient(response.patient));
      onClickBack();
    } catch (err) {
      setApiError(
        'There was an error updating your medical details. Please try again.'
      );

      window.scrollTo(0, 0);
      setRequestInProgress(false);
    }
  };

  const onSubmitForm = (data) => {
    setShowNoItemsAddedError(false);

    if (requestInProgress) {
      return;
    }

    let defaultUsingContraception;
    if (!showQuestion) {
      defaultUsingContraception = PatientTakingContraception.YES;
    } else {
      defaultUsingContraception = data.usingContraception;
    }

    const { contraception, takingContraceptionOther } = data;
    let checkedContraceptions = [];
    if (defaultUsingContraception === PatientTakingContraception.YES) {
      checkedContraceptions = Object.keys(contraception).filter((el) => {
        return contraception[el] === true;
      });
      if (
        checkedContraceptions.length === 0 &&
        takingContraceptionOther.length === 0
      ) {
        setShowNoItemsAddedError(true);
        window.scrollTo(0, 0);
        return;
      }
    }

    patchRequest({
      attributesToUpdate: {
        takingContraception: usingContraception,
        takingContraceptionDetails: checkedContraceptions,
        takingContraceptionOther:
          defaultUsingContraception === PatientTakingContraception.YES
            ? takingContraceptionOther
            : '',
      },
    });
  };

  const renderQuestion = () => {
    return (
      <>
        <TextBody>Are you currently taking or using contraception?</TextBody>
        <Radio
          name="usingContraception"
          values={[
            { label: 'Yes', value: PatientTakingContraception.YES },
            { label: 'No', value: PatientTakingContraception.NO },
          ]}
          error={errors.usingContraception}
          registerRef={register({ required: 'This field is required.' })}
        />
      </>
    );
  };

  const renderContraceptions = () => {
    const contraceptionList = contraceptions.map((el) => {
      return (
        <Checkbox
          key={el._id}
          label={el.name}
          id={`contraception-${el._id}`}
          name={`contraception[${el._id}]`}
          registerRef={register()}
        />
      );
    });

    return (
      <>
        <TextBody>Which form of contraception are you taking?</TextBody>
        {showNoItemsAddedError && (
          <FieldError
            label="You must specify at least one contraception."
            className="medical-medications__field-error"
          />
        )}

        {contraceptionList}

        <TextInput
          label="Other"
          type="text"
          name="takingContraceptionOther"
          error={errors.takingContraceptionOther}
          registerRef={register({
            maxLength: {
              value: 500,
              message: 'Too many characters (max 200).',
            },
          })}
        />
      </>
    );
  };

  const onCloseAlertError = () => {
    setApiError(null);
  };

  return (
    <>
      <PageHeader showBack onClickBack={onClickBack} />
      <div className="medical-contraception">
        <Content>
          {apiError && (
            <AlertError label={apiError} onClose={onCloseAlertError} />
          )}

          <TextTitle type="sub">Contraception</TextTitle>

          <form onSubmit={handleSubmit(onSubmitForm)}>
            {showQuestion && renderQuestion()}

            {usingContraception === PatientTakingContraception.YES &&
              renderContraceptions()}

            {requestInProgress === true ? (
              <LoadingSpinner />
            ) : (
              <Button type="submit">Save</Button>
            )}
          </form>
        </Content>
      </div>
    </>
  );
};

Contraception.propTypes = {
  onClickBack: PropTypes.func.isRequired,
  showQuestion: PropTypes.bool,
};

Contraception.defaultProps = {
  showQuestion: true,
};

export default Contraception;
