import './SelectDate.scss';
import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import TextTitle from '../../elements/TextTitle';
import TextBody from '../../elements/TextBody';
import Calendar from '../../elements/Calendar';
import Content from '../../elements/Content';
import {
  selectFetched,
  selectTypeInitialAvailabilityStatusGroupDate,
  SCHEDULE_PRICE,
  SCHEDULE_DURATION_PATIENT_MINS,
  schedulesLoading,
  schedulesReceived,
} from '../../../slices/scheduleSlice';
import { getRecentBookings } from '../../../slices/settingsSlice';
import AlertInfo from '../../elements/alert/Info';
import LoadingSpinner from '../../elements/LoadingSpinner';
import { trackEvent, TrackEventNames } from '../../../services/tracking';
import { getScheduleForPatients } from '../../../services/api/apiAppointment';
import { selectAllActivePractitioners } from '../../../slices/practitionersSlice';
import { selectPastAppointments } from '../../../slices/appointmentsSlice';
import logoVisa from './img/payment/visa.png';
import logoMastercard from './img/payment/mastercard.png';
import logoAmericanExpress from './img/payment/american-express.png';
import logoGooglePay from './img/payment/google-pay.svg';
import logoApplePay from './img/payment/apple-pay.svg';
import logoKlarna from './img/payment/klarna.svg';
import logoClinicVoucher from './img/payment/clinic-voucher.svg';

const SelectDate = (props) => {
  const { onStageComplete, startDate, practitionerId, setPractitionerId } =
    props;
  const [infoAlertText, setInfoAlertText] = useState('');
  const dispatch = useDispatch();
  const schedulesFetched = useSelector(selectFetched);
  const recentBookings = useSelector(getRecentBookings);
  const typeInitialAvailabilityStatusGroupDate = useSelector(
    selectTypeInitialAvailabilityStatusGroupDate
  );
  const practitioners = useSelector(selectAllActivePractitioners);
  const pastAppointments = useSelector(selectPastAppointments);

  const getSchedules = useCallback(async () => {
    dispatch(schedulesLoading());

    try {
      const { schedules } = await getScheduleForPatients(
        practitionerId || null
      );
      dispatch(schedulesReceived(schedules));
    } catch (err) {
      console.log('API: Error fetching schedule', err);
    }
  }, [dispatch, practitionerId]);

  const loadSchedules = useCallback(() => {
    getSchedules();
  }, [getSchedules]);

  useEffect(() => {
    // Load the schedules each time the view is created
    loadSchedules();
  }, [loadSchedules]);

  const onSelectDay = (selectedDate) => {
    const todayDate = dayjs().startOf('day');
    if (selectedDate.isBefore(todayDate)) {
      setInfoAlertText('You cannot select a date in the past');
      return;
    }

    trackEvent(TrackEventNames.BOOK_V2__STEP_1__SELECT_DATE__COMPLETED);

    onStageComplete(selectedDate);
  };

  const onCloseAlertGpNotes = () => {
    setInfoAlertText('');
  };

  const onChangePractitionerDropdown = (evt) => {
    const selectedPractitionerId = evt.target.value;
    setPractitionerId(selectedPractitionerId);
  };

  const getPreviouslySeen = (pracId) =>
    pastAppointments.some((past) => past.practitionerId === pracId)
      ? ' - previously seen'
      : null;

  const renderPractitionerDropdown = () => {
    const options = [
      ...practitioners.map((practitioner) => (
        <option key={practitioner._id} value={practitioner._id}>
          {practitioner.title} {practitioner.firstName} {practitioner.lastName}
          {getPreviouslySeen(practitioner._id)}
        </option>
      )),
    ];

    return (
      <select
        className="form-dob__select form-dob__month"
        name="practitioner"
        onChange={onChangePractitionerDropdown}
        value={practitionerId || undefined}
      >
        <option value="">
          All Menopause GPs - I don&apos;t mind who I see
        </option>
        {options}
      </select>
    );
  };

  if (!schedulesFetched) {
    return <LoadingSpinner />;
  }

  return (
    <div className="book-appointment-date">
      {infoAlertText.length > 0 && (
        <AlertInfo
          title="Select Appointment Date"
          label={infoAlertText}
          onClose={onCloseAlertGpNotes}
        />
      )}

      <Content>
        <TextTitle type="sub">Select GP preference:</TextTitle>
        {renderPractitionerDropdown()}
        <div className="book-appointment-date__title">
          <TextTitle type="sub">Select a date for your appointment:</TextTitle>
        </div>

        <div className="book-appointment-date__availabilities">
          <div className="book-appointment-date__availability book-appointment-date__availability--good">
            Good
          </div>
          <div className="book-appointment-date__availability book-appointment-date__availability--limited">
            Limited
          </div>
          {/* <div className="book-appointment-date__availability book-appointment-date__availability--fully-booked">
            Sold Out
          </div> */}
        </div>

        <div className="calendar-urgency">
          <Calendar
            startDate={startDate}
            onSelectDay={onSelectDay}
            availabilityStatuses={typeInitialAvailabilityStatusGroupDate}
          />
          {recentBookings && (
            <div className="calendar-urgency-message">
              <InfoOutlinedIcon className="calendar-urgency-icon" />{' '}
              {recentBookings} patients have registered in the last 48 hours.
            </div>
          )}
        </div>

        <TextBody>
          Online consultations last {SCHEDULE_DURATION_PATIENT_MINS} minutes and
          cost &pound;{SCHEDULE_PRICE}.
        </TextBody>

        <div className="line" />
        <TextBody>WE ACCEPT:</TextBody>
        <div className="book-appointment-confirm__logos">
          <img src={logoVisa} alt="Visa" />
          <img src={logoMastercard} alt="Mastercard" />
          <img src={logoAmericanExpress} alt="American Express" />
          <img src={logoGooglePay} alt="Google Pay" />
          <img src={logoApplePay} alt="Apple Pay" />
        </div>
        <div className="book-appointment-confirm__long-logo-and-label">
          <img src={logoKlarna} alt="Klarna" />
          <TextBody className="text-body--v-small">
            Pay in 3 interest-free payments.
          </TextBody>
        </div>
        <div className="book-appointment-confirm__logo-and-label">
          <img src={logoClinicVoucher} alt="Clinic-Voucher" />
          <TextBody className="text-body--v-small">
            Payments via pre-paid vouchers.
          </TextBody>
        </div>
      </Content>
    </div>
  );
};

SelectDate.propTypes = {
  onStageComplete: PropTypes.func.isRequired,
  startDate: PropTypes.instanceOf(dayjs),
  practitionerId: PropTypes.string,
  setPractitionerId: PropTypes.func.isRequired,
};

SelectDate.defaultProps = {
  startDate: dayjs(),
  practitionerId: null,
};

export default SelectDate;
