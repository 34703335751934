import './Details.scss';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import TextTitle from '../../elements/TextTitle';
import TextBody from '../../elements/TextBody';
import Button from '../../elements/Button';
import TextArea from '../../elements/form/TextArea';
import Content from '../../elements/Content';
import Checkbox from '../../elements/form/Checkbox';
import StepHeader from '../../elements/StepHeader';
import { trackEvent, TrackEventNames } from '../../../services/tracking';
import { selectAll as menopauseSymptomsSelectAll } from '../../../slices/menopauseSymptomsSlice';
import { selectSymptomIds } from '../../../slices/patientSlice';
import FieldError from '../../elements/form/FieldError';
import InfoBanner from '../../elements/InfoBanner';

const Details = (props) => {
  const { onStageComplete, formData } = props;
  const menopauseSymptoms = useSelector(menopauseSymptomsSelectAll);
  const symptomIds = useSelector(selectSymptomIds);

  const defaultValues = {
    ...formData,
    menopauseSymptom: symptomIds.reduce((acc, cur) => {
      acc[cur] = true;
      return acc;
    }, {}),
  };
  const { register, handleSubmit, errors, watch } = useForm({
    defaultValues,
  });
  const [medicalHistoriesError, setMedicalHistoriesError] = useState('');

  const onSubmitForm = (data) => {
    setMedicalHistoriesError('');

    const {
      patientPreAppointmentNotes,
      patientPreAppointmentReason,
      patientPreAppointmentQuestions,
      menopauseSymptom,
    } = data;

    const checkedMenopauseSymptomIds = Object.keys(menopauseSymptom).filter(
      (el) => {
        return menopauseSymptom[el] === true;
      }
    );

    if (checkedMenopauseSymptomIds.length === 0) {
      setMedicalHistoriesError('Please select at least one option below.');
      window.scrollTo(0, 0);
      return;
    }

    // Ensure only the None of the above option is selected by itself
    if (
      checkedMenopauseSymptomIds.includes('NONE') &&
      checkedMenopauseSymptomIds.length > 1
    ) {
      setMedicalHistoriesError(
        "You cannot select the 'None of the above' option with other options selected. Please review your answers."
      );
      window.scrollTo(0, 0);
      return;
    }

    trackEvent(TrackEventNames.BOOK_V2__STEP_12__ABOUT_YOUR_APPT__COMPLETED);

    onStageComplete({
      patientPreAppointmentNotes,
      patientPreAppointmentReason,
      patientPreAppointmentQuestions,
      menopauseSymptoms: checkedMenopauseSymptomIds,
    });
  };

  const renderMenopauseSymptoms = () => {
    const list = menopauseSymptoms.map((el) => {
      return (
        <Checkbox
          key={el._id}
          label={el.name}
          id={`book-appointment-details-menopause-symptoms-${el._id}`}
          name={`menopauseSymptom[${el._id}]`}
          registerRef={register()}
          className="book-appointment-details-menopause-symptoms__item"
        />
      );
    });

    list.push(
      <Checkbox
        key="none"
        label="None of the above"
        id="book-appointment-details-menopause-symptoms-none"
        name="menopauseSymptom[NONE]"
        registerRef={register()}
        className="book-appointment-details-menopause-symptoms__none-option"
      />
    );

    return (
      <div className="book-appointment-details-menopause-symptoms">{list}</div>
    );
  };

  const notesMaxCharacters = 500;

  return (
    <div className="book-appointment-details">
      <Content>
        <StepHeader currentStep={10} />
        <TextTitle type="sub" className="book-appointment-details__sub-title">
          About your menopause
        </TextTitle>
        <TextBody>
          To ensure that you get the most out of your appointment, it would be
          really helpful if you could tell us a little about why you are making
          this appointment by answering the following questions:
        </TextBody>
      </Content>

      <Content>
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <TextBody>
            What are the most severe menopause symptoms that you’re
            experiencing?
          </TextBody>

          {medicalHistoriesError.length > 0 && (
            <FieldError
              label={medicalHistoriesError}
              className="book-medical-history__field-error"
            />
          )}

          {renderMenopauseSymptoms()}

          <TextBody>
            In a few sentences, can you please tell us why you are making this
            appointment?
          </TextBody>
          <TextArea
            type="text"
            id="book-appointment-details-about-appointment-reason"
            name="patientPreAppointmentReason"
            error={errors.patientPreAppointmentReason}
            displayMaxCharacters={notesMaxCharacters}
            watch={watch}
            registerRef={register({
              required: 'This field is required.',
              maxLength: {
                value: notesMaxCharacters,
                message: `Too many characters (max ${notesMaxCharacters}).`,
              },
            })}
            className="book-appointment-details__notes"
          />

          <TextBody>
            Can you please explain how your recent symptoms are impacting on
            your life?
          </TextBody>
          <TextArea
            type="text"
            id="book-appointment-details-about-appointment-notes"
            name="patientPreAppointmentNotes"
            error={errors.patientPreAppointmentNotes}
            displayMaxCharacters={notesMaxCharacters}
            watch={watch}
            registerRef={register({
              required: 'This field is required.',
              maxLength: {
                value: notesMaxCharacters,
                message: `Too many characters (max ${notesMaxCharacters}).`,
              },
            })}
            className="book-appointment-details__notes"
          />

          <TextBody>
            Do you have any specific questions that you would like to have
            answered by our menopause GP? If so, please enter them here:
          </TextBody>
          <TextArea
            type="text"
            id="book-appointment-details-about-appointment-questions"
            name="patientPreAppointmentQuestions"
            error={errors.patientPreAppointmentQuestions}
            displayMaxCharacters={notesMaxCharacters}
            watch={watch}
            registerRef={register({
              maxLength: {
                value: notesMaxCharacters,
                message: `Too many characters (max ${notesMaxCharacters}).`,
              },
            })}
            className="book-appointment-details__questions"
          />

          <Checkbox
            label="I understand that the information I have provided will not be reviewed or acted upon immediately and may not be seen until the time of my appointment."
            labelHighlight="For urgent medical help, please call 999 or go straight to A&E."
            id="book-appointment-disclaimer"
            name="patientPreAppointmentDisclaimer"
            registerRef={register({
              required: 'This field is required.',
            })}
            error={errors.patientPreAppointmentDisclaimer}
            className="book-appointment-details-disclaimer"
          />

          <Button className="book-appointment-continue__button" type="submit">
            Continue
          </Button>

          <InfoBanner showDismissIcon={false} showInfoIcon={false}>
            <strong>
              Important: If you have seriously harmed yourself or are having
              suicidal thoughts, please call 999 for an ambulance or go straight
              to A&E. Help and support is available right now if you need it -
              you do not have to struggle with difficult feelings alone. Visit{' '}
              <a
                href="https://www.samaritans.org"
                target="_blank"
                rel="noreferrer noopener"
              >
                www.samaritans.org
              </a>{' '}
              for more information and support.
            </strong>
          </InfoBanner>
        </form>
      </Content>
    </div>
  );
};

Details.propTypes = {
  onStageComplete: PropTypes.func.isRequired,
  formData: PropTypes.shape({
    patientPreAppointmentNotes: PropTypes.string,
    patientPreAppointmentReason: PropTypes.string,
    patientPreAppointmentQuestions: PropTypes.string,
  }).isRequired,
};

export default Details;
