import './Faq.scss';
import { Link } from 'react-router-dom';
import TextTitle from '../elements/TextTitle';
import TextBody from '../elements/TextBody';
import {
  SCHEDULE_PRICE,
  SCHEDULE_DURATION_PATIENT_MINS,
} from '../../slices/scheduleSlice';

const Faq = () => {
  return (
    <div className="faqs">
      <div className="faq">
        <TextTitle type="body">How do I prepare for my consultation?</TextTitle>
        <div className="faq__answer">
          <TextBody>
            We recommend that you give yourself 15 minutes to log into your
            account and click the button to start your video consultation. If
            you’re using your mobile phone and/or tablet outside of your home,
            try to go somewhere quiet where you feel comfortable discussing your
            health. Remember, this appointment is about giving you the advice
            and support you require, so we don’t want you disturbed.
          </TextBody>
          <TextBody>
            Not sure where to start when it comes to discussing menopause with
            your doctor? Don’t worry, we’ve spoken to Dr Shilpa McQuillan who
            shares her practical advice for making the most of your appointment:
          </TextBody>
          <TextBody>
            <strong>There’s no need to feel embarrassed</strong>
            <br />
            Try to remember that menopause is really common and your doctor will
            have seen it and heard it before. Be honest, tell your story, and
            most importantly explain how it’s affecting you. This will help your
            doctor tailor information and choices to suit you.
          </TextBody>
          <TextBody>
            <strong>See your conversations as a journey together</strong>
            <br />
            Your doctor really wants to help, but may feel limited by the amount
            of information to discuss during your appointment. It’s important
            you don’t feel rushed, so think of your menopause as a journey and
            be prepared to arrange more than one appointment so that you feel
            all of your questions and concerns are addressed.
          </TextBody>
          <TextBody>
            <strong>Prepare and research</strong>
            <br />
            Making a list of questions or points you want to discuss will ensure
            your GP understands your agenda from the start and will cover the
            things most important to you.
          </TextBody>
          <TextBody>
            In addition, consider having some vital information ready to help
            your GP tailor advice that is safe and suitable for you.
          </TextBody>

          <TextBody>
            This may include:
            <ul>
              <li>
                A symptom diary with timeline notes for changes to your mood,
                sleep patterns and/or periods.
              </li>

              <li>
                Record of any relevant medical problems you or your close family
                members may have, such as history of breast or ovarian cancer,
                heart disease, or clots in the legs or lungs, as well as any
                previous operations you may have had on your womb, ovaries or
                cervix.
              </li>
            </ul>
          </TextBody>
        </div>
      </div>

      <div className="faq">
        <TextTitle type="body">
          How confidential and secure is the service?
        </TextTitle>
        <div className="faq__answer">
          <TextBody>
            We take privacy and security very seriously and take all reasonable
            and appropriate measures to protect any personal data we may collect
            while operating our service. Our servers are located in the UK, and
            all of the information transmitted through our platform is encrypted
            and protected with SSL technology.
          </TextBody>
          <TextBody>
            We also promise to uphold the Caldicott principles which ensure your
            information is protected and only used when it is appropriate to do
            so, as set out in our Privacy Policy.
          </TextBody>
        </div>
      </div>

      <div className="faq">
        <TextTitle type="body">Can I cancel my appointment?</TextTitle>
        <div className="faq__answer">
          <TextBody>
            We understand that circumstances can sometimes change and you may
            need to cancel your appointment. If you’re unable to make your
            appointment, we ask that you inform us as soon as you can, with at
            least 24 hours’ notice. If you cancel your appointment 24 hours
            before the date and time of your consultation, you will be entitled
            to a full refund. For more information, please see our{' '}
            <a
              href="https://healthandher.com/terms-conditions-clinic/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms and Conditions
            </a>
            .
          </TextBody>
        </div>
      </div>

      <div className="faq">
        <TextTitle type="body">Can I reschedule my appointment?</TextTitle>
        <div className="faq__answer">
          <TextBody>
            Yes, if your circumstances change you may reschedule your
            appointment up to 24 hours before the date and time of your
            appointment.{' '}
          </TextBody>
          <TextBody>
            If you need to cancel your appointment, you will need to inform us
            at least 24 hours before the date and time of your consultation in
            order to be entitled to a refund. For more information, please see
            our{' '}
            <a
              href="https://healthandher.com/terms-conditions-clinic/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms and Conditions
            </a>
            .
          </TextBody>
        </div>
      </div>

      <div className="faq">
        <TextTitle type="body">What if I need technical support?</TextTitle>
        <div className="faq__answer">
          <TextBody>
            We recommend that you select the option to begin your appointment 10
            minutes ahead of your appointment start time. This time will allow
            the system to conduct a video call set-up to check that you have
            everything you need in place, such as good internet connectivity,
            speaker, microphone and video.{' '}
          </TextBody>
          <TextBody>
            We’ve made the appointment process as easy and intuitive as possible
            via our online platform, but if you require any additional support
            you can speak to a member of our support team by calling us on 0333
            305 5903 and selecting option 3, or emailing us at{' '}
            <a href="mailto:clinic@healthandher.com">clinic@healthandher.com</a>
            .
          </TextBody>
          <TextBody>
            Ahead of your appointment, we also recommend reading our{' '}
            <Link
              to="/files/health-and-her-clinic-video-consultation-guide.pdf"
              target="_blank"
            >
              Video Consultation Guide
            </Link>
            .
          </TextBody>
        </div>
      </div>

      <div className="faq">
        <TextTitle type="body">Do I need a smartphone?</TextTitle>
        <div className="faq__answer">
          <TextBody>
            No - this is a popular option for our patients but you can also use
            a laptop or desktop computer for your appointment. Simply log into
            the Health &amp; Her Clinic via your browser and follow the
            on-screen instructions to get set up.
          </TextBody>
        </div>
      </div>

      <div className="faq">
        <TextTitle type="body">Can I book a follow-up appointment?</TextTitle>
        <div className="faq__answer">
          <TextBody>
            Yes, after your initial consultation, you can book a follow up{' '}
            {SCHEDULE_DURATION_PATIENT_MINS} minute appointment for &pound;
            {SCHEDULE_PRICE}.
          </TextBody>
        </div>
      </div>

      <div className="faq">
        <TextTitle type="body">Can I get a prescription for HRT?</TextTitle>
        <div className="faq__answer">
          <TextBody>
            Our GPs don’t currently offer private prescriptions but they can
            provide a letter of HRT recommendation for your GP, which we find is
            a more cost-effective option for most women.
          </TextBody>
        </div>
      </div>

      <div className="faq">
        <TextTitle type="body">How can I make a complaint?</TextTitle>
        <div className="faq__answer">
          <TextBody>
            We try to ensure that you are pleased with the experience of our
            service at all times and take complaints, concerns, and issues very
            seriously. If you would like to log a complaint, we pledge that it
            will be properly investigated in an unbiased, non-judgmental,
            transparent, timely and appropriate manner.
          </TextBody>

          <TextBody>
            <strong>When we look into a complaint, we aim to:</strong>
            <ul>
              <li>
                Make it possible for the complainant to discuss the problem with
                those concerned
              </li>

              <li>Find out what went wrong</li>

              <li>Issue an apology where appropriate</li>

              <li>
                Identify what we can do to ensure the problem does not arise
                again
              </li>
            </ul>
          </TextBody>

          <TextBody>
            Our complaints procedure is designed to make sure that we settle
            complaints as quickly and efficiently as possible, while keeping you
            regularly informed, as far as reasonably practicable, as to the
            progress of the investigation. Our aim is to acknowledge complaints
            within 3 working days, resolve them within 5 days, and respond
            within 10 working days. Complaints can be made by email or telephone
            using the contact details as outlined in the &apos;Getting in
            touch&apos; section of this guide.
          </TextBody>

          <TextBody>
            If you are dissatisfied with the outcome of any investigations, then
            a complaint can be referred to the team at:
          </TextBody>

          <TextBody>
            Healthcare Inspectorate Wales
            <br />
            Welsh Government
            <br />
            Rhydycar Business Park
            <br />
            Merthyr Tydfil
            <br />
            CF48 1UZ
          </TextBody>
          <TextBody>
            <strong>Telephone:</strong> 0300 062 8163
            <span className="faq__email">
              <strong>Email:</strong> hiw@gov.wales
            </span>
          </TextBody>
        </div>
      </div>
    </div>
  );
};

export default Faq;
