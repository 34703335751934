import { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { Auth } from '@aws-amplify/auth';
import TextTitle from '../../elements/TextTitle';
import TextBody from '../../elements/TextBody';
import Button from '../../elements/Button';
import TextInput from '../../elements/form/TextInput';
import FormError from '../../elements/form/FormError';
import LoadingSpinner from '../../elements/LoadingSpinner';
import FormPasswordAndConfirm from '../../forms/PasswordAndConfirm';
// import { forgottenPasswordChange } from '../../../services/api/apiAuth';
import { trackEvent, TrackEventNames } from '../../../services/tracking';

const ForgottenPasswordChange = (props) => {
  const { onStageComplete, email } = props;
  const { register, handleSubmit, watch, trigger, getValues, errors } = useForm(
    {
      defaultValues: {
        email: 'holly@patient.com',
      },
    }
  );
  const [globalFormError, setGlobalFormError] = useState(null);
  const [requestInProgress, setRequestInProgress] = useState(false);

  const forgottenPasswordChangeRequest = async (
    passwordResetCode,
    newPassword
  ) => {
    setRequestInProgress(true);
    try {
      console.log(
        await Auth.forgotPasswordSubmit(email, passwordResetCode, newPassword)
      );

      trackEvent(TrackEventNames.FORGOT_PASSWORD__STEP_2__COMPLETED);

      onStageComplete();
    } catch (err) {
      trackEvent(TrackEventNames.FORGOT_PASSWORD__STEP_2__FAILED);

      setGlobalFormError(
        <p>
          Incorrect password reset code, please try again. If the problem
          continues please email{' '}
          <a href="mailto:clinic@healthandher.com">clinic@healthandher.com</a>.
        </p>
      );
      window.scrollTo(0, 0);

      setRequestInProgress(false);
    }
  };

  const onSubmitForm = (data) => {
    if (requestInProgress) {
      return;
    }

    const { passwordResetCode, newPassword } = data;
    setGlobalFormError(null);
    forgottenPasswordChangeRequest(passwordResetCode, newPassword);
  };

  return (
    <>
      <TextTitle centerText>Forgotten Password</TextTitle>

      <form
        className="forgotten-password-form"
        onSubmit={handleSubmit(onSubmitForm)}
      >
        {globalFormError !== null && <FormError>{globalFormError}</FormError>}

        <TextBody centerText>
          If the email address you provided is registered for an account, you
          will be sent an email containing a password reset code. Please enter
          the code below.
        </TextBody>

        <TextInput
          label="Code (sent via email)"
          type="text"
          name="passwordResetCode"
          autoCorrect="off"
          autoCapitalize="off"
          autocomplete="off"
          error={errors.passwordResetCode}
          registerRef={register({
            required: 'This field is required.',
            maxLength: {
              value: 10,
              message: 'Too many characters (max 10).',
            },
          })}
        />

        <TextBody centerText>
          Create a new strong password for your Health &amp; Her Clinic account.
          Once this is changed, you will be logged out from every device you’re
          logged in on and will need to sign in again.
        </TextBody>

        <FormPasswordAndConfirm
          errors={errors}
          register={register}
          watch={watch}
          trigger={trigger}
          getValues={getValues}
        />

        <div className="forgotten-password__actions">
          {requestInProgress === true ? (
            <LoadingSpinner />
          ) : (
            <Button type="submit">Update Password</Button>
          )}
        </div>
      </form>
    </>
  );
};

ForgottenPasswordChange.propTypes = {
  onStageComplete: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
};

export default ForgottenPasswordChange;
