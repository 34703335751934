import './List.scss';
import PropTypes from 'prop-types';

const List = (props) => {
  const { className, children } = props;

  const classes = ['menu-list'];

  if (className.length > 0) {
    classes.push(className);
  }

  return <div className={classes.join(' ')}>{children}</div>;
};

List.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

List.defaultProps = {
  className: '',
};

export default List;
