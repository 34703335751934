import PropTypes from 'prop-types';
import TextInput from '../elements/form/TextInput';
import FormConfirm from '../elements/form/Confirm';

const PatientAddress = (props) => {
  const { errors, register } = props;

  return (
    <>
      <TextInput
        label="Address Line 1"
        type="text"
        name="line1"
        error={errors.line1}
        registerRef={register({
          required: 'This field is required.',
          maxLength: {
            value: 200,
            message: 'Too many characters (max 200).',
          },
        })}
        required
      />

      <TextInput
        label="Address Line 2"
        type="text"
        name="line2"
        error={errors.line2}
        registerRef={register({
          maxLength: {
            value: 200,
            message: 'Too many characters (max 200).',
          },
        })}
      />

      <TextInput
        label="City/Town"
        type="text"
        name="city"
        error={errors.city}
        registerRef={register({
          required: 'This field is required.',
          maxLength: {
            value: 200,
            message: 'Too many characters (max 200).',
          },
        })}
        required
      />

      <TextInput
        label="County"
        type="text"
        name="region"
        error={errors.region}
        registerRef={register({
          required: 'This field is required.',
          maxLength: {
            value: 200,
            message: 'Too many characters (max 200).',
          },
        })}
        required
      />

      <TextInput
        label="Postcode"
        type="text"
        name="postcode"
        error={errors.postcode}
        registerRef={register({
          required: 'This field is required.',
          maxLength: {
            value: 200,
            message: 'Too many characters (max 200).',
          },
        })}
        required
      />

      <FormConfirm
        name="confirmAddressResidentUk"
        error={!!errors.confirmAddressResidentUk}
        errorLabel="You must confirm you are a resident of the UK."
        registerRef={register({
          required: true,
        })}
        withoutBorder
      >
        I confirm I am a resident of the UK
      </FormConfirm>
    </>
  );
};

PatientAddress.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  errors: PropTypes.object.isRequired,
  register: PropTypes.func.isRequired,
};

export default PatientAddress;
