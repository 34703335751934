import './Register.scss';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Redirect, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// import { Auth } from '@aws-amplify/auth';
import TextTitle from '../../elements/TextTitle';
import Button from '../../elements/Button';
import Content from '../../elements/Content';
import TextInput from '../../elements/form/TextInput';
import TextBody from '../../elements/TextBody';
import FormError from '../../elements/form/FormError';
import LoadingSpinner from '../../elements/LoadingSpinner';
// import { registerConfirmEmail } from '../../../services/api/apiAuth';
import { trackEvent, TrackEventNames } from '../../../services/tracking';
import Logo from '../../elements/Logo';
import { fetchPatient } from '../../../slices/patientSlice';
import { selectPatient } from '../../../slices/registerSlice';

const ConfirmEmail = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  // Get the newly registered patient
  const patient = useSelector(selectPatient);
  const { register, handleSubmit, errors } = useForm();
  const [apiError, setApiError] = useState(null);
  const [requestInProgress, setRequestInProgress] = useState(false);

  const { email } = patient;

  const registerConfirmEmailRequest = async () => {
    setRequestInProgress(true);
    try {
      //  await Auth.sendCustomChallengeAnswer(user, login2FactorCode);

      //  await registerConfirmEmail(email, login2FactorToken);

      trackEvent(TrackEventNames.REGISTER__STEP_2__CONF_EMAIL__COMPLETED);

      dispatch(fetchPatient());
      history.push('/');
    } catch (err) {
      trackEvent(TrackEventNames.REGISTER__STEP_2__CONFIRM_EMAIL__FAILED);

      setApiError(
        <p>
          Incorrect verification code. Please check your email and try again.
        </p>
      );
      setRequestInProgress(false);
      window.scrollTo(0, 0);
    }
  };

  const onSubmitForm = (data) => {
    if (requestInProgress) {
      return;
    }

    const { login2FactorToken } = data;
    setApiError(null);
    registerConfirmEmailRequest(login2FactorToken);
  };

  // No patient, user trying to direct access this page without
  // completing the Register step
  if (!patient) {
    return <Redirect to="/register" />;
  }

  return (
    <div className="register">
      <Content className="register-content">
        <div className="register-confirm-email">
          <div className="register__logo">
            <Logo />
          </div>

          <TextTitle centerText>Almost there!</TextTitle>

          <TextBody centerText>
            An email containing a verification code has been sent to{' '}
            <em>{email}</em>. Please enter this code below to complete your
            registration.
          </TextBody>

          <TextBody centerText>
            <strong>Not received your email?</strong> If you do not receive your
            code within a few minutes, please check your junk or spam folder.
          </TextBody>

          <form onSubmit={handleSubmit(onSubmitForm)}>
            {apiError !== null && <FormError>{apiError}</FormError>}

            <TextInput
              type="text"
              name="login2FactorToken"
              autoCorrect="off"
              autoCapitalize="off"
              autoComplete="off"
              error={errors.login2FactorToken}
              registerRef={register({
                required: 'This field is required.',
                maxLength: {
                  value: 10,
                  message: 'Too many characters (max 10).',
                },
              })}
            />

            {requestInProgress === true ? (
              <LoadingSpinner />
            ) : (
              <div className="register-actions">
                <Button type="submit">Continue</Button>
              </div>
            )}
          </form>
        </div>
      </Content>
    </div>
  );
};

export default ConfirmEmail;
