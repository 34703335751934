import './Help.scss';
import { Link } from 'react-router-dom';
import NavBar from '../navigation/NavBar';
import PageHeader from '../elements/PageHeader';
import Content from '../elements/Content';
import Button from '../elements/Button';
import TextTitle from '../elements/TextTitle';
import TextBody from '../elements/TextBody';

const Help = () => {
  return (
    <>
      <PageHeader title="Help Centre" />
      <div className="help">
        <Content>
          <TextTitle type="body">How can we help?</TextTitle>
          <TextBody>
            At the Health &amp; Her Clinic, we offer access to specialist
            menopause care at the touch of a button, with guidance and support
            at every step of the way.
          </TextBody>

          <TextBody>
            Get started by reading our Video Consultation Guide and Frequently
            Asked Questions.
          </TextBody>

          <div>
            <Link
              to="/files/health-and-her-clinic-video-consultation-guide.pdf"
              target="_blank"
            >
              <Button color="primary-link" className="help__link">
                Video Consultation Guide
              </Button>
            </Link>
          </div>

          <div>
            <Link to="/help/faq">
              <Button color="primary-link" className="help__link">
                Frequently Asked Questions
              </Button>
            </Link>
          </div>

          <div>
            <Link to="/help/about">
              <Button color="primary-link" className="help__link">
                About Health &amp; Her
              </Button>
            </Link>
          </div>

          <TextTitle type="body" className="help__contact-title">
            Contact Us
          </TextTitle>
          <TextBody>
            If you have a question that isn’t answered here, please get in
            touch. You can contact our support team by emailing{' '}
            <a href="mailto:clinic@healthandher.com">clinic@healthandher.com</a>
            .
          </TextBody>
        </Content>
      </div>
      <NavBar active="HELP" />
    </>
  );
};
export default Help;
