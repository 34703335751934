import './ForgottenPassword.scss';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ForgottenPasswordEmail from './ForgottenPasswordEmail';
import ForgottenPasswordChange from './ForgottenPasswordChange';
import Content from '../../elements/Content';
import { fetchPatient } from '../../../slices/patientSlice';
import Logo from '../../elements/Logo';

const stages = {
  FORGOTTEN_PASSWORD_EMAIL: 'FORGOTTEN_PASSWORD_EMAIL',
  FORGOTTEN_PASSWORD_CHANGE: 'FORGOTTEN_PASSWORD_CHANGE',
};

const stageOrder = [
  stages.FORGOTTEN_PASSWORD_EMAIL,
  stages.FORGOTTEN_PASSWORD_CHANGE,
];

const ForgottenPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [currentStage, setCurrentStage] = useState(
    stages.FORGOTTEN_PASSWORD_EMAIL
  );
  const [email, setEmail] = useState();

  const onStageComplete = (data) => {
    switch (currentStage) {
      case stages.FORGOTTEN_PASSWORD_EMAIL:
        setEmail(data.email);
        break;
      case stages.FORGOTTEN_PASSWORD_CHANGE:
        dispatch(fetchPatient());
        history.push('/');
        break;
      default:
        throw new Error(`unexpected stage ${currentStage}`);
    }

    const currentStageIndex = stageOrder.findIndex((el) => el === currentStage);
    const nextStage = stageOrder[currentStageIndex + 1];
    if (nextStage !== undefined) {
      setCurrentStage(nextStage);
    }
  };

  const onClickStageGoBack = () => {
    const currentStageIndex = stageOrder.findIndex((el) => el === currentStage);
    const previousStage = stageOrder[currentStageIndex - 1];
    if (previousStage !== undefined) {
      setCurrentStage(previousStage);
    } else {
      history.push('/login');
    }
  };

  const renderStage = () => {
    let content;
    switch (currentStage) {
      case stages.FORGOTTEN_PASSWORD_EMAIL:
        content = <ForgottenPasswordEmail onStageComplete={onStageComplete} />;
        break;
      case stages.FORGOTTEN_PASSWORD_CHANGE:
        content = (
          <ForgottenPasswordChange
            onStageComplete={onStageComplete}
            email={email}
          />
        );
        break;
      default:
        throw new Error('stage not handled');
    }

    window.scrollTo(0, 0);
    return content;
  };

  return (
    <div className="forgotten-password">
      <div className="register__logo">
        <Logo />
      </div>
      <Content className="forgotten-password-content">{renderStage()}</Content>
      <div className="forgotten-password__back">
        <button
          className="button button--color-primary-link button--size-medium"
          onClick={onClickStageGoBack}
        >
          Back to Login
        </button>
      </div>
    </div>
  );
};

export default ForgottenPassword;
