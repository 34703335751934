import { baseApiUrl, jsonPatch, jsonGet } from './api';

export const getAccount = (data) => {
  const url = `${baseApiUrl}/account`;
  return jsonGet(url, data);
};

export const patch = (data) => {
  const url = `${baseApiUrl}/`;
  return jsonPatch(url, data);
};
