import './Confirm.scss';
import PropTypes from 'prop-types';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import FormFieldError from './FieldError';
import FormCheckbox from './Checkbox';

const Confirm = (props) => {
  const {
    labelExtra,
    name,
    registerRef,
    error,
    errorLabel,
    onClickView,
    children,
    withoutBorder,
    withoutArrow,
  } = props;

  const classes = ['form-confirm'];

  if (error) {
    classes.push('form-confirm--error');
  }

  const boxClasses = ['form-confirm__box'];
  if (withoutBorder) {
    boxClasses.push('form-confirm__box--without-border');
  }

  const checkboxClasses = ['form-confirm__checkbox'];
  if (labelExtra.length > 0) {
    checkboxClasses.push('form-confirm__checkbox--has-extra');
  }

  const onClickLabel = (evt) => {
    if (onClickView) {
      // Clicking the checkbox label should show the confirm modal
      // rather than toggling the checkbox
      evt.stopPropagation();
      evt.preventDefault();
      onClickView();
    }
  };

  const showArrow = onClickView && !withoutArrow && labelExtra.length === 0;

  return (
    <div className={classes.join(' ')}>
      <div className={boxClasses.join(' ')}>
        <div className="form-confirm__main">
          <FormCheckbox
            label={children}
            name={name}
            registerRef={registerRef}
            onClickLabel={onClickLabel}
            className={checkboxClasses.join(', ')}
          />
          {showArrow && (
            <div className="form-confirm__view" onClick={onClickView}>
              <ArrowForwardIosIcon />
            </div>
          )}
        </div>
        {labelExtra.length > 0 && (
          <div className="form-confirm__extra" onClick={onClickView}>
            {labelExtra}
          </div>
        )}
      </div>
      {error && errorLabel.length > 0 ? (
        <FormFieldError label={errorLabel} />
      ) : null}
    </div>
  );
};

Confirm.propTypes = {
  labelExtra: PropTypes.string,
  name: PropTypes.string.isRequired,
  registerRef: PropTypes.func.isRequired,
  error: PropTypes.bool,
  errorLabel: PropTypes.string,
  onClickView: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  withoutBorder: PropTypes.bool,
  withoutArrow: PropTypes.bool,
};

Confirm.defaultProps = {
  labelExtra: '',
  error: false,
  errorLabel: '',
  onClickView: null,
  withoutBorder: false,
  withoutArrow: false,
};

export default Confirm;
