import './AppRouter.scss';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import UnauthenticatedRoute from './UnauthenticatedRoute';
import AuthenticationRequiredRoute from './AuthenticationRequiredRoute';
import ScrollToTop from './ScrollToTop';
import LoadingSpinner from '../components/elements/LoadingSpinner';
import Login from '../components/auth/login/Login';
import ForgottenPassword from '../components/auth/forgottenPassword/ForgottenPassword';
import Register from '../components/auth/register/Register';
import RegisterConfirmEmail from '../components/auth/register/ConfirmEmail';
import Home from '../components/home/Home';
import Medical from '../components/medical/Medical';
import Help from '../components/help/Help';
import HelpFaq from '../components/help/Faq';
import HelpAbout from '../components/help/About';
import Account from '../components/account/Account';
import AppointmentBook from '../components/appointment/book/Book';
import AppointmentRebook from '../components/appointment/book/Rebook';
import AppointmentBookSuccess from '../components/appointment/book/Success';
import AppointmentUpcomingView from '../components/appointment/view/View';
import AppointmentUpcomingInProgress from '../components/appointment/view/InProgress';
import UnsupportedBrowser from '../components/general/UnsupportedBrowser';
import PageNotFound from '../components/general/PageNotFound';
import { fetchPatient, selectPatientFetched } from '../slices/patientSlice';
import { isSupportedBrowser } from '../services/browser';
import { GoogleAnalytics } from '../components/general/tracking/GoogleAnalytics';

const AppRouter = () => {
  const patientFetched = useSelector(selectPatientFetched);
  const dispatch = useDispatch();
  const showUnsupportedBrowser = !isSupportedBrowser();

  // Load the initial account state
  useEffect(() => {
    if (showUnsupportedBrowser) {
      return;
    }

    dispatch(fetchPatient());
  }, [dispatch, showUnsupportedBrowser]);

  const renderLoading = () => {
    return <LoadingSpinner fullscreen />;
  };

  const renderAuthenticatedRoutes = () => {
    return (
      <Switch>
        {/* Public routes - authentication not required */}
        {/* /welcome has been removed - redirect to pick up any direct links */}
        <UnauthenticatedRoute
          exact
          path="/welcome"
          render={() => <Redirect to="/register" />}
        />
        <Route
          exact
          path="/attend"
          render={() => {
            window.location =
              'https://consult.attendanywhere.co.uk/ce/?apikey=d0b2c4f7-3905-4afd-a8d0-87815231f833&nid=j1%2fFh567w';
          }}
        />
        <UnauthenticatedRoute exact path="/login" component={Login} />
        <UnauthenticatedRoute
          exact
          path="/forgotten-password"
          component={ForgottenPassword}
        />
        <Route exact path="/register" component={Register} />
        <Route
          exact
          path="/register/confirm-email"
          component={RegisterConfirmEmail}
        />
        {/* Multi stage registration has been removed - redirect to capture anyone
        who has the page open */}
        <UnauthenticatedRoute
          exact
          path="/register/:stage"
          render={() => <Redirect to="/register" />}
        />

        {/* Private routes - authentication required */}
        <AuthenticationRequiredRoute exact path="/" component={Home} />
        <AuthenticationRequiredRoute
          exact
          path="/medical"
          component={Medical}
        />
        <AuthenticationRequiredRoute exact path="/help" component={Help} />
        <AuthenticationRequiredRoute
          exact
          path="/help/faq"
          component={HelpFaq}
        />
        <AuthenticationRequiredRoute
          exact
          path="/help/about"
          component={HelpAbout}
        />
        <AuthenticationRequiredRoute
          exact
          path="/account"
          component={Account}
        />

        <AuthenticationRequiredRoute
          exact
          path="/book-appointment/:appointmentId/success"
          component={AppointmentBookSuccess}
        />
        <AuthenticationRequiredRoute
          exact
          path="/book-appointment/:stage?"
          component={AppointmentBook}
        />
        <AuthenticationRequiredRoute
          exact
          path="/rebook-appointment/:appointmentId/:stage?"
          component={AppointmentRebook}
        />
        <AuthenticationRequiredRoute
          exact
          path="/appointments/:appointmentId"
          component={AppointmentUpcomingView}
        />
        <AuthenticationRequiredRoute
          exact
          path="/appointments/:appointmentId/in-progress"
          component={AppointmentUpcomingInProgress}
        />
        <Route component={PageNotFound} />
      </Switch>
    );
  };

  let content;
  if (showUnsupportedBrowser) {
    content = <UnsupportedBrowser />;
  } else if (patientFetched) {
    content = renderAuthenticatedRoutes();
  } else {
    content = renderLoading();
  }

  return (
    <BrowserRouter>
      <ScrollToTop />
      {content}
      <GoogleAnalytics />
    </BrowserRouter>
  );
};

export default AppRouter;
