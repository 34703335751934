import './PageNotFound.scss';
import { Link } from 'react-router-dom';
import Logo from '../elements/Logo';
import TextBody from '../elements/TextBody';
import Content from '../elements/Content';
import Button from '../elements/Button';

const PageNotFound = () => {
  return (
    <div className="page-not-found">
      <div className="page-not-found-info">
        <div className="page-not-found-info__logo">
          <Logo />
        </div>

        <h1 className="page-not-found-info__title">Page not found!</h1>
      </div>

      <Content>
        <TextBody centerText>
          The page you are trying to access cannot be found.
        </TextBody>

        <div className="page-not-found__home">
          <Link to="/">
            <Button color="primary">Go to Home</Button>
          </Link>
        </div>

        <TextBody centerText>
          For help or further information please contact{' '}
          <a href="mailto:clinic@healthandher.com">clinic@healthandher.com</a>.
        </TextBody>
      </Content>
    </div>
  );
};

export default PageNotFound;
