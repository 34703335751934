import './Email.scss';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Content from '../../elements/Content';
import PageHeader from '../../elements/PageHeader';
import Button from '../../elements/Button';
import TextInput from '../../elements/form/TextInput';
import TextBody from '../../elements/TextBody';
import { selectPatient } from '../../../slices/patientSlice';

const Email = (props) => {
  const { onClickBack } = props;
  const [showUpdateEmailForm, setShowUpdateEmailForm] = useState(false);
  const patient = useSelector(selectPatient);
  const { register, handleSubmit, errors, formState } = useForm({
    defaultValues: {
      email: patient.email,
    },
  });
  const { dirtyFields } = formState;

  const onClickChangeAccountEmail = () => {
    setShowUpdateEmailForm(true);
  };

  const saveRequest = () => {
    try {
      onClickBack();
    } catch (err) {
      console.log('API: Error changing user email', err);
    }
  };

  const onSubmitForm = (data) => {
    const attributesToUpdate = Object.keys(dirtyFields).reduce((acc, cur) => {
      acc[cur] = data[cur];
      return acc;
    }, {});
    saveRequest({ attributesToUpdate });
  };

  const renderShowUpdateEmailForm = () => {
    return (
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <TextInput
          label="New Email"
          type="text"
          id="email-email"
          name="email"
          error={errors.email}
          registerRef={register({
            required: 'This field is required.',
            maxLength: {
              value: 200,
              message: 'Too many characters (max 200).',
            },
          })}
        />

        <Button type="submit">Update Email Address</Button>
      </form>
    );
  };

  let content = null;
  if (showUpdateEmailForm) {
    content = renderShowUpdateEmailForm();
  } else {
    content = (
      <Button type="submit" onClick={onClickChangeAccountEmail}>
        Change Account Email
      </Button>
    );
  }

  return (
    <>
      <PageHeader title="Your Email" showBack onClickBack={onClickBack} />
      <div className="account-email">
        <Content>
          <TextBody>
            The email address you registered for your account is{' '}
            <strong>{patient.email}</strong>.
          </TextBody>
          {content}
        </Content>
      </div>
    </>
  );
};

Email.propTypes = {
  onClickBack: PropTypes.func.isRequired,
};

export default Email;
