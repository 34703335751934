import TextBody from '../elements/TextBody';

const ShareWithPatientOwnGp = () => {
  return (
    <div className="share-with-patient-own-gp">
      <TextBody>
        With your consent, information relating to your appointment, including
        medication recommendations, treatment options, maybe shared with your
        NHS GP to support your ongoing care. You also consent for appropriate
        persons within Health and Her to access your clinical information for
        the sole purpose of supporting your ongoing care only. Please note that
        in the very unlikely event that our clinical team feel that either you
        or someone else may be in immediate danger, they may make contact with
        your GP, your emergency contact, and/or another healthcare professional
        regardless of your consent decision
      </TextBody>
    </div>
  );
};

export default ShareWithPatientOwnGp;
