import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import ProgressionBar from './ProgressionBar';
import TextTitle from './TextTitle';
import { selectBookingHeldAppointment } from '../../slices/appointmentsSlice';

const StepHeader = ({ currentStep }) => {
  const bookingHeldAppointment = useSelector(selectBookingHeldAppointment);
  const getFormattedSelectedDate = () => {
    const startAt = dayjs(bookingHeldAppointment.startAt);
    const isSameYear = startAt.isSame(dayjs(), 'year');
    const dateFormat = isSameYear
      ? 'Do MMMM [at] hh:mma'
      : 'Do MMMM YYYY [at] hh:mma';
    return startAt.format(dateFormat);
  };
  return (
    <>
      <ProgressionBar currentStep={currentStep} />
      <TextTitle type="sub">
        <strong>Your appointment:</strong> {getFormattedSelectedDate()}
      </TextTitle>
    </>
  );
};

StepHeader.propTypes = {
  currentStep: PropTypes.number,
};

export default StepHeader;
