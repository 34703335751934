import './Calendar.scss';
import { useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

dayjs.extend(isoWeek);

const Calendar = (props) => {
  const { className, startDate, onSelectDay, availabilityStatuses } = props;
  const [displayDate, setDisplayDate] = useState(startDate.startOf('month'));
  const todayDate = dayjs().startOf('day');

  const classes = ['calendar'];

  if (className.length > 0) {
    classes.push(className);
  }

  const getPrevMonth = () => {
    return displayDate.subtract(1, 'month');
  };

  const getNextMonth = () => {
    return displayDate.add(1, 'month');
  };

  const onClickPrevMonth = () => {
    setDisplayDate(getPrevMonth());
  };

  const onClickNextMonth = () => {
    setDisplayDate(getNextMonth());
  };

  const onClickDay = (selectedDate) => {
    if (onSelectDay) {
      onSelectDay(selectedDate);
    }
  };

  const renderMonthHeader = () => {
    const prevMonth = getPrevMonth();
    const nextMonth = getNextMonth();

    const isSameYear = displayDate.isSame(dayjs(), 'year');
    const dateFormat = isSameYear ? 'MMMM' : 'MMMM YYYY';

    return (
      <div className="calendar__header">
        <div className="calendar__header-inner">
          <div className="calendar__header-month" onClick={onClickPrevMonth}>
            <ChevronLeftIcon className="calendar__header-nav" />
            {prevMonth.format('MMM')}
          </div>
          <div className="calendar__header-month calendar__header-month--current">
            {displayDate.format(dateFormat)}
          </div>
          <div className="calendar__header-month" onClick={onClickNextMonth}>
            {nextMonth.format('MMM')}
            <ChevronRightIcon className="calendar__header-nav" />
          </div>
        </div>
      </div>
    );
  };

  const renderDayHeader = () => {
    const weekStart = displayDate.startOf('isoweek');
    const format = 'ddd';
    const dayLabels = [
      weekStart.format(format),
      weekStart.add(1, 'day').format(format),
      weekStart.add(2, 'day').format(format),
      weekStart.add(3, 'day').format(format),
      weekStart.add(4, 'day').format(format),
      weekStart.add(5, 'day').format(format),
      weekStart.add(6, 'day').format(format),
    ];

    const days = dayLabels.map((el) => {
      return (
        <div key={el} className="calendar__header-day">
          {el}
        </div>
      );
    });

    return (
      <div className="calendar__header-days">
        <div className="calendar__header-days-inner">{days}</div>
      </div>
    );
  };

  const getDayOfWeek = (dayjsDate) => {
    const jsDate = dayjsDate.toDate();
    // 0 - 6 (mon - sun)
    return (jsDate.getDay() + 6) % 7;
  };

  const renderDays = () => {
    const monthStart = displayDate;
    const monthEnd = displayDate.endOf('month');

    // Over draw the month so we display full weeks (this will take us into
    // the previous and next month)
    const firstDate = monthStart.subtract(getDayOfWeek(monthStart), 'days');
    const lastDate = monthEnd
      // Display a full week as last day of the month is unlikely to be a Sunday
      .add(6 - getDayOfWeek(monthEnd), 'days')
      // Plus render another full week so we display plenty of availability slots
      // to the users
      .add(1, 'week');

    const days = [];
    let currentDate = firstDate;
    while (currentDate.isBefore(lastDate)) {
      const classesForDayInner = ['calendar__body-day-inner'];

      if (currentDate.isSame(todayDate)) {
        classesForDayInner.push('calendar__body-day-inner--today');
      }

      if (currentDate.isBefore(todayDate)) {
        classesForDayInner.push('calendar__body-day-inner--past');
      } else {
        const dateKey = currentDate.format('YYYY-MM-DD');
        const availabilityStatus = availabilityStatuses[dateKey];

        if (availabilityStatus === undefined) {
          classesForDayInner.push('calendar__body-day-inner--no-availability');
        } else if (availabilityStatus === 'FULLY_BOOKED') {
          classesForDayInner.push('calendar__body-day-inner--fully-booked');
        } else if (availabilityStatus === 'LIMITED') {
          classesForDayInner.push(
            'calendar__body-day-inner--limited-availability'
          );
        } else if (availabilityStatus === 'GOOD') {
          classesForDayInner.push(
            'calendar__body-day-inner--good-availability'
          );
        }
      }

      // Create a block scoped copy of the date so it can be passed as an argument
      // for the click handler
      const blockScopedDate = currentDate;
      days.push(
        <div
          key={currentDate.format('YYYY-MM-DD')}
          className="calendar__body-day"
          onClick={() => {
            onClickDay(blockScopedDate);
          }}
        >
          <div className={classesForDayInner.join(' ')}>
            {currentDate.format('D')}
          </div>
        </div>
      );

      currentDate = currentDate.add(1, 'days');
    }
    return (
      <div className="calendar__body-days">
        <div className="calendar__body-days-inner">{days}</div>
      </div>
    );
  };

  return (
    <div className={classes.join(' ')}>
      {renderMonthHeader()}
      {renderDayHeader()}
      {renderDays()}
    </div>
  );
};

Calendar.propTypes = {
  className: PropTypes.string,
  startDate: PropTypes.instanceOf(dayjs),
  onSelectDay: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  availabilityStatuses: PropTypes.object.isRequired,
};

Calendar.defaultProps = {
  className: '',
  startDate: dayjs(),
  onSelectDay: null,
};

export default Calendar;
