import './MedicalProfile.scss';
import { useState } from 'react';
import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import TextTitle from '../../../elements/TextTitle';
import TextBody from '../../../elements/TextBody';
import Content from '../../../elements/Content';
import Button from '../../../elements/Button';
import TextInput from '../../../elements/form/TextInput';
import Radio from '../../../elements/form/Radio';
import FormMonthYearPicker from '../../../forms/MonthYearPicker';
import LoadingSpinner from '../../../elements/LoadingSpinner';
import StepHeader from '../../../elements/StepHeader';
import {
  selectPatient,
  PatientHadSmearTest,
  PatientSmoking,
  PatientAlcohol,
  replacePatient,
} from '../../../../slices/patientSlice';
import AlertError from '../../../elements/alert/Error';
import { patch } from '../../../../services/api/apiPatient';
import { trackEvent, TrackEventNames } from '../../../../services/tracking';

const MedicalProfile = (props) => {
  const { onStageComplete } = props;
  const patient = useSelector(selectPatient);
  const dispatch = useDispatch();

  let defaultValueLastSmearTestAt = null;
  if (patient.lastSmearTestAt) {
    const lastSmearTestAtDate = dayjs(patient.lastSmearTestAt);
    defaultValueLastSmearTestAt = {
      day: lastSmearTestAtDate.date(),
      month: lastSmearTestAtDate.month(),
      year: lastSmearTestAtDate.year(),
    };
  }

  const { register, errors, watch, handleSubmit } = useForm({
    defaultValues: {
      heightFeet: patient.heightFeet,
      heightInches: patient.heightInches,
      weightStone: patient.weightStone,
      weightPounds: patient.weightPounds,
      hadSmearTest: patient.hadSmearTest,
      lastSmearTestAt: defaultValueLastSmearTestAt,
      smokingStatus: patient.smokingStatus,
      alcoholStatus: patient.alcoholStatus,
      alcoholUnitPerWeek: patient.alcoholUnitPerWeek,
      alcoholFreeNightsPerWeek: patient.alcoholFreeNightsPerWeek,
    },
  });

  const [requestInProgress, setRequestInProgress] = useState(false);
  const [apiError, setApiError] = useState();

  const alcoholStatus = watch('alcoholStatus');
  const hadSmearTest = watch('hadSmearTest');

  const patchRequest = async (data) => {
    setRequestInProgress(true);

    try {
      const response = await patch(data);
      dispatch(replacePatient(response.patient));

      trackEvent(TrackEventNames.BOOK_V2__STEP_5__MEDICAL_PROFILE__COMPLETED);

      onStageComplete();
    } catch (err) {
      setApiError(
        'There was an error updating your medical profile. Please try again.'
      );

      window.scrollTo(0, 0);
      setRequestInProgress(false);
    }
  };

  const onSubmitForm = (data) => {
    if (requestInProgress) {
      return;
    }

    const { lastSmearTestAt, ...rest } = data;

    let lastSmearTestAtUtc = null;
    if (lastSmearTestAt) {
      lastSmearTestAtUtc = dayjs()
        .utc()
        .startOf('day')
        // We dont capture the date so default to 1st of month
        .date(1)
        .month(lastSmearTestAt.month)
        .year(lastSmearTestAt.year)
        .toISOString();
    }

    patchRequest({
      attributesToUpdate: {
        ...rest,
        lastSmearTestAt: lastSmearTestAtUtc,
      },
    });
  };

  const onCloseAlertError = () => {
    setApiError(null);
  };

  return (
    <div className="book-medical-profile">
      <Content>
        <StepHeader currentStep={3} />
      </Content>
      <Content>
        {apiError && (
          <AlertError label={apiError} onClose={onCloseAlertError} />
        )}

        <form onSubmit={handleSubmit(onSubmitForm)}>
          <TextBody>
            We&apos;ll now ask a series of personal questions relating to your
            medical history. It&apos;s important to ensure that you provide us
            with the most accurate and up to date information as this will help
            your doctor deliver the highest quality of care and service during
            your appointment.
          </TextBody>

          <TextBody>Please note that all fields are mandatory.</TextBody>

          <TextTitle type="sub">About You</TextTitle>

          <TextBody>What is your height?</TextBody>
          <div className="book-medical-profile-height">
            <TextInput
              label="Feet"
              type="number"
              name="heightFeet"
              error={errors.heightFeet}
              registerRef={register({
                required: 'This field is required.',
                min: {
                  value: 0,
                  message: 'Must be 0 or greater',
                },
              })}
              className="book-medical-profile__input-number"
              min="0"
            />

            <TextInput
              label="Inches"
              type="number"
              name="heightInches"
              error={errors.heightInches}
              registerRef={register({
                required: 'This field is required.',
                min: {
                  value: 0,
                  message: 'Must be 0 or greater',
                },
                max: {
                  value: 12,
                  message: 'Must be 12 or less',
                },
              })}
              className="book-medical-profile__input-number"
              min="0"
              max="12"
            />
          </div>

          <TextBody>What is your weight?</TextBody>
          <div className="book-medical-profile-height">
            <TextInput
              label="Stone"
              type="number"
              name="weightStone"
              error={errors.weightStone}
              registerRef={register({
                required: 'This field is required.',
                min: {
                  value: 0,
                  message: 'Must be 0 or greater',
                },
              })}
              className="book-medical-profile__input-number"
              min="0"
            />

            <TextInput
              label="Pounds"
              type="number"
              name="weightPounds"
              error={errors.weightPounds}
              registerRef={register({
                required: 'This field is required.',
                min: {
                  value: 0,
                  message: 'Must be 0 or greater',
                },
                max: {
                  value: 14,
                  message: 'Must be 14 or less',
                },
              })}
              className="book-medical-profile__input-number"
              min="0"
              max="14"
            />
          </div>

          <TextBody>Have you ever had a cervical smear test?</TextBody>
          <Radio
            name="hadSmearTest"
            values={[
              { label: 'Yes', value: PatientHadSmearTest.YES },
              { label: 'No', value: PatientHadSmearTest.NO },
            ]}
            error={errors.hadSmearTest}
            registerRef={register({ required: 'This field is required.' })}
          />

          {hadSmearTest === PatientHadSmearTest.YES ? (
            <>
              <TextBody className="book-medical-profile__date-picker-label">
                When was the date of your last cervical smear test?
              </TextBody>

              <FormMonthYearPicker
                name="lastSmearTestAt"
                register={register}
                error={errors.lastSmearTestAt}
                required
                className="book-medical-profile__month-year-picker"
              />
            </>
          ) : null}

          <TextTitle
            type="sub"
            className="book-medical-profile__sub-title book-medical-profile__sub-title"
          >
            Lifestyle
          </TextTitle>

          <TextBody>Do you smoke?</TextBody>
          <Radio
            name="smokingStatus"
            values={[
              {
                label: 'Current smoker',
                value: PatientSmoking.CURRENT_SMOKER,
              },
              { label: 'Ex-smoker', value: PatientSmoking.EX_SMOKER },
              { label: 'Never smoked', value: PatientSmoking.NEVER_SMOKED },
            ]}
            error={errors.smokingStatus}
            registerRef={register({ required: 'This field is required.' })}
          />

          <TextBody>Do you drink alcohol?</TextBody>

          <Radio
            name="alcoholStatus"
            values={[
              { label: 'Yes', value: PatientAlcohol.YES },
              { label: 'No', value: PatientAlcohol.NO },
            ]}
            error={errors.alcoholStatus}
            registerRef={register({ required: 'This field is required.' })}
          />

          {alcoholStatus === PatientAlcohol.YES ? (
            <>
              <TextInput
                label="How many units do you drink per week?"
                labelAfter={
                  <TextBody className="mb-sm text-style-normal" small>
                    Single spirit shot = 1 unit. Pint of beer/cider = 2 units.
                    Glass of wine = 2 units.
                  </TextBody>
                }
                type="number"
                name="alcoholUnitPerWeek"
                error={errors.alcoholUnitPerWeek}
                registerRef={register({
                  required: 'This field is required.',
                  min: {
                    value: 0,
                    message: 'Must be 0 or greater',
                  },
                })}
                className="book-medical-profile__input-number"
                min="0"
              />

              <TextInput
                label="How many alcohol-free nights do you have per week?"
                type="number"
                name="alcoholFreeNightsPerWeek"
                error={errors.alcoholFreeNightsPerWeek}
                registerRef={register({
                  required: 'This field is required.',
                  min: {
                    value: 0,
                    message: 'Must be 0 or greater',
                  },
                })}
                className="book-medical-profile__input-number"
                min="0"
              />
            </>
          ) : null}

          {requestInProgress === true ? (
            <LoadingSpinner />
          ) : (
            <Button type="submit">Continue</Button>
          )}
        </form>
      </Content>
    </div>
  );
};

MedicalProfile.propTypes = {
  onStageComplete: PropTypes.func.isRequired,
};

export default MedicalProfile;
