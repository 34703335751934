/* eslint-disable import/prefer-default-export */
export const awsconfig = {
  Auth: {
    region: process.env.REACT_APP_COG_REGION || 'eu-west-2',
    userPoolId: process.env.REACT_APP_COG_USER_POOL || 'eu-west-2_Dl63HdWF7',
    userPoolWebClientId:
      process.env.REACT_APP_COG_CLIENT_ID || '5kb79o5qscnbo5n4rokv98jua',
    mandatorySignIn: false,
    authenticationFlowType: 'CUSTOM_AUTH',
    storage: sessionStorage,
  },
};
