import './TextArea.scss';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import FormFieldError from './FieldError';
import TextBody from '../TextBody';

const TextArea = (props) => {
  const {
    label,
    name,
    placeholder,
    className,
    registerRef,
    watch,
    error,
    displayMaxCharacters,
  } = props;

  const id = uuidv4();
  const classes = ['form-text-area'];

  let errorLabel = '';
  if (Object.keys(error).length > 0) {
    classes.push('form-text-area--error');

    if (error.message.length > 0) {
      errorLabel = error.message;
    } else {
      errorLabel = 'Invalid input';
    }
  }

  if (className.length > 0) {
    classes.push(className);
  }

  let input;
  let usedCharacters;
  let maxCharacterClasses;
  if (watch) {
    input = watch(name);
    usedCharacters = input ? input.length : 0;
    maxCharacterClasses = ['form-text-area__max-characters'];

    if (usedCharacters > displayMaxCharacters) {
      maxCharacterClasses.push('form-text-area__max-characters--exceeded');
    }
  }

  return (
    <div className={classes.join(' ')}>
      <label className="form-text-area__label" htmlFor={id}>
        {label.length > 0 && label}
        <textarea
          className="form-text-area__field"
          id={id}
          name={name}
          ref={registerRef}
          placeholder={placeholder}
        />
        <div className="form-text-area__meta">
          {error && errorLabel.length > 0 ? (
            <FormFieldError label={errorLabel} />
          ) : null}
          {displayMaxCharacters !== false && (
            <TextBody className={maxCharacterClasses.join(' ')}>
              (max {usedCharacters} / {displayMaxCharacters} characters)
            </TextBody>
          )}
        </div>
      </label>
    </div>
  );
};

TextArea.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  registerRef: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  error: PropTypes.object,
  displayMaxCharacters: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  watch: PropTypes.func,
};

TextArea.defaultProps = {
  label: '',
  error: {},
  placeholder: '',
  className: '',
  displayMaxCharacters: false,
  watch: null,
};

export default TextArea;
