import './Modal.scss';
import { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import Button from '../../../elements/Button';
import TextTitle from '../../../elements/TextTitle';

const Modal = (props) => {
  const { onClickAccept, onClickClose, title, acceptButtonLabel, children } =
    props;
  const buttonRef = useRef(null);

  useEffect(() => {
    buttonRef.current?.focus();
  }, []);

  return (
    <div className="confirm-modal" onClick={onClickClose}>
      <div className="confirm-modal__content">
        <div className="confirm-modal__inner">
          <div className="confirm-modal__header">
            <TextTitle type="sub" className="confirm-modal__title">
              {title}
            </TextTitle>
            <CloseIcon
              className="confirm-modal__close"
              onClick={onClickClose}
            />
          </div>

          {children}

          <div className="confirm-modal__actions">
            <Button
              ref={buttonRef}
              onClick={onClickAccept}
              className="confirm-modal__accept"
            >
              {acceptButtonLabel}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  onClickAccept: PropTypes.func.isRequired,
  onClickClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  acceptButtonLabel: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Modal;
