import './Book.scss';
import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useParams, Redirect } from 'react-router-dom';
import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux';
import PageHeader from '../../elements/PageHeader';
import SelectDate from './SelectDate';
import SelectTime from './SelectTime';
import AlertConfirm from '../../elements/alert/Confirm';
import { selectAllPractitioners } from '../../../slices/practitionersSlice';
import { moveAppointment } from '../../../services/api/apiAppointment';
import { fetchPatient } from '../../../slices/patientSlice';

// import { bookSetAppointmentDetails } from '../../../services/api/apiAppointment';
import {
  selectBookingHeldAppointment,
  // replaceAppointment,
  selectPastAppointments,
} from '../../../slices/appointmentsSlice';
// import { replacePatient } from '../../../slices/patientSlice';
import { ScheduleTypes } from '../../../slices/scheduleSlice';
import NavBar from '../../navigation/NavBar';
import AlertInfo from '../../elements/alert/Info';

const stages = {
  SELECT_DATE: {
    id: 'SELECT_DATE',
    url: 'select-date',
  },
  SELECT_TIME: {
    id: 'SELECT_TIME',
    url: 'select-time',
  },
  CONFIRM_REBOOK: {
    id: 'CONFIRM_REBOOK',
    url: 'confirm-rebook',
  },
};

const stageOrder = [
  stages.SELECT_DATE.id,
  stages.SELECT_TIME.id,
  stages.CONFIRM_REBOOK.id,
];

const Rebook = () => {
  const history = useHistory();
  // const dispatch = useDispatch();
  const bookingHeldAppointment = useSelector(selectBookingHeldAppointment);

  let defaultSelectedDate;
  if (bookingHeldAppointment) {
    defaultSelectedDate = dayjs(bookingHeldAppointment.startAt);
  }
  const pastAppointments = useSelector(selectPastAppointments);
  const [proposedAppointment, setProposedAppointment] = React.useState();
  const [selectedDate, setSelectedDate] = useState(defaultSelectedDate);
  const [apiError, setApiError] = useState();
  const pracId = pastAppointments.find((past) => !!past.practitionerId);
  const [practitionerId, setPractitionerId] = useState(
    pracId ? pracId.practitionerId : null
  );

  const dispatch = useDispatch();

  const practitioners = useSelector(selectAllPractitioners);

  const scheduleType = ScheduleTypes.INITIAL;

  const getIndexForStage = (stageId) => {
    return stageOrder.findIndex((el) => el === stageId);
  };

  // Workout the current stage from the stage param in
  // the url, a default is set if it doesn't exist
  const { stage: stageParam, appointmentId } = useParams();
  const [stageCompleteTo, setStageCompleteTo] = useState();
  const currentStage = Object.keys(stages).find(
    (el) => stages[el].url === stageParam
  );

  const onConfirmMoveAppointment = async () => {
    // Make API call
    await moveAppointment({
      appointmentId,
      newAppointmentId: proposedAppointment._id,
    });

    // If successful, then reload account
    dispatch(fetchPatient());
    history.push('/');
  };

  const onClickStageGoBack = useCallback(() => {
    const stageIndex = getIndexForStage(currentStage);
    const previousStage = stageOrder[stageIndex - 1];
    if (previousStage !== undefined) {
      const { url } = stages[previousStage];
      setStageCompleteTo(previousStage);
      history.push(`/rebook-appointment/${appointmentId}/${url}`);
    } else {
      history.push('/');
    }
  }, [currentStage, appointmentId, history]);

  useEffect(() => {
    // Detect the pop state of the browser - (back button being clicked)
    // and handle the routing to work the same as the in app back button.
    // This is important to ensure back navigation works when the user
    // click back from the stripe checkout or cancels the strip checkout.
    // We need to ensure the user can navigate back from the pay stage to previous
    // stages using the browser back button
    window.addEventListener('popstate', onClickStageGoBack);
    return () => {
      window.removeEventListener('popstate', onClickStageGoBack);
    };
  }, [onClickStageGoBack]);

  // Invalid stage from stageParam, redirect to first stage
  if (!currentStage) {
    const firstStage = stageOrder[0];
    const { url } = stages[firstStage];
    return <Redirect to={`/rebook-appointment/${appointmentId}/${url}`} />;
  }

  // Ensure user isn't trying to access a later stage in appointment booking without
  // first passing through a previous stage, ie direct linking to stage 2 url
  // should not work unless landing on the last stage (ie, /pay).
  // We need to allow this as users can cancel or click back from the stripe
  // checkout and we want the booking process to pick up back on the /pay stage
  let stageCompletedToIndex = getIndexForStage(stageCompleteTo);
  if (stageCompletedToIndex === -1) {
    stageCompletedToIndex = 0;
  }

  const currentStageIndex = getIndexForStage(currentStage);

  if (stageCompletedToIndex < currentStageIndex) {
    const firstStage = stageOrder[stageCompletedToIndex];
    const { url } = stages[firstStage];
    return <Redirect to={`/rebook-appointment/${appointmentId}/${url}`} />;
  }

  const onChangeSelectedDate = (date) => {
    console.log(date);
    setSelectedDate(date);
  };

  const onStageComplete = async (stageData) => {
    // eslint-disable-next-line default-case
    switch (currentStage) {
      case stages.SELECT_DATE.id:
        setSelectedDate(stageData);
        break;
      /* case stages.DETAILS.id: {
        try {
          const details = {
            appointmentId: bookingHeldAppointment._id,
            ...stageData,
          };
          const { appointment, patient } = await bookSetAppointmentDetails(
            details
          );
          dispatch(replaceAppointment(appointment));
          dispatch(replacePatient(patient));
          window.scrollTo(0, 0);
        } catch (err) {
          setApiError(
            'Unable to update appointment, please try again or go back and select another time'
          );
          return;
        }
        break;
      } */
      case stages.SELECT_TIME.id:
        setProposedAppointment(stageData);
        break;
      default:
        console.log(currentStage);
    }

    window.scrollTo(0, 0);
    const stageIndex = getIndexForStage(currentStage);
    const nextStage = stageOrder[stageIndex + 1];
    if (nextStage !== undefined) {
      const { url } = stages[nextStage];
      setStageCompleteTo(nextStage);
      history.push(`/rebook-appointment/${appointmentId}/${url}`);
    }
  };

  // eslint-disable-next-line no-unused-vars
  const getFormattedSelectedDate = () => {
    const startAt = dayjs(bookingHeldAppointment.startAt);
    const isSameYear = startAt.isSame(dayjs(), 'year');
    const dateFormat = isSameYear
      ? 'Do MMMM [at] hh:mma'
      : 'Do MMMM YYYY [at] hh:mma';
    return startAt.format(dateFormat);
  };

  const selectedPractitioner = proposedAppointment
    ? practitioners.find(
        (prac) => prac._id === proposedAppointment.practitionerId
      )
    : {};

  const renderStage = () => {
    let content;
    switch (currentStage) {
      case stages.SELECT_DATE.id:
        content = (
          <SelectDate
            onStageComplete={onStageComplete}
            scheduleType={scheduleType}
            practitionerId={practitionerId}
            setPractitionerId={setPractitionerId}
          />
        );
        break;
      case stages.SELECT_TIME.id:
        content = (
          <SelectTime
            onStageComplete={onStageComplete}
            scheduleType={scheduleType}
            selectedDate={selectedDate}
            onChangeSelectedDate={onChangeSelectedDate}
            practitionerId={practitionerId}
            setPractitionerId={setPractitionerId}
          />
        );
        break;
      case stages.CONFIRM_REBOOK.id:
        console.log(proposedAppointment);
        content = (
          <AlertConfirm
            title="Confirm your new appointment"
            label={`Your appointment is due to take place on ${dayjs(
              proposedAppointment.patientStartAt
            ).format('LLLL')} with ${
              selectedPractitioner
                ? `${selectedPractitioner.title} ${selectedPractitioner.firstName} ${selectedPractitioner.lastName}`
                : ''
            }`}
            onNo={() => {
              // setShowAlertConfirm(false);
              // Go back to the appointment list
              history.push('/');
            }}
            onYes={onConfirmMoveAppointment}
            yesLabel="Yes - confirm"
            noLabel="Cancel"
          />
        );
        break;
      default:
        throw new Error(`stage not handled: ${currentStage}`);
    }

    return content;
  };

  const onCloseAlertInfo = () => {
    setApiError(null);
  };

  return (
    <>
      <PageHeader showBack onClickBack={onClickStageGoBack} />
      {apiError && (
        <AlertInfo
          title="Cannot book appointment"
          label={apiError}
          onClose={onCloseAlertInfo}
        />
      )}
      <div className="book">{renderStage()}</div>
      <NavBar desktopOnly />
    </>
  );
};

export default Rebook;
