import PropTypes from 'prop-types';
import ShareWithPatientOwnGpContent from '../../../content/ShareWithPatientOwnGp';
import Modal from './Modal';

const ShareWithPatientOwnGp = (props) => {
  const { onClickAccept, onClickClose } = props;

  return (
    <Modal
      title="Sharing with your GP"
      acceptButtonLabel="Accept"
      onClickClose={onClickClose}
      onClickAccept={onClickAccept}
    >
      <ShareWithPatientOwnGpContent />
    </Modal>
  );
};

ShareWithPatientOwnGp.propTypes = {
  onClickAccept: PropTypes.func.isRequired,
  onClickClose: PropTypes.func.isRequired,
};

export default ShareWithPatientOwnGp;
