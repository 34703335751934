import './Appointment.scss';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import TextBody from '../elements/TextBody';
import { selectAllPractitioners } from '../../slices/practitionersSlice';

dayjs.extend(advancedFormat);

const Appointment = (props) => {
  const { appointment } = props;
  const history = useHistory();
  const practitioners = useSelector(selectAllPractitioners);

  const practitioner = practitioners.find(
    (el) => el._id === appointment.practitionerId
  );
  if (!practitioner) {
    return <div>Cannot load appointment</div>;
  }

  const onClickAppointment = () => {
    history.push(`/appointments/${appointment._id}`);
  };

  const startAt = dayjs(appointment.startAt);
  const endAt = dayjs(appointment.endAt);

  const practitionerFullname = [
    practitioner.title,
    practitioner.firstName,
    practitioner.lastName,
  ].join(' ');

  return (
    <div className="home-appointment" onClick={onClickAppointment}>
      <div className="home-appointment__info">
        <img
          className="home-appointment__avatar"
          src={`/images/practitioners/${practitioner.profilePictureUrl}`}
          alt={`${practitionerFullname}`}
        />
        <div className="home-appointment__details">
          <TextBody className="home-appointment__date">
            {startAt.format('dddd Do MMMM')}
          </TextBody>
          <TextBody className="home-appointment__time">
            {startAt.format('h:mm A')} - {endAt.format('h:mm A')}
          </TextBody>
          <TextBody className="home-appointment__practitioner">
            {practitionerFullname}
          </TextBody>
        </div>
      </div>
      <div className="home-appointment__actions">
        <div className="home-appointment__action">View Appointment</div>
      </div>
    </div>
  );
};

Appointment.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  appointment: PropTypes.object.isRequired,
};

export default Appointment;
