import './FormModal.scss';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import CloseIcon from '@material-ui/icons/Close';
import Button from './Button';
import TextTitle from './TextTitle';
import TextInput from './form/TextInput';

const FormModal = (props) => {
  const { onFormSubmitted, onClose, label, data } = props;

  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      input: data.value,
    },
  });

  const onSubmitForm = (formData) => {
    const { input } = formData;

    onFormSubmitted({ ...data, value: input });
  };

  const stateLabel = data.isNew ? 'Add' : 'Edit';

  return (
    <div className="form-modal">
      <div className="form-modal__content">
        <div className="form-modal__header">
          <TextTitle type="sub" className="form-modal__label">
            {stateLabel} {label}
          </TextTitle>
          <CloseIcon className="form-modal__close" onClick={onClose} />
        </div>

        <form onSubmit={handleSubmit(onSubmitForm)}>
          <TextInput
            label={label}
            type="text"
            id="form-modal-input"
            name="input"
            error={errors.input}
            registerRef={register({
              required: 'This field is required.',
              maxLength: {
                value: 200,
                message: 'Too many characters (max 200).',
              },
            })}
            autoFocus
          />

          <div className="form-modal__actions">
            <Button type="submit" className="form-modal__action">
              {stateLabel} {label}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

FormModal.propTypes = {
  onFormSubmitted: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
};

export default FormModal;
