import './DataPill.scss';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';

const DataPill = (props) => {
  const { id, label, className, onView, onRemove, ...rest } = props;

  const classes = ['data-pill'];

  if (className.length > 0) {
    classes.push(className);
  }

  const onClickView = () => {
    onView(id);
  };

  const onClickRemove = (evt) => {
    evt.stopPropagation();
    onRemove(id);
  };

  return (
    <div
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      className={classes.join(' ')}
      onClick={onClickView}
    >
      <div className="data-pill__content">
        {label}
        <CloseIcon className="data-pill__icon" onClick={onClickRemove} />
      </div>
    </div>
  );
};

DataPill.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  onView: PropTypes.func,
  onRemove: PropTypes.func,
};

DataPill.defaultProps = {
  className: '',
  onView: null,
  onRemove: null,
};

export default DataPill;
