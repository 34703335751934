import './SelectTime.scss';
import { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TextTitle from '../../elements/TextTitle';
import TextBody from '../../elements/TextBody';
import Content from '../../elements/Content';
import {
  selectFetched,
  selectAllSchedules,
  schedulesReceived,
  ScheduleStatuses,
  schedulesLoading,
} from '../../../slices/scheduleSlice';
import LoadingSpinner from '../../elements/LoadingSpinner';
import {
  bookHoldAppointment,
  getScheduleForPatients,
} from '../../../services/api/apiAppointment';
import {
  addBookingHeldAppointment,
  selectBookingHeldAppointment,
  selectPastAppointments,
} from '../../../slices/appointmentsSlice';
import {
  replacePractitioner,
  selectAllPractitioners,
} from '../../../slices/practitionersSlice';
import AlertInfo from '../../elements/alert/Info';
import { trackEvent, TrackEventNames } from '../../../services/tracking';

dayjs.extend(advancedFormat);

const SelectTime = (props) => {
  const {
    onStageComplete,
    onChangeSelectedDate,
    selectedDate,
    practitionerId,
    setPractitionerId,
  } = props;
  const dispatch = useDispatch();
  const schedulesFetched = useSelector(selectFetched);
  const availableSchedules = useSelector(selectAllSchedules);
  const [apiError, setApiError] = useState();
  const bookingHeldAppointment = useSelector(selectBookingHeldAppointment);
  const practitioners = useSelector(selectAllPractitioners);
  const pastAppointments = useSelector(selectPastAppointments);
  const [loading, setLoading] = useState(false);

  const getSchedules = useCallback(async () => {
    setLoading(true);
    dispatch(schedulesLoading());
    try {
      const { schedules } = await getScheduleForPatients(practitionerId);
      dispatch(schedulesReceived(schedules));
    } catch (err) {
      console.log('API: Error fetching schedule', err);
    }
    setLoading(false);
  }, [dispatch, practitionerId]);

  const loadSchedules = useCallback(() => {
    getSchedules();
  }, [getSchedules]);

  useEffect(() => {
    // Load the schedules each time the view is created
    loadSchedules();
  }, [loadSchedules]);

  // Get the available slots for the date currently being show (selectedDate)
  const timeSlotsForSelectedDate = availableSchedules.filter((el) => {
    return dayjs(el.patientStartAt).isSame(selectedDate, 'day');
  });

  const onClickPrevDay = () => {
    const prevDate = selectedDate.subtract(1, 'days');
    onChangeSelectedDate(prevDate);
  };

  const onClickNextDay = () => {
    const nextDate = selectedDate.add(1, 'days');
    onChangeSelectedDate(nextDate);
  };

  const onSelectTime = async (schedule) => {
    setLoading(true);
    try {
      const { appointment, schedules, practitioner } =
        await bookHoldAppointment({
          scheduleId: schedule._id,
          // Copy over any notes the patient has added on a currently held
          // booking - patient might have gone back through booking process
          // to pick a different time
          patientPreAppointmentNotes:
            bookingHeldAppointment &&
            bookingHeldAppointment.patientPreAppointmentNotes,
          patientPreAppointmentQuestions:
            bookingHeldAppointment &&
            bookingHeldAppointment.patientPreAppointmentQuestions,
          patientPreAppointmentReason:
            bookingHeldAppointment &&
            bookingHeldAppointment.patientPreAppointmentReason,
        });
      dispatch(addBookingHeldAppointment(appointment));
      dispatch(schedulesReceived(schedules));
      dispatch(replacePractitioner(practitioner));

      trackEvent(TrackEventNames.BOOK_V2__STEP_2__SELECT_TIME__COMPLETED);

      onStageComplete(schedule);
    } catch (err) {
      trackEvent(TrackEventNames.BOOK_V2__STEP_2__SELECT_TIME__FAILED);

      setApiError(
        'Unable to book the selected time, please try again or select another time'
      );
      // Reload the schedules to remove any that have been updated since page
      // loaded. It could have been that the user tried to book a schedule
      // that is BOOKING_HELD so we need to remove this time slot from the list
      loadSchedules();
    }
    setLoading(false);
  };

  const renderTimeSlots = () => {
    if (timeSlotsForSelectedDate.length === 0) {
      return (
        <>
          <div className="book-appointment-time__none-available">
            No appointment times available
          </div>
          <TextBody>
            Try looking for an appointment on another day using the arrows
            above.
          </TextBody>
        </>
      );
    }

    const timeSlotItems = timeSlotsForSelectedDate.map((el) => {
      const classes = ['book-appointment-time__slot'];

      // Allow patient to select AVAILABLE and BOOKING_HELD appointments. If the booking
      // held hasn't expired yet, the server will reject it and a message will be shown to the
      // patient.
      const isAvailable =
        el.status === ScheduleStatuses.AVAILABLE ||
        el.status === ScheduleStatuses.BOOKING_HELD;

      if (!isAvailable) {
        classes.push('book-appointment-time__slot--unavailable');
      }

      const blockScopedSchedule = el;
      return (
        <button
          key={el._id}
          className={classes.join(' ')}
          onClick={isAvailable ? () => onSelectTime(blockScopedSchedule) : null}
          type="button"
        >
          {dayjs(el.patientStartAt).format('h:mm A')}
        </button>
      );
    });

    return (
      <>
        <TextBody>
          Please prepare to enter the virtual waiting room at least 15 minutes
          before your appointment.
        </TextBody>

        {timeSlotItems}
      </>
    );
  };

  const onCloseAlertInfo = () => {
    setApiError(null);
  };

  const today = dayjs();
  const showPrevDayButton = !selectedDate.isBefore(today);
  const isSameYear = selectedDate.isSame(dayjs(), 'year');
  const dateFormat = isSameYear ? 'dddd Do MMMM' : 'dddd Do MMM YYYY';

  if (!schedulesFetched) {
    return <LoadingSpinner />;
  }

  const onChangePractitionerDropdown = (evt) => {
    const selectedPractitionerId = evt.target.value;
    setPractitionerId(selectedPractitionerId);
  };

  const getPreviouslySeen = (pracId) =>
    pastAppointments.some((past) => past.practitionerId === pracId)
      ? ' - previously seen'
      : null;

  const renderPractitionerDropdown = () => {
    const options = [
      ...practitioners.map((practitioner) => (
        <option key={practitioner._id} value={practitioner._id}>
          {practitioner.title} {practitioner.firstName} {practitioner.lastName}
          {getPreviouslySeen(practitioner._id)}
        </option>
      )),
    ];
    return (
      <select
        className="book-appointment-time__select form-dob__select form-dob__month"
        name="practitioner"
        onChange={onChangePractitionerDropdown}
        value={practitionerId || undefined}
      >
        <option value="">
          All Menopause GPs - I don&apos;t mind who I see
        </option>
        {options}
      </select>
    );
  };

  return (
    <div className="book-appointment-time">
      <Content>
        {apiError && (
          <AlertInfo
            title="Cannot book appointment time"
            label={apiError}
            onClose={onCloseAlertInfo}
          />
        )}

        <TextTitle type="sub">Select GP preference:</TextTitle>

        {renderPractitionerDropdown()}

        <div className="book-appointment-time-date">
          {showPrevDayButton && (
            <ChevronLeftIcon
              className="book-appointment-time-date__nav"
              onClick={onClickPrevDay}
            />
          )}
          <div className="book-appointment-time-date__selected">
            {selectedDate.format(dateFormat)}
          </div>
          <ChevronRightIcon
            className="book-appointment-time-date__nav"
            onClick={onClickNextDay}
          />
        </div>

        <TextTitle type="sub" className="book-appointment-time__what-time">
          What time would you like your appointment?
        </TextTitle>
      </Content>

      <Content>{loading ? <LoadingSpinner /> : renderTimeSlots()}</Content>
    </div>
  );
};

SelectTime.propTypes = {
  onStageComplete: PropTypes.func.isRequired,
  onChangeSelectedDate: PropTypes.func.isRequired,
  selectedDate: PropTypes.instanceOf(dayjs),
  practitionerId: PropTypes.string,
  setPractitionerId: PropTypes.func.isRequired,
};

SelectTime.defaultProps = {
  selectedDate: dayjs().startOf('day'),
  practitionerId: null,
};

export default SelectTime;
