import './Confirm.scss';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import Button from '../Button';
import TextTitle from '../TextTitle';
import TextBody from '../TextBody';

const Confirm = (props) => {
  const { onNo, onYes, title, label, yesLabel, noLabel } = props;

  return (
    <div className="alert-confirm" onClick={onNo}>
      <div className="alert-confirm__content">
        <div className="alert-confirm__header">
          <TextTitle type="sub" className="alert-confirm__label">
            {title}
          </TextTitle>
          <CloseIcon className="alert-confirm__close" onClick={onNo} />
        </div>
        <TextBody>{label}</TextBody>

        <div className="alert-confirm__actions">
          {onNo && (
            <Button
              className="alert-confirm__action"
              onClick={onNo}
              color="secondary"
            >
              {noLabel || 'Cancel'}
            </Button>
          )}
          {onYes && (
            <Button className="alert-confirm__action" onClick={onYes}>
              {yesLabel || 'Confirm'}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

Confirm.propTypes = {
  onNo: PropTypes.func,
  onYes: PropTypes.func,
  title: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  yesLabel: PropTypes.string,
  noLabel: PropTypes.string,
};

Confirm.defaultProps = {
  yesLabel: 'Confirm',
  noLabel: 'Cancel',
  onYes: null,
  onNo: null,
};

export default Confirm;
