import './PayType.scss';
import { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import TextTitle from '../../elements/TextTitle';
import TextBody from '../../elements/TextBody';
import Button from '../../elements/Button';
import Content from '../../elements/Content';
import { selectAllPractitioners } from '../../../slices/practitionersSlice';
import LoadingSpinner from '../../elements/LoadingSpinner';
import AlertError from '../../elements/alert/Error';
import { trackEvent, TrackEventNames } from '../../../services/tracking';
import { bookSetAppointmentPaymentSessionCreate } from '../../../services/api/apiAppointment';
import logoVisa from './img/payment/visa.png';
import logoMastercard from './img/payment/mastercard.png';
import logoAmericanExpress from './img/payment/american-express.png';
import logoGooglePay from './img/payment/google-pay.svg';
import logoApplePay from './img/payment/apple-pay.svg';
import logoKlarna from './img/payment/klarna.svg';

const PayType = (props) => {
  const { selectedDate, appointment, onStageComplete } = props;
  const practitioners = useSelector(selectAllPractitioners);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [paymentTypeStepTwo, setPaymentTypeStepTwo] = useState(false);
  const [apiError, setApiError] = useState();

  const practitioner = practitioners.find(
    (el) => el._id === appointment.practitionerId
  );
  if (!practitioner) {
    return <div>Cannot load appointment</div>;
  }

  const onCloseAlertError = () => {
    setApiError(null);
  };

  const startAt = dayjs(appointment.startAt);

  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLIC_KEY ||
      'pk_test_51HlWWyBSGCke0qJMb89ptm6Dic50PXpeWldRaj7DJTH7E7He6I1hPJvnMLW7eCP1WSz72BD3oAjx0pQEACPebopm00fl1ahZ3U'
  );

  const stripePostRequest = async (data) => {
    setRequestInProgress(true);

    try {
      const response = await bookSetAppointmentPaymentSessionCreate(data);
      const { id } = response;
      const stripe = await stripePromise;

      stripe.redirectToCheckout({ sessionId: id });
    } catch (err) {
      // console.log(err);
      setApiError(
        'There was an error showing the checkout page, please try again or go back and select another time. If the problem continues please email clinic@healthandher.com.'
      );

      window.scrollTo(0, 0);
      setRequestInProgress(false);
    }
  };

  const onClickPayStripe = async () => {
    trackEvent(TrackEventNames.BOOK_V2__STEP_13__CONFIRM__COMPLETED);

    stripePostRequest({ appointmentId: appointment._id });
  };

  return (
    <div className="book-appointment-confirm">
      <Content>
        {apiError && (
          <AlertError label={apiError} onClose={onCloseAlertError} />
        )}
        <TextTitle type="sub">
          Your appointment: {startAt.format('h:mm A')} on{' '}
          {selectedDate.format('dddd Do MMMM YYYY')}{' '}
        </TextTitle>
        <TextBody>Please select your payment method</TextBody>
        {requestInProgress === true ? (
          <LoadingSpinner />
        ) : (
          <>
            {paymentTypeStepTwo ? (
              <div className="book-appointment-confirm__flexcol">
                <div>
                  <Button
                    color="primary"
                    className="book-appointment-confirm__button"
                    onClick={() => onStageComplete({ type: 'BY_PREPAID' })}
                  >
                    Pre-Paid Voucher / Card
                  </Button>
                </div>
                <div>
                  <Button
                    color="primary"
                    className="book-appointment-confirm__button"
                    onClick={() => onStageComplete({ type: 'BY_PREPAID' })}
                  >
                    Employee Voucher Code
                  </Button>
                </div>
                <div>
                  <Button
                    color="primary"
                    className="book-appointment-confirm__button"
                    onClick={onClickPayStripe}
                  >
                    Discount Code
                  </Button>
                </div>
              </div>
            ) : (
              <div className="book-appointment-confirm__flexcol">
                <div>
                  <Button
                    color="primary"
                    className="book-appointment-confirm__button"
                    onClick={onClickPayStripe}
                  >
                    Pay with Credit / Debit Card
                  </Button>
                  <div className="book-appointment-confirm__logos">
                    <img src={logoVisa} alt="Visa" />
                    <img src={logoMastercard} alt="Mastercard" />
                    <img src={logoAmericanExpress} alt="American Express" />
                    <img src={logoGooglePay} alt="Google Pay" />
                    <img src={logoApplePay} alt="Apple Pay" />
                  </div>
                  <div className="book-appointment-confirm__long-logo-and-label">
                    <img src={logoKlarna} alt="Klarna" />
                    <TextBody className="text-body--v-small">
                      Pay in 3 interest-free payments.
                    </TextBody>
                  </div>
                </div>
                <div>
                  <Button
                    color="primary"
                    className="book-appointment-confirm__button"
                    onClick={() => setPaymentTypeStepTwo(true)}
                  >
                    Use Pre-Paid Voucher / Card
                  </Button>
                </div>
              </div>
            )}
          </>
        )}
      </Content>
    </div>
  );
};

PayType.propTypes = {
  appointment: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    practitionerId: PropTypes.string.isRequired,
    startAt: PropTypes.instanceOf(dayjs).isRequired,
    endAt: PropTypes.instanceOf(dayjs).isRequired,
  }).isRequired,
  selectedDate: PropTypes.instanceOf(dayjs).isRequired,
  onStageComplete: PropTypes.func.isRequired,
};

export default PayType;
