import PropTypes from 'prop-types';
import PrivacyContent from '../../../content/EmailContact';
import Modal from './Modal';

const EmailContact = (props) => {
  const { onClickAccept, onClickClose } = props;

  return (
    <Modal
      title="Contacting you by email"
      acceptButtonLabel="Accept"
      onClickClose={onClickClose}
      onClickAccept={onClickAccept}
    >
      <PrivacyContent />
    </Modal>
  );
};

EmailContact.propTypes = {
  onClickAccept: PropTypes.func.isRequired,
  onClickClose: PropTypes.func.isRequired,
};

export default EmailContact;
