import './Radio.scss';
import PropTypes from 'prop-types';
import FormFieldError from './FieldError';

const Radio = (props) => {
  const {
    name,
    values,
    registerRef,
    className,
    error,
    onChange,
    stackVertically,
    threeColumn,
  } = props;

  const classes = ['form-radio'];

  let errorLabel = '';
  if (Object.keys(error).length > 0) {
    classes.push('form-radio--error');

    if (error.message && error.message.length > 0) {
      errorLabel = error.message;
    } else {
      errorLabel = 'Invalid input';
    }
  }

  if (stackVertically) {
    classes.push('form-radio--vertically');
  }

  if (threeColumn) {
    classes.push('form-radio--three-column');
  }

  if (className.length > 0) {
    classes.push(className);
  }

  const renderInputs = () => {
    return values.map((el) => {
      const { label, value, inputClassName = '' } = el;
      const inputId = `${name}-${value}`;
      return (
        <label
          className={`form-radio__label ${inputClassName} `}
          htmlFor={inputId}
          key={inputId}
        >
          <input
            className="form-radio__radio"
            type="radio"
            id={inputId}
            name={name}
            value={value}
            ref={registerRef}
            onChange={onChange}
          />
          {label}
        </label>
      );
    });
  };

  return (
    <div className={classes.join(' ')}>
      <div className="form-radio__inputs">{renderInputs()}</div>
      {error && errorLabel.length > 0 ? (
        <FormFieldError label={errorLabel} />
      ) : null}
    </div>
  );
};

Radio.propTypes = {
  name: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  registerRef: PropTypes.func.isRequired,
  className: PropTypes.string,
  onChange: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  error: PropTypes.object,
  stackVertically: PropTypes.bool,
  threeColumn: PropTypes.bool,
};

Radio.defaultProps = {
  error: {},
  onChange: null,
  className: '',
  stackVertically: false,
};

export default Radio;
