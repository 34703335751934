import './TextBody.scss';
import PropTypes from 'prop-types';

const TextBody = (props) => {
  const {
    type,
    centerText,
    emphasis,
    small,
    vSmall,
    className,
    children,
    ...rest
  } = props;

  const classes = ['text-body'];
  classes.push(`text-body--${type}`);

  if (centerText) {
    classes.push('text-body--center');
  }

  if (emphasis) {
    classes.push('text-body--emphasis');
  }

  if (small) {
    classes.push('text-body--small');
  }
  if (vSmall) {
    classes.push('text-body--v-small');
  }

  if (className.length > 0) {
    classes.push(className);
  }

  return (
    <div
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      className={classes.join(' ')}
    >
      {children}
    </div>
  );
};

TextBody.propTypes = {
  type: PropTypes.oneOf(['default']),
  centerText: PropTypes.bool,
  className: PropTypes.string,
  emphasis: PropTypes.bool,
  small: PropTypes.bool,
  vSmall: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

TextBody.defaultProps = {
  type: 'default',
  centerText: false,
  className: '',
  emphasis: false,
  small: false,
  vSmall: false,
};

export default TextBody;
