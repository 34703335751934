import './Medical.scss';
import { useState } from 'react';
import NavBar from '../navigation/NavBar';
import PageHeader from '../elements/PageHeader';
import TextTitle from '../elements/TextTitle';
import Content from '../elements/Content';
import MenuList from '../elements/menu/List';
import MenuListItem from '../elements/menu/ListItem';
import History from './about-you/History';
import Medication from './about-you/Medication';
import Contraception from './about-you/Contraception';
import Hrt from './about-you/Hrt';
import Gp from './about-gp/Gp';

const editStage = {
  HISTORY: 'HISTORY',
  MEDICATION: 'MEDICATION',
  CONTRACEPTION: 'CONTRACEPTION',
  HRT: 'HRT',
  GP: 'GP',
};

const Medical = () => {
  const [currentEditStage, setCurrentEditStage] = useState(null);
  const [formHistory, setFormHistory] = useState(null);
  const [formMedication, setFormMedication] = useState(null);
  const [formContraception, setFormContraception] = useState(null);
  const [formHrt, setFormHrt] = useState(null);
  const [formGp, setFormGp] = useState(null);

  const onClickEditHistory = () => {
    setCurrentEditStage(editStage.HISTORY);
  };

  const onClickEditMedication = () => {
    setCurrentEditStage(editStage.MEDICATION);
  };

  const onClickEditContraception = () => {
    setCurrentEditStage(editStage.CONTRACEPTION);
  };

  const onClickEditHrt = () => {
    setCurrentEditStage(editStage.HRT);
  };

  const onClickEditGp = () => {
    setCurrentEditStage(editStage.GP);
  };

  const onEditStageComplete = (stageData = null) => {
    switch (currentEditStage) {
      case editStage.HISTORY:
        setFormHistory(stageData);
        break;
      case editStage.MEDICATION:
        setFormMedication(stageData);
        break;
      case editStage.CONTRACEPTION:
        setFormContraception(stageData);
        break;
      case editStage.HRT:
        setFormHrt(stageData);
        break;
      case editStage.GP:
        setFormGp(stageData);
        break;
      default:
        throw new Error(`stage not handled: ${currentEditStage}`);
    }

    setCurrentEditStage(null);
  };

  const onEditStageClose = () => {
    setCurrentEditStage(null);
  };

  const renderEditStage = () => {
    let content;
    switch (currentEditStage) {
      case editStage.HISTORY:
        content = (
          <History
            onStageComplete={onEditStageComplete}
            onClickBack={onEditStageClose}
            formData={formHistory}
          />
        );
        break;
      case editStage.MEDICATION:
        content = (
          <Medication
            onStageComplete={onEditStageComplete}
            onClickBack={onEditStageClose}
            formData={formMedication}
          />
        );
        break;
      case editStage.CONTRACEPTION:
        content = (
          <Contraception
            onStageComplete={onEditStageComplete}
            onClickBack={onEditStageClose}
            formData={formContraception}
          />
        );
        break;
      case editStage.HRT:
        content = (
          <Hrt
            onStageComplete={onEditStageComplete}
            onClickBack={onEditStageClose}
            formData={formHrt}
          />
        );
        break;
      case editStage.GP:
        content = (
          <Gp
            onStageComplete={onEditStageComplete}
            onClickBack={onEditStageClose}
            formData={formGp}
          />
        );
        break;
      default:
        throw new Error(`stage not handled: ${currentEditStage}`);
    }

    window.scrollTo(0, 0);
    return content;
  };

  const renderMainContent = () => {
    return (
      <>
        <PageHeader title="Medical" />
        <div className="medical">
          <Content>
            <TextTitle type="sub">Clinical Records</TextTitle>

            <MenuList>
              <MenuListItem
                label="Medical History"
                onClick={onClickEditHistory}
              />
              <MenuListItem
                label="Medication"
                onClick={onClickEditMedication}
              />
              <MenuListItem
                label="Contraception"
                onClick={onClickEditContraception}
              />
              <MenuListItem
                label="Hormone Replacement Therapy"
                onClick={onClickEditHrt}
              />
            </MenuList>

            <TextTitle type="sub" className="medical__title">
              GP Details
            </TextTitle>

            <MenuList>
              <MenuListItem
                label="GP Name and Address"
                onClick={onClickEditGp}
              />
            </MenuList>
          </Content>
        </div>
      </>
    );
  };

  const content =
    currentEditStage === null ? renderMainContent() : renderEditStage();

  return (
    <>
      {content}
      <NavBar active="MEDICAL" />
    </>
  );
};

export default Medical;
