import PropTypes from 'prop-types';
import PrivacyContent from '../../../content/Privacy';
import Modal from './Modal';

const Privacy = (props) => {
  const { onClickAccept, onClickClose } = props;

  return (
    <Modal
      title="Privacy Policy"
      acceptButtonLabel="Accept"
      onClickClose={onClickClose}
      onClickAccept={onClickAccept}
    >
      <PrivacyContent />
    </Modal>
  );
};

Privacy.propTypes = {
  onClickAccept: PropTypes.func.isRequired,
  onClickClose: PropTypes.func.isRequired,
};

export default Privacy;
