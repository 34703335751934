import './Hrt.scss';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import TextBody from '../../elements/TextBody';
import Content from '../../elements/Content';
import PageHeader from '../../elements/PageHeader';
import Button from '../../elements/Button';
import Radio from '../../elements/form/Radio';
import Checkbox from '../../elements/form/Checkbox';
import TextInput from '../../elements/form/TextInput';
import LoadingSpinner from '../../elements/LoadingSpinner';
import {
  selectAllItems as hrtSelectAllItems,
  selectAllCategories as hrtSelectAllCategories,
} from '../../../slices/hrtsSlice';
import {
  selectPatient,
  selectHrtIds,
  selectPreviousHrtIds,
  replacePatient,
  PatientTakingHrt,
} from '../../../slices/patientSlice';
import { patch } from '../../../services/api/apiPatient';
import AlertError from '../../elements/alert/Error';
import FieldError from '../../elements/form/FieldError';
import TextTitle from '../../elements/TextTitle';

const Hrt = (props) => {
  const { onClickBack, showQuestion } = props;
  const patient = useSelector(selectPatient);
  const hrtCategories = useSelector(hrtSelectAllCategories);
  const hrtItems = useSelector(hrtSelectAllItems);
  const hrtIds = useSelector(selectHrtIds);
  const previousHrtIds = useSelector(selectPreviousHrtIds);
  const { register, handleSubmit, watch, errors } = useForm({
    defaultValues: {
      takingHrt: patient.takingHrt,
      hrt: hrtIds.reduce((acc, cur) => {
        acc[cur] = true;
        return acc;
      }, {}),
      takingHrtOther: patient.takingHrtOther,
      previousTakingHrt: patient.previousTakingHrt,
      previousHrt: previousHrtIds.reduce((acc, cur) => {
        acc[cur] = true;
        return acc;
      }, {}),
      previousHrtOther: patient.previousHrtOther,
    },
  });
  const [showHrtNoItemsAddedError, setHrtShowNoItemsAddedError] =
    useState(false);
  const [
    showPreviousHrtNoItemsAddedError,
    setPreviousHrtShowNoItemsAddedError,
  ] = useState(false);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [apiError, setApiError] = useState();
  const dispatch = useDispatch();

  const takingHrt = watch('takingHrt');
  const previousTakingHrt = watch('previousTakingHrt');

  const patchRequest = async (data) => {
    setRequestInProgress(true);

    try {
      const response = await patch(data);
      dispatch(replacePatient(response.patient));
      onClickBack();
    } catch (err) {
      setApiError(
        'There was an error updating your medical details. Please try again.'
      );

      window.scrollTo(0, 0);
      setRequestInProgress(false);
    }
  };

  const onSubmitForm = (data) => {
    setHrtShowNoItemsAddedError(false);
    setPreviousHrtShowNoItemsAddedError(false);

    if (requestInProgress) {
      return;
    }

    let defaultUsingHrt;
    if (!showQuestion) {
      defaultUsingHrt = PatientTakingHrt.YES;
    } else {
      defaultUsingHrt = data.takingHrt;
    }

    let scrollToY = null;

    const { hrt, takingHrtOther } = data;
    let hrts = [];
    if (defaultUsingHrt === PatientTakingHrt.YES) {
      hrts = Object.keys(hrt).filter((el) => {
        return hrt[el] === true;
      });
      if (hrts.length === 0 && takingHrtOther.length === 0) {
        setHrtShowNoItemsAddedError(true);
        scrollToY = 0;
      }
    }

    let defaultPreviousUsingHrt;
    if (!showQuestion) {
      defaultPreviousUsingHrt = PatientTakingHrt.YES;
    } else {
      defaultPreviousUsingHrt = data.previousTakingHrt;
    }

    const { previousHrt, previousHrtOther } = data;
    let previousHrts = [];
    if (defaultPreviousUsingHrt === PatientTakingHrt.YES) {
      previousHrts = Object.keys(previousHrt).filter((el) => {
        return previousHrt[el] === true;
      });
      if (previousHrts.length === 0 && previousHrtOther.length === 0) {
        setPreviousHrtShowNoItemsAddedError(true);

        if (scrollToY === null) {
          // Scroll the question into view
          const hrtPreviousError = document.querySelector(
            '.medical-hrt__question-previous'
          );
          const hrtPreviousErrorRect = hrtPreviousError.getBoundingClientRect();

          // 120 to account for navbar / header
          scrollToY =
            hrtPreviousErrorRect.top + document.documentElement.scrollTop - 120;
        }
      }
    }

    // Check for scrollToY to scroll to error location and return early
    // before patching
    if (scrollToY !== null) {
      window.scrollTo(0, scrollToY);
      return;
    }

    patchRequest({
      attributesToUpdate: {
        takingHrt: defaultUsingHrt,
        takingHrtDetails: hrts,
        takingHrtOther:
          defaultUsingHrt === PatientTakingHrt.YES ? takingHrtOther : '',
        previousTakingHrt: defaultPreviousUsingHrt,
        previousHrtDetails: previousHrts,
        previousHrtOther:
          defaultPreviousUsingHrt === PatientTakingHrt.YES
            ? previousHrtOther
            : '',
      },
    });
  };

  const renderHrtCategory = (category, attributeName = 'hrt') => {
    const hrtCategoryList = hrtItems
      .filter((el) => el.category === category.key)
      .map((el) => {
        return (
          <Checkbox
            key={el._id}
            label={el.name}
            id={`${attributeName}-${el._id}`}
            name={`${attributeName}[${el._id}]`}
            registerRef={register()}
          />
        );
      });

    return (
      <>
        <TextBody emphasis>{category.name}</TextBody>

        {hrtCategoryList}
      </>
    );
  };

  const renderCurrentHrtQuestion = () => {
    return (
      <>
        <TextBody>Are you currently taking HRT?</TextBody>
        <Radio
          name="takingHrt"
          values={[
            { label: 'Yes', value: PatientTakingHrt.YES },
            { label: 'No', value: PatientTakingHrt.NO },
          ]}
          error={errors.takingHrt}
          registerRef={register({ required: 'This field is required.' })}
        />
      </>
    );
  };

  const renderPreviousHrtQuestion = () => {
    return (
      <>
        <TextBody className="medical-hrt__question-previous">
          Have you previously tried any other types of HRT?
        </TextBody>
        <Radio
          name="previousTakingHrt"
          values={[
            { label: 'Yes', value: PatientTakingHrt.YES },
            { label: 'No', value: PatientTakingHrt.NO },
          ]}
          error={errors.previousTakingHrt}
          registerRef={register({ required: 'This field is required.' })}
        />
      </>
    );
  };

  const renderCurrentHrts = () => {
    const hrtCategoryList = hrtCategories.map((el) => {
      return <div key={el.key}>{renderHrtCategory(el)}</div>;
    });

    return (
      <>
        <TextBody>Which of the following HRT are you taking?</TextBody>
        {showHrtNoItemsAddedError && (
          <FieldError
            label="You must specify at least one HRT."
            className="medical-hrt__field-error"
          />
        )}

        {hrtCategoryList}

        <TextInput
          label="Other"
          type="text"
          name="takingHrtOther"
          error={errors.takingHrtOther}
          registerRef={register({
            maxLength: {
              value: 500,
              message: 'Too many characters (max 200).',
            },
          })}
        />
      </>
    );
  };

  const renderPreviousHrts = () => {
    const hrtCategoryList = hrtCategories.map((el) => {
      return <div key={el.key}>{renderHrtCategory(el, 'previousHrt')}</div>;
    });

    return (
      <>
        <TextBody>Please select all that apply:</TextBody>
        {showPreviousHrtNoItemsAddedError && (
          <FieldError
            label="You must specify at least one HRT."
            className="medical-hrt__field-error"
          />
        )}

        {hrtCategoryList}

        <TextInput
          label="Other"
          type="text"
          name="previousHrtOther"
          error={errors.previousHrtOther}
          registerRef={register({
            maxLength: {
              value: 500,
              message: 'Too many characters (max 200).',
            },
          })}
        />
      </>
    );
  };

  const onCloseAlertError = () => {
    setApiError(null);
  };

  return (
    <>
      <PageHeader showBack onClickBack={onClickBack} />
      <div className="medical-hrt">
        <Content>
          {apiError && (
            <AlertError label={apiError} onClose={onCloseAlertError} />
          )}

          <TextTitle type="sub">HRT</TextTitle>

          <form onSubmit={handleSubmit(onSubmitForm)}>
            {showQuestion && renderCurrentHrtQuestion()}

            {takingHrt === PatientTakingHrt.YES && renderCurrentHrts()}

            {showQuestion && renderPreviousHrtQuestion()}

            {previousTakingHrt === PatientTakingHrt.YES && renderPreviousHrts()}

            {requestInProgress === true ? (
              <LoadingSpinner />
            ) : (
              <Button type="submit">Save</Button>
            )}
          </form>
        </Content>
      </div>
    </>
  );
};

Hrt.propTypes = {
  onClickBack: PropTypes.func.isRequired,
  showQuestion: PropTypes.bool,
};

Hrt.defaultProps = {
  showQuestion: true,
};

export default Hrt;
