import { baseApiUrl, jsonPost } from './api';

export const login = (email, password) => {
  const url = `${baseApiUrl}/login`;
  return jsonPost(url, { email, password });
};

export const register = (registerData) => {
  const url = `${baseApiUrl}/register`;
  return jsonPost(url, registerData);
};

export const registerConfirmEmail = (email, login2FactorCode) => {
  const url = `${baseApiUrl}/register/confirm-email`;
  return jsonPost(url, { email, login2FactorCode });
};

export const logout = () => {
  const url = `${baseApiUrl}/logout`;
  return jsonPost(url);
};

export const forgottenPasswordInit = (email) => {
  const url = `${baseApiUrl}/forgotten-password/init`;
  return jsonPost(url, { email });
};

export const forgottenPasswordChange = (
  email,
  passwordResetCode,
  newPassword
) => {
  const url = `${baseApiUrl}/forgotten-password/change`;
  return jsonPost(url, { email, passwordResetCode, newPassword });
};

export const changePasswordNotify = (email) => {
  const url = `${baseApiUrl}/changepassword`;
  return jsonPost(url, { email });
};
