import PropTypes from 'prop-types';
import Checkbox from '../elements/form/Checkbox';
import TextBody from '../elements/TextBody';

const MedicalHistoryOptions = ({
  medicalHistories,
  register,
  type = 'personal',
}) => {
  const medicalHistoriesList = medicalHistories
    .filter(
      (medicalHistory) => !medicalHistory.type || medicalHistory.type === type
    )
    .map((el) => {
      return (
        <Checkbox
          key={el._id}
          label={el.label ? el.label : el.name}
          id={`book-medical-history-${el._id}`}
          name={`medicalHistory[${el._id}]`}
          registerRef={register()}
        />
      );
    });
  if (type === 'personal') {
    medicalHistoriesList.push(
      <Checkbox
        key="none"
        label="None of the above"
        id="book-medical-history-none"
        name="medicalHistory[NONE]"
        registerRef={register()}
        className="book-medical-history__none-option"
      />
    );
  }
  const showBcraNote = () => {
    const bcraItem = medicalHistories.filter(
      (medicalHistory) =>
        medicalHistory._id && medicalHistory._id === '627e03f030e6a601d6ff01dc'
    );
    return bcraItem.length ? (
      <TextBody>
        *BRCA is an abbreviation for breast cancer gene. BRCA1 and BRCA2 are two
        two genes that everyone has. Sometimes the structure inside a gene is
        permanently changed. This change is called a gene mutation. The BRCA1
        and BRCA2 genes normally protect us from breast and ovarian cancer. A
        mutation in one of these genes means that protection is lost. Over time,
        time, this may mean cancers are more likely to develop.
      </TextBody>
    ) : null;
  };
  return (
    <>
      {medicalHistoriesList}
      {type === 'family' && showBcraNote()}
    </>
  );
};

MedicalHistoryOptions.propTypes = {
  medicalHistories: PropTypes.array.isRequired,
  register: PropTypes.func.isRequired,
  type: PropTypes.string,
};

export default MedicalHistoryOptions;
