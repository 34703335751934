import './PostAppointment.scss';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import DOMPurify from 'dompurify';
import linkifyHtml from 'linkifyjs/html';
import { convertFromRaw } from 'draft-js';
import { convertToHTML } from 'draft-convert';
import { useState, useEffect } from 'react';
import { LoadingSpinner } from '@hh/clinic-app-common';
import TextBody from '../../elements/TextBody';
import Button from '../../elements/Button';
import TextTitle from '../../elements/TextTitle';
import {
  getGpLetterByAccountId,
  AppointmentStatuses,
} from '../../../slices/appointmentsSlice';
import { selectPatient } from '../../../slices/patientSlice';
import AlertError from '../../elements/alert/Error';

const PostAppointment = (props) => {
  const { appointment, practitioner } = props;
  const patient = useSelector(selectPatient);
  const dispatch = useDispatch();
  const [apiError, setApiError] = useState(false);
  const [loadingGpLetter, setLoadingGpLetter] = useState(false);

  const practitionerFullname = [
    practitioner.title,
    practitioner.firstName,
    practitioner.lastName,
  ].join(' ');

  useEffect(() => {
    if (appointment.gpLetterUrl) window.location = appointment.gpLetterUrl;
  }, [appointment.gpLetterUrl]);

  const openGpLetter = async (appointmentId) => {
    setLoadingGpLetter(true);
    try {
      const letterRes = await dispatch(
        getGpLetterByAccountId({ appointmentId })
      );
      if (letterRes.error) {
        setApiError(
          'There was an error opening the GP letter, please refresh the page and try again.'
        );
      }
    } catch (err) {
      setApiError(
        'There was an error opening the GP letter, please refresh the page and try again.'
      );
    }
    setLoadingGpLetter(false);
  };

  const renderWaitingOnPractitioner = () => {
    return (
      <>
        <TextTitle type="sub">Appointment Finished</TextTitle>

        <TextBody>
          Thank you for attending your appointment with {practitionerFullname}.
          Your notes and a letter for your GP are currently being written up. We
          will notify you when they are available to download from your account.
        </TextBody>

        <TextBody>
          In the meantime, we would love to know how you found the experience of
          using the Health &amp; Her Menopause Clinic. Taking part will take 2
          minutes, and will provide us with invaluable feedback so we can
          continue to improve our service.
        </TextBody>
        <a
          href="https://www.surveymonkey.co.uk/r/Y7CDBW5"
          target="_blank"
          rel="noreferrer noopener"
        >
          <Button color="primary">Give your feedback</Button>
        </a>
      </>
    );
  };

  const renderDoctorNote = (patientNote) => {
    const renderPlainTextPatientNotes = () => {
      const noteSplitToNewlines = patientNote.notes.split('\n').map((el) => (
        <>
          {el}
          <br />
        </>
      ));

      return noteSplitToNewlines;
    };

    const renderRichTextPatientNotes = () => {
      const contentState = convertFromRaw(patientNote.notes);
      const html = convertToHTML(contentState);
      const cleanedHtml = DOMPurify.sanitize(html);
      const linkifiedHtml = linkifyHtml(cleanedHtml, { target: '_blank' });

      return (
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: linkifiedHtml,
          }}
        />
      );
    };

    return (
      <TextBody className="appointment-post-appointment__doctor-notes">
        <p>
          <b>Note Added: </b>
          {dayjs(patientNote.createdAt).format('dddd Do MMMM, h:mma')}
        </p>
        {typeof patientNote.notes === 'string'
          ? renderPlainTextPatientNotes()
          : renderRichTextPatientNotes()}
      </TextBody>
    );
  };

  const onCloseAlertError = () => {
    setApiError(null);
  };

  const renderCompleted = () => {
    const hasGpLetter =
      appointment.gpLetterRequired &&
      appointment.practitionerNotesForGpLetter &&
      Object.keys(appointment.practitionerNotesForGpLetter).length;

    let infoText = 'Your notes';

    if (hasGpLetter) {
      infoText += ' and a letter for your GP';
    }

    infoText += ' are now ready for you to view.';

    return (
      <>
        {apiError && (
          <AlertError label={apiError} onClose={onCloseAlertError} />
        )}
        <TextTitle type="sub">Appointment Finished</TextTitle>
        <TextBody>
          Thank you for attending your appointment with {practitionerFullname}.{' '}
          {infoText}
        </TextBody>
        {hasGpLetter && <TextBody emphasis>Letter for your GP</TextBody>}
        {hasGpLetter && (
          <TextBody>
            Please select the button below to download your appointment notes.
            If you would like to update your own GP with the details from this
            consultation, including any recommendations, you will need to print
            this letter and pass it on to your GP.
          </TextBody>
        )}
        {hasGpLetter &&
          patient.gp &&
          (loadingGpLetter ? (
            <LoadingSpinner />
          ) : (
            <>
              <Button
                color="primary"
                className="appointment-post-appointment__download-letter"
                onClick={() => openGpLetter(appointment._id)}
              >
                Download letter for your GP
              </Button>
              {appointment.gpLetterUrl && (
                <p>
                  If the letter did not download automatically then{' '}
                  <a
                    target="_blank"
                    rel="noreferrer noopener"
                    href={appointment.gpLetterUrl}
                  >
                    click here to download
                  </a>{' '}
                  - this link will expire in 2 minutes
                </p>
              )}
            </>
            // eslint-disable-next-line react/jsx-no-target-blank
          ))}
        {hasGpLetter && !patient.gp ? (
          <>
            <TextBody>
              To download your GP letter, please add information about your GP.
            </TextBody>
            <Link to="/medical">
              <Button color="primary">Add GP Details</Button>
            </Link>
          </>
        ) : null}
        <TextBody
          emphasis
          className="appointment-post-appointment__medical-notes-title"
        >
          Patient Medical Notes
        </TextBody>
        {appointment.practitionerNotesForPatient &&
        Object.keys(appointment.practitionerNotesForPatient).length
          ? appointment.practitionerNotesForPatient.map((doctorNote) =>
              renderDoctorNote(doctorNote)
            )
          : 'There are no notes'}
      </>
    );
  };

  const renderContent = () => {
    if (appointment.status === AppointmentStatuses.WAITING_ON_PRACTITIONER) {
      return renderWaitingOnPractitioner();
    }

    return renderCompleted();
  };

  return <div className="appointment-post-appointment">{renderContent()}</div>;
};

PostAppointment.propTypes = {
  appointment: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    practitionerNotesForPatient: PropTypes.array,
    practitionerNotesForGpLetter: PropTypes.string,
    gpLetterRequired: PropTypes.bool,
    gpLetterUrl: PropTypes.string,
    practitionerId: PropTypes.string,
  }).isRequired,
  practitioner: PropTypes.shape({
    title: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
  }).isRequired,
};

export default PostAppointment;
