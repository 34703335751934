import './InProgress.scss';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import TextTitle from '../../elements/TextTitle';
import TextBody from '../../elements/TextBody';
import Content from '../../elements/Content';
import PageHeader from '../../elements/PageHeader';
import Button from '../../elements/Button';
import { selectAllAppointments } from '../../../slices/appointmentsSlice';
import NavBar from '../../navigation/NavBar';
import AlertError from '../../elements/alert/Error';

const InProgress = () => {
  const appointments = useSelector(selectAllAppointments);
  const { appointmentId } = useParams();
  const history = useHistory();

  const appointment = appointments.find((el) => el._id === appointmentId);

  const onCloseAlertError = () => {
    history.push('/');
  };

  if (!appointment) {
    return (
      <AlertError
        label="Unable to load appointment, the appointment could not be found."
        onClose={onCloseAlertError}
      />
    );
  }

  const onClickAppointmentFinished = async () => {
    history.push('/');
  };

  const attendAppointmentLink = '/attend';

  return (
    <>
      <PageHeader title="Your Appointment" showBack={false} />
      <div className="appointment-in-progress">
        <Content>
          <>
            <TextTitle type="sub">Appointment In Progress</TextTitle>

            <TextBody>
              Your video call should open in a new window. If this has not
              happened, please try again:
              <Button color="primary-link">
                <a
                  href={attendAppointmentLink}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Open appointment in new window
                </a>
              </Button>
            </TextBody>

            <TextTitle type="body">Need help?</TextTitle>
            <TextBody>
              If you have any questions or require support, please email us at{' '}
              <a href="mailto:clinic@healthandher.com">
                clinic@healthandher.com
              </a>
              .
            </TextBody>

            <div className="appointment-in-progress__actions">
              <Button onClick={onClickAppointmentFinished} color="primary-link">
                Return to home
              </Button>
            </div>
          </>
        </Content>
        <NavBar desktopOnly />
      </div>
    </>
  );
};

export default InProgress;
