import './MedicalProcedures.scss';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import TextTitle from '../../../elements/TextTitle';
import TextBody from '../../../elements/TextBody';
import TextInput from '../../../elements/form/TextInput';
import Content from '../../../elements/Content';
import Button from '../../../elements/Button';
import Radio from '../../../elements/form/Radio';
import LoadingSpinner from '../../../elements/LoadingSpinner';
import StepHeader from '../../../elements/StepHeader';
import {
  selectAll as medicalProceduresSelectAll,
  MedicalProcedureGroups,
} from '../../../../slices/medicalProceduresSlice';
import { selectPatient, replacePatient } from '../../../../slices/patientSlice';
import AlertError from '../../../elements/alert/Error';
import { patch } from '../../../../services/api/apiPatient';
import { trackEvent, TrackEventNames } from '../../../../services/tracking';

const MedicalProcedures = (props) => {
  const { onStageComplete } = props;
  const patient = useSelector(selectPatient);
  const medicalProcedures = useSelector(medicalProceduresSelectAll);
  const medicalProceduresDefaults = () => {
    const medicalProceduresObj = {
      ungrouped: {},
    };
    const medicalProcedureGroupsObj = {};
    Object.keys(MedicalProcedureGroups).forEach((key) => {
      medicalProceduresObj[key] = {};
      medicalProcedureGroupsObj[key] = '0';
    });
    medicalProcedures.forEach((obj) => {
      if (obj.enabled !== true) return;
      if (obj.group) {
        medicalProceduresObj[obj.group] = undefined;
      } else {
        medicalProceduresObj.ungrouped[obj._id] = '0';
      }
    });
    if (!patient.medicalProcedures) {
      return {
        medicalProcedureGroup: medicalProcedureGroupsObj,
        medicalProcedures: medicalProceduresObj,
      };
    }
    patient.medicalProcedures.forEach((procedureId) => {
      const procedureMatch =
        medicalProcedures.filter(
          (obj) => obj._id === procedureId && obj.enabled === true
        )[0] || [];
      if (procedureMatch.group && procedureMatch.group !== 'ungrouped') {
        medicalProcedureGroupsObj[procedureMatch.group] = '1';
        medicalProceduresObj[procedureMatch.group] = procedureMatch._id;
      } else {
        medicalProceduresObj.ungrouped[procedureMatch._id] = '1';
      }
    });
    return {
      medicalProcedureGroup: medicalProcedureGroupsObj,
      medicalProcedures: medicalProceduresObj,
    };
  };
  const { register, errors, watch, handleSubmit } = useForm({
    defaultValues: {
      ...medicalProceduresDefaults(),
      medicalProcedureDetails: patient.medicalProcedureDetails,
    },
  });

  const [requestInProgress, setRequestInProgress] = useState(false);
  const [apiError, setApiError] = useState();
  const dispatch = useDispatch();

  const patchRequest = async (data) => {
    setRequestInProgress(true);

    try {
      const response = await patch(data);
      dispatch(replacePatient(response.patient));

      trackEvent(TrackEventNames.BOOK_V2__STEP_11__PROCEDURES__COMPLETED);

      onStageComplete();
    } catch (err) {
      setApiError(
        'There was an error updating your medical details. Please try again.'
      );

      window.scrollTo(0, 0);
      setRequestInProgress(false);
    }
  };

  const onSubmitForm = (data) => {
    if (requestInProgress) {
      return;
    }
    const medicalProceduresArr = [];
    const medicalProcedureDetails = data.medicalProcedureDetails || {};

    if (data.medicalProcedures) {
      Object.keys(data.medicalProcedures).forEach((key) => {
        if (key !== 'ungrouped') {
          medicalProceduresArr.push(data.medicalProcedures[key]);
        } else {
          Object.keys(data.medicalProcedures.ungrouped).forEach(
            (procedureId) =>
              data.medicalProcedures.ungrouped[procedureId] === '1' &&
              medicalProceduresArr.push(procedureId)
          );
        }
      });
    }
    patchRequest({
      attributesToUpdate: {
        medicalProcedures: medicalProceduresArr,
        medicalProcedureDetails,
      },
    });
  };
  const renderMedicalProcedures = () => {
    const groupWatch = {};

    Object.keys(MedicalProcedureGroups).forEach((key) => {
      groupWatch[key] = watch(`medicalProcedureGroup[${key}]`);
    });

    Object.keys(medicalProcedures).forEach((key) => {
      groupWatch[medicalProcedures[key]._id] = watch(
        `medicalProcedures[ungrouped][${medicalProcedures[key]._id}]`
      );
    });

    return (
      <>
        {Object.keys(MedicalProcedureGroups).map((key) => (
          <div key={key}>
            <TextBody className={'text-body--semi-bold'}>
              {MedicalProcedureGroups[key]}
            </TextBody>
            <Radio
              name={`medicalProcedureGroup[${key}]`}
              values={[
                { label: 'Yes', value: '1' },
                { label: 'No', value: '0' },
              ]}
              error={
                errors.medicalProcedureGroup &&
                errors.medicalProcedureGroup[key]
              }
              required
              registerRef={register({ required: 'This field is required.' })}
            />
            {groupWatch[key] === '1' && (
              <div key={`medicalProcedureGrouped-${key}`}>
                <TextBody>Was it:</TextBody>
                <Radio
                  name={`medicalProcedures[${key}]`}
                  values={medicalProcedures
                    .filter((el) => el.group === key)
                    .sort((a, b) =>
                      b.label.toLowerCase() === "don't know" ? -1 : 1
                    )
                    .map((el) => {
                      return {
                        label: el.label,
                        value: el._id,
                      };
                    })}
                  required
                  error={
                    errors.medicalProcedures && errors.medicalProcedures[key]
                  }
                  registerRef={register({
                    required: 'This field is required.',
                  })}
                  stackVertically
                />
              </div>
            )}
          </div>
        ))}
        {Object.keys(medicalProcedures)
          .filter((key) => medicalProcedures[key].group === null)
          .map((key) => (
            <div key={`medicalProcedures-${key}`}>
              <TextBody className={'text-body--semi-bold'}>
                {medicalProcedures[key].label}
              </TextBody>
              <Radio
                name={`medicalProcedures[ungrouped][${medicalProcedures[key]._id}]`}
                values={[
                  {
                    label: 'Yes',
                    value: '1',
                  },
                  {
                    label: 'No',
                    value: '0',
                  },
                ]}
                required
                error={
                  errors.medicalProcedures &&
                  errors.medicalProcedures.ungrouped &&
                  errors.medicalProcedures.ungrouped[medicalProcedures[key]._id]
                }
                registerRef={register({
                  required: 'This field is required.',
                })}
              />
              {medicalProcedures[key].provideDetails &&
                groupWatch[medicalProcedures[key]._id] === '1' && (
                  <TextInput
                    className={'text-body--semi-bold after-radio'}
                    label="Why was this done and when?"
                    type="text"
                    name={`medicalProcedureDetails[${medicalProcedures[key]._id}]`}
                    error={
                      errors.medicalProcedureDetails &&
                      errors.medicalProcedureDetails[medicalProcedures[key]._id]
                    }
                    required
                    registerRef={register({
                      maxLength: {
                        value: 500,
                        message: 'Too many characters (max 200).',
                      },
                      required: 'This field is required.',
                    })}
                  />
                )}
            </div>
          ))}
      </>
    );
  };

  const onCloseAlertError = () => {
    setApiError(null);
  };

  return (
    <div className="book-medical-procedures">
      <Content>
        <StepHeader currentStep={9} />
        <TextTitle type="sub" className="book-medical-procedures__sub-title">
          Your Gynaecological Surgical History
        </TextTitle>
      </Content>
      <Content>
        {apiError && (
          <AlertError label={apiError} onClose={onCloseAlertError} />
        )}

        <form onSubmit={handleSubmit(onSubmitForm)}>
          {renderMedicalProcedures()}
          {requestInProgress === true ? (
            <LoadingSpinner />
          ) : (
            <Button type="submit">Continue</Button>
          )}
        </form>
      </Content>
    </div>
  );
};

MedicalProcedures.propTypes = {
  onStageComplete: PropTypes.func.isRequired,
};

export default MedicalProcedures;
