import { SCHEDULE_PRICE } from '../slices/scheduleSlice';

// see app-patient/.env.*
const googleAnalyticsGa4Id = process.env.REACT_APP_GOOGLE_ANALYTICS_V4_ID;
const googleAnalyticsUaId = process.env.REACT_APP_GOOGLE_ANALYTICS_UA_ID;
const googleAdwordsPurchaseConversionId =
  process.env.REACT_APP_GOOGLE_ADWORDS_PURCHASE_CONVERSION_ID;
const googleAdwordsRegisterConversionId =
  process.env.REACT_APP_GOOGLE_ADWORDS_REGISTER_CONVERSION_ID;

/* eslint-disable no-undef */
export const TrackEventNames = {
  /**
   * Registration
   */
  REGISTER__STEP_1__ABOUT_YOU__COMPLETED:
    'REGISTER__STEP_1__ABOUT_YOU__COMPLETED',
  REGISTER__STEP_1__ABOUT_YOU__FAILED: 'REGISTER__STEP_1__ABOUT_YOU__FAILED',
  REGISTER__STEP_2__CONF_EMAIL__COMPLETED:
    'REGISTER__STEP_2__CONF_EMAIL__COMPLETED',
  // Error confirming email address - incorrect confirmation code
  REGISTER__STEP_2__CONFIRM_EMAIL__FAILED:
    'REGISTER__STEP_2__CONFIRM_EMAIL__FAILED',

  /**
   * Login
   */
  // Incorrect email / password
  LOGIN__STEP_1__FAILED: 'LOGIN__STEP_1__FAILED',
  LOGIN__STEP_1__COMPLETED: 'LOGIN__STEP_1__COMPLETED',
  // Incorrect 2 factor authentication code
  LOGIN__STEP_2__FAILED: 'LOGIN__STEP_2__FAILED',
  LOGIN__STEP_2__COMPLETED: 'LOGIN__STEP_2__COMPLETED',

  /**
   * Forgotten password
   */
  // Incorrect email / password
  FORGOT_PASSWORD__STEP_1__COMPLETED: 'FORGOT_PASSWORD__STEP_1__COMPLETED',
  // Incorrect 2 factor authentication code
  FORGOT_PASSWORD__STEP_2__FAILED: 'FORGOT_PASSWORD__STEP_2__FAILED',
  FORGOT_PASSWORD__STEP_2__COMPLETED: 'FORGOT_PASSWORD__STEP_2__COMPLETED',

  /**
   * Book appointment
   */

  BOOK_V2__STEP_1__SELECT_DATE__COMPLETED:
    'BOOK_V2__STEP_1__SELECT_DATE__COMPLETED',
  // Error booking time - possibly time was booked by another user while
  // calendar was open (duplicate booking prevented)
  BOOK_V2__STEP_2__SELECT_TIME__FAILED: 'BOOK_V2__STEP_2__SELECT_TIME__FAILED',
  BOOK_V2__STEP_2__SELECT_TIME__COMPLETED:
    'BOOK_V2__STEP_2__SELECT_TIME__COMPLETED',
  BOOK_V2__STEP_3__YOUR_ADDRESS__COMPLETED:
    'BOOK_V2__STEP_3__YOUR_ADDRESS__COMPLETED',
  BOOK_V2__STEP_4__YOUR_GP__COMPLETED: 'BOOK_V2__STEP_4__YOUR_GP__COMPLETED',
  BOOK_V2__STEP_5__MEDICAL_PROFILE__COMPLETED:
    'BOOK_V2__STEP_5__MEDICAL_PROFILE__COMPLETED',
  BOOK_V2__STEP_6__MEDICAL_HISTORY__COMPLETED:
    'BOOK_V2__STEP_6__MEDICAL_HISTORY__COMPLETED',
  BOOK_V2__STEP_7__MEDICATION__COMPLETED:
    'BOOK_V2__STEP_7__MEDICATION__COMPLETED',
  BOOK_V2__STEP_8__HRT__COMPLETED: 'BOOK_V2__STEP_8__HRT__COMPLETED',
  BOOK_V2__STEP_9__MENSTRUAL__COMPLETED:
    'BOOK_V2__STEP_9__MENSTRUAL__COMPLETED',
  BOOK_V2__STEP_10__CONTRACEPTION__COMPLETED:
    'BOOK_V2__STEP_10__CONTRACEPTION__COMPLETED',
  BOOK_V2__STEP_11__PROCEDURES__COMPLETED:
    'BOOK_V2__STEP_11__PROCEDURES__COMPLETED',
  BOOK_V2__STEP_12__ABOUT_YOUR_APPT__COMPLETED:
    'BOOK_V2__STEP_12__ABOUT_YOUR_APPT__COMPLETED',
  BOOK_V2__STEP_13__CONFIRM__COMPLETED: 'BOOK_V2__STEP_13__CONFIRM__COMPLETED',
  BOOK_V2__STEP_14__PAYMENT__COMPLETED: 'BOOK_V2__STEP_14__PAYMENT__COMPLETED',
};

export function setUserId(userId) {
  if (!window.gtag) {
    return;
  }

  gtag('config', googleAnalyticsGa4Id, {
    user_id: userId,
  });
}

// Wrapper around GA and FB tracking
export function trackEvent(eventName, options = {}) {
  console.log('Tracking: ', eventName);
  // Send events to Google Analytics 4 and Google Universal Analytics
  const googleAnalyticsGa4Options = { send_to: googleAnalyticsGa4Id };
  const googleAnalyticsUaOptions = { send_to: googleAnalyticsUaId };

  if (window.gtag) {
    window.gtag('event', eventName, googleAnalyticsGa4Options);
    window.gtag('event', eventName, googleAnalyticsUaOptions);
  } else {
    console.log('No gtag');
  }

  // For specific custom events, also track recommended GA events, see full list
  // at https://support.google.com/analytics/answer/9267735
  // eslint-disable-next-line default-case
  switch (eventName) {
    case TrackEventNames.LOGIN__STEP_2__COMPLETED:
      if (window.gtag) {
        window.gtag('event', 'login', googleAnalyticsGa4Options);
        window.gtag('event', 'login', googleAnalyticsUaOptions);
      }
      break;
    case TrackEventNames.REGISTER__STEP_2__CONF_EMAIL__COMPLETED:
      if (window.gtag) {
        window.gtag('event', 'sign_up', googleAnalyticsGa4Options);
        window.gtag('event', 'sign_up', googleAnalyticsUaOptions);
      }

      // Only set on production
      if (googleAdwordsRegisterConversionId) {
        // For Adwords
        window.gtag('event', 'conversion', {
          send_to: googleAdwordsRegisterConversionId,
        });
      }

      break;
    case TrackEventNames.BOOK_V2__STEP_14__PAYMENT__COMPLETED:
      if (window.gtag) {
        // https://developers.google.com/analytics/devguides/collection/gtagjs/ecommerce
        // https://developers.google.com/gtagjs/reference/event#purchase

        const gaPurchaseParams = {
          ...options.purchaseDetails,
        };

        // For analytics
        window.gtag('event', 'purchase', {
          ...googleAnalyticsGa4Options,
          ...gaPurchaseParams,
        });

        window.gtag('event', 'purchase', {
          ...googleAnalyticsUaOptions,
          ...gaPurchaseParams,
        });

        // Only set on production
        if (googleAdwordsPurchaseConversionId) {
          console.log('CR');
          // For Adwords
          window.gtag('event', 'conversion', {
            send_to: googleAdwordsPurchaseConversionId,
            value: SCHEDULE_PRICE,
            currency: 'GBP',
          });
        } else console.log('NCR');
      }
  }
}

export function trackPageView(location, path) {
  if (!window.gtag) {
    return;
  }

  // Dont track mongoIds in URL
  const cleanLocation = location.replace(/[0-9a-fA-F]{24}/gi, ':id');
  const cleanPath = path.replace(/[0-9a-fA-F]{24}/gi, ':id');

  window.gtag('event', 'page_view', {
    page_title: cleanPath,
    page_location: cleanLocation,
    page_path: cleanPath,
    send_to: googleAnalyticsGa4Id,
  });

  window.gtag('event', 'page_view', {
    page_title: cleanPath,
    page_location: cleanLocation,
    page_path: cleanPath,
    send_to: googleAnalyticsUaId,
  });
}
