import './NavBar.scss';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';

const NavBar = (props) => {
  const { active, desktopOnly } = props;

  const classes = ['navigation-nav-bar'];

  if (desktopOnly) {
    classes.push('navigation-nav-bar--desktop-only');
  }

  return (
    <div className={classes.join(' ')}>
      <Link
        to="/"
        className={`navigation-nav-bar__tab ${
          active === 'HOME' ? 'navigation-nav-bar__tab--active' : ''
        }`}
      >
        <HomeOutlinedIcon className="navigation-nav-bar__icon" />
        <div className="navigation-nav-bar__label">Home</div>
      </Link>
      <Link
        to="/medical"
        className={`navigation-nav-bar__tab ${
          active === 'MEDICAL' ? 'navigation-nav-bar__tab--active' : ''
        }`}
      >
        <AddCircleOutlineOutlinedIcon className="navigation-nav-bar__icon" />
        <div className="navigation-nav-bar__label">Medical</div>
      </Link>
      <Link
        to="/help"
        className={`navigation-nav-bar__tab ${
          active === 'HELP' ? 'navigation-nav-bar__tab--active' : ''
        }`}
      >
        <HelpOutlineOutlinedIcon className="navigation-nav-bar__icon" />
        <div className="navigation-nav-bar__label">Help</div>
      </Link>
      <Link
        to="/account"
        className={`navigation-nav-bar__tab ${
          active === 'ACCOUNT' ? 'navigation-nav-bar__tab--active' : ''
        }`}
      >
        <PersonOutlineOutlinedIcon className="navigation-nav-bar__icon" />
        <div className="navigation-nav-bar__label">Account</div>
      </Link>
    </div>
  );
};

NavBar.propTypes = {
  active: PropTypes.oneOf(['HOME', 'MEDICAL', 'HELP', 'ACCOUNT', '']),
  desktopOnly: PropTypes.bool,
};

NavBar.defaultProps = {
  active: '',
  desktopOnly: false,
};

export default NavBar;
