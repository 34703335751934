import TextBody from '../elements/TextBody';

const EmailContact = () => {
  return (
    <div className="register-data">
      <TextBody>
        We understand that your privacy is important and it is part of our
        commitment that we handle your personal information with care.
      </TextBody>

      <TextBody>
        At Health &amp; Her we will only use your email address to contact you
        with important information regarding your Health &amp; Her Clinic
        account, including but not limited to:
      </TextBody>

      <TextBody>
        <ul>
          <li>Appointment reminders</li>
          <li>Booking confirmations</li>
          <li>To let you know your appointment notes are ready to download</li>
          <li>Security messages</li>
        </ul>
      </TextBody>
    </div>
  );
};

export default EmailContact;
