import './index.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { StylesProvider } from '@material-ui/core/styles';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import Bugsnag from '@bugsnag/js';
import axios from 'axios';
import Amplify from '@aws-amplify/core';
import { Auth } from '@aws-amplify/auth';
import { hotjar } from 'react-hotjar';
import packageInfo from '../package.json';

import AppRouter from './routers/AppRouter';
import store from './app/store';

import { awsconfig } from './config/aws-config';

Amplify.configure(awsconfig);

Bugsnag.start({
  apiKey: 'ce9ef3da8cdf134e5e8c345ee2a2f180',
  appVersion: packageInfo.version,
  releaseStage: process.env.REACt_APP_STAGE || 'staging',
  plugins: [new BugsnagPluginReact()],
});

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

const ErrorView = () => (
  <div>
    <p>
      We have experienced an unexpected error trying to load this page. Please
      contact clinic@healthandher.com for further assistance.{' '}
    </p>
  </div>
);

hotjar.initialize(2994657, 6);

// hotjar.identify('USER_ID', { userProperty: 'value' });

// Also add the bearer token to all requests. This sets up axios's interceptor to intercept any request and add it
axios.interceptors.request.use(
  async (config) => {
    // If we don't have a user, then don't care...
    try {
      const user = await Auth.currentUserInfo();
      if (!user) return config;

      const session = await Auth.currentSession();

      const token = session.getAccessToken().getJwtToken();

      if (token) {
        // eslint-disable-next-line no-param-reassign
        config.headers.authorization = token;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
    return config;
  },
  async (error) => {
    return Promise.reject(error);
  }
);

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary FallbackComponent={ErrorView}>
      <Provider store={store}>
        {/* Inject the material UI styles first into the head so they can
      be overridden by component styles
      https://material-ui.com/styles/advanced/#css-injection-order */}
        <StylesProvider injectFirst>
          <AppRouter />
        </StylesProvider>
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);
