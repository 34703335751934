import './Checkbox.scss';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import FormFieldError from './FieldError';

const Checkbox = (props) => {
  const {
    noMargin,
    label,
    labelHighlight,
    name,
    registerRef,
    error,
    onClickLabel,
    className,
  } = props;

  const id = uuidv4();
  const classes = ['form-checkbox'];

  let errorLabel = '';
  if (Object.keys(error).length > 0) {
    classes.push('form-checkbox--error');

    if (error.message.length > 0) {
      errorLabel = error.message;
    } else {
      errorLabel = 'Invalid input';
    }
  }

  if (noMargin) {
    classes.push('form-checkbox--no-margin');
  }

  if (className.length > 0) {
    classes.push(className);
  }

  return (
    <div className={classes.join(' ')}>
      <label className="form-checkbox__label" htmlFor={id}>
        <input
          className="form-checkbox__checkbox"
          type="checkbox"
          id={id}
          name={name}
          ref={registerRef}
        />
        <span onClick={onClickLabel}>
          {label}
          {labelHighlight && (
            <span className="form-checkbox__label-highlight">
              {labelHighlight}
            </span>
          )}
        </span>
      </label>
      {error && errorLabel.length > 0 ? (
        <FormFieldError label={errorLabel} />
      ) : null}
    </div>
  );
};

Checkbox.propTypes = {
  onClickLabel: PropTypes.func,
  noMargin: PropTypes.bool,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  labelHighlight: PropTypes.string,
  name: PropTypes.string.isRequired,
  registerRef: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  error: PropTypes.object,
  className: PropTypes.string,
};

Checkbox.defaultProps = {
  onClickLabel: null,
  noMargin: false,
  error: {},
  className: '',
};

export default Checkbox;
