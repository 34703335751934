import './History.scss';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import TextBody from '../../elements/TextBody';
import Content from '../../elements/Content';
import PageHeader from '../../elements/PageHeader';
import Button from '../../elements/Button';
import TextInput from '../../elements/form/TextInput';
import LoadingSpinner from '../../elements/LoadingSpinner';
import { selectAll as medicalHistoriesSelectAll } from '../../../slices/medicalHistoriesSlice';
import {
  selectPatient,
  selectMedicalHistoryIds,
  replacePatient,
} from '../../../slices/patientSlice';
import { patch } from '../../../services/api/apiPatient';
import AlertError from '../../elements/alert/Error';
import TextTitle from '../../elements/TextTitle';
import FieldError from '../../elements/form/FieldError';
import MedicalHistoryOptions from '../../content/MedicalHistoryOptions';

const History = (props) => {
  const { onClickBack } = props;
  const patient = useSelector(selectPatient);
  const medicalHistories = useSelector(medicalHistoriesSelectAll);
  const medicalHistoryIds = useSelector(selectMedicalHistoryIds);
  const { register, errors, handleSubmit } = useForm({
    defaultValues: {
      medicalHistory: medicalHistoryIds.reduce((acc, cur) => {
        acc[cur] = true;
        return acc;
      }, {}),
      medicalHistoryOther: patient.medicalHistoryOther,
    },
  });
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [apiError, setApiError] = useState();
  const dispatch = useDispatch();
  const [medicalHistoriesError, setMedicalHistoriesError] = useState('');

  const patchRequest = async (data) => {
    setRequestInProgress(true);

    try {
      const response = await patch(data);
      dispatch(replacePatient(response.patient));
      onClickBack();
    } catch (err) {
      setApiError(
        'There was an error updating your medical details. Please try again.'
      );

      window.scrollTo(0, 0);
      setRequestInProgress(false);
    }
  };

  const onSubmitForm = (data) => {
    setMedicalHistoriesError('');

    if (requestInProgress) {
      return;
    }

    const { medicalHistory, medicalHistoryOther } = data;

    const checkedMedicalHistoryIds = Object.keys(medicalHistory).filter(
      (el) => {
        return medicalHistory[el] === true;
      }
    );

    const personalMedicalHistory =
      medicalHistories.filter((medicalHistoryItem) => {
        return (
          medicalHistoryItem.type === 'personal' &&
          checkedMedicalHistoryIds.includes(medicalHistoryItem._id)
        );
      }).length > 0;

    if (
      checkedMedicalHistoryIds.length === 0 ||
      (!personalMedicalHistory && !checkedMedicalHistoryIds.includes('NONE'))
    ) {
      setMedicalHistoriesError('Please select at least one option below.');
      window.scrollTo(0, 0);
      return;
    }

    // Ensure only the None of the above option is selected by itself
    if (
      checkedMedicalHistoryIds.includes('NONE') &&
      checkedMedicalHistoryIds.length > 1 &&
      personalMedicalHistory
    ) {
      setMedicalHistoriesError(
        "You cannot select the 'None of the above' option with other options selected. Please review your answers."
      );
      window.scrollTo(0, 0);
      return;
    }

    patchRequest({
      attributesToUpdate: {
        medicalHistory: checkedMedicalHistoryIds,
        medicalHistoryOther,
      },
    });
  };

  const onCloseAlertError = () => {
    setApiError(null);
  };

  return (
    <>
      <PageHeader showBack onClickBack={onClickBack} />
      <div className="medical-history">
        <Content>
          {apiError && (
            <AlertError label={apiError} onClose={onCloseAlertError} />
          )}

          <TextTitle type="sub">Your Medical History</TextTitle>

          <form onSubmit={handleSubmit(onSubmitForm)}>
            <TextBody>
              Do you suffer or have you ever suffered with any of the following
              conditions? (Please check all that apply.)
            </TextBody>

            {medicalHistoriesError.length > 0 && (
              <FieldError
                label={medicalHistoriesError}
                className="book-medical-history__field-error"
              />
            )}
            <MedicalHistoryOptions
              medicalHistories={medicalHistories}
              register={register}
            />
            <TextInput
              label="Other"
              type="text"
              name="medicalHistoryOther"
              className="mb-3xl"
              error={errors.medicalHistoryOther}
              registerRef={register({
                maxLength: {
                  value: 500,
                  message: 'Too many characters (max 200).',
                },
              })}
            />
            <TextTitle type="sub" className="book-medical-history__sub-title">
              Family Medical History
            </TextTitle>
            <TextBody>
              Please select any of the following conditions that have affected
              someone in your close family, i.e. a parent, brother or sister,
              aunt, uncle, grandparent or cousin.
            </TextBody>
            <MedicalHistoryOptions
              medicalHistories={medicalHistories}
              register={register}
              type={'family'}
            />
            {requestInProgress === true ? (
              <LoadingSpinner />
            ) : (
              <Button type="submit">Save</Button>
            )}
          </form>
        </Content>
      </div>
    </>
  );
};

History.propTypes = {
  onClickBack: PropTypes.func.isRequired,
};

export default History;
