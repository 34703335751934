import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { selectBookingHeldAppointment } from './appointmentsSlice';

export const ScheduleStatuses = {
  AVAILABLE: 'AVAILABLE',
  BOOKING_HELD: 'BOOKING_HELD',
  BOOKED: 'BOOKED',
};

export const ScheduleTypes = {
  INITIAL: 'INITIAL',
  FOLLOW_UP: 'FOLLOW_UP',
};

export const ScheduleAvailabilityStatuses = {
  FULLY_BOOKED: 'FULLY_BOOKED',
  LIMITED: 'LIMITED',
  GOOD: 'GOOD',
};

export const SCHEDULE_PRICE = 135;
export const SCHEDULE_DURATION_PATIENT_MINS = 35;

export const scheduleSlice = createSlice({
  name: 'schedule',
  initialState: {
    fetched: false,
    entities: [],
  },
  // Redux Toolkit allows us to write "mutating" logic in reducers. It
  // doesn't actually mutate the state because it uses the Immer library,
  // which detects changes to a "draft state" and produces a brand new
  // immutable state based off those changes
  reducers: {
    schedulesLoading(state) {
      state.fetched = false;
      state.entities = [];
    },
    schedulesReceived(state, action) {
      state.fetched = true;
      state.entities = action.payload;
    },
    replaceSchedule: (state, action) => {
      const index = state.entities.findIndex(
        (el) => el._id === action.payload._id
      );
      if (index !== -1) {
        state.entities[index] = action.payload;
      } else {
        state.entities.push(action.payload);
      }
    },
  },
});

export const { schedulesLoading, schedulesReceived, replaceSchedule } =
  scheduleSlice.actions;

const sortSchedulesByPatientStartAt = (schedule) => {
  const copy = [...schedule];
  return copy.sort((a, b) =>
    dayjs(a.patientStartAt).isBefore(b.patientStartAt) ? -1 : 1
  );
};

// Selectors ******************************************************************
// The functions below are called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`

export const selectAllSchedules = (state) => {
  return sortSchedulesByPatientStartAt(state.schedule.entities);
};

export const selectFetched = (state) => {
  return state.schedule.fetched;
};

export const selectAvailableSchedules = (state) => {
  const available = state.schedule.entities.filter((el) => {
    return el.status === ScheduleStatuses.AVAILABLE;
  });

  return sortSchedulesByPatientStartAt(available);
};

export const selectTypeInitialAvailabilityCountGroupDate = (state) => {
  const available = selectAllSchedules(state);
  const bookingHeldAppointment = selectBookingHeldAppointment(state);

  const countsGroupDate = available.reduce((acc, cur) => {
    const date = dayjs(cur.patientStartAt).format('YYYY-MM-DD');
    if (acc[date] === undefined) {
      acc[date] = 0;
    }

    let isBookingHeld = false;
    if (bookingHeldAppointment) {
      isBookingHeld = cur._id === bookingHeldAppointment.scheduleId;
    }

    if (cur.status === ScheduleStatuses.AVAILABLE || isBookingHeld) {
      acc[date] += 1;
    }

    return acc;
  }, {});

  return countsGroupDate;
};

export const selectTypeInitialAvailabilityStatusGroupDate = (state) => {
  const countsGroupDate = selectTypeInitialAvailabilityCountGroupDate(state);

  // Iterate over each of the dates and how many available appointments that
  // are available for the date and return a new object with date keys and a
  // label of availability
  const statusesGroupDate = Object.keys(countsGroupDate).reduce((acc, cur) => {
    const countForDate = countsGroupDate[cur];

    let availabilityStatus;
    if (countForDate === 0) {
      availabilityStatus = ScheduleAvailabilityStatuses.FULLY_BOOKED;
    } else if (countForDate < 3) {
      availabilityStatus = ScheduleAvailabilityStatuses.LIMITED;
    } else {
      availabilityStatus = ScheduleAvailabilityStatuses.GOOD;
    }
    acc[cur] = availabilityStatus;

    return acc;
  }, {});

  return statusesGroupDate;
};

export default scheduleSlice.reducer;
