import './View.scss';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import TextTitle from '../../elements/TextTitle';
import TextBody from '../../elements/TextBody';
import Content from '../../elements/Content';
import PageHeader from '../../elements/PageHeader';
import PreAppointment from './PreAppointment';
import PostAppointment from './PostAppointment';
import Cancelled from './Cancelled';
import PatientNoShow from './PatientNoShow';
import {
  AppointmentStatuses,
  selectAllAppointments,
} from '../../../slices/appointmentsSlice';
import { selectAllPractitioners } from '../../../slices/practitionersSlice';
import NavBar from '../../navigation/NavBar';
import AlertError from '../../elements/alert/Error';

dayjs.extend(advancedFormat);

const View = () => {
  const appointments = useSelector(selectAllAppointments);
  const practitioners = useSelector(selectAllPractitioners);
  const { appointmentId } = useParams();
  const history = useHistory();

  const appointment = appointments.find((el) => el._id === appointmentId);

  const onCloseAlertError = () => {
    history.push('/');
  };

  if (!appointment) {
    return (
      <AlertError
        label="Unable to load appointment, the appointment could not be found."
        onClose={onCloseAlertError}
      />
    );
  }

  const practitioner = practitioners.find(
    (el) => el._id === appointment.practitionerId
  );
  if (!practitioner) {
    return (
      <AlertError
        label="Unable to load appointment, the appointment could not be found."
        onClose={onCloseAlertError}
      />
    );
  }

  const startAt = dayjs(appointment.startAt);
  const endAt = dayjs(appointment.endAt);

  const onClickBack = () => {
    history.push('/');
  };

  const renderContent = () => {
    // Appointment is cancelled
    if (appointment.status === AppointmentStatuses.CANCELLED) {
      return <Cancelled appointment={appointment} />;
    }

    // Appointment - patient didn't show
    if (appointment.status === AppointmentStatuses.PATIENT_NO_SHOW) {
      return <PatientNoShow />;
    }

    // Before appointment starts
    if (
      [AppointmentStatuses.BOOKED, AppointmentStatuses.IN_PROGRESS].includes(
        appointment.status
      )
    ) {
      return <PreAppointment appointment={appointment} />;
    }

    // After appointment
    return (
      <PostAppointment appointment={appointment} practitioner={practitioner} />
    );
  };

  const practitionerFullname = [
    practitioner.title,
    practitioner.firstName,
    practitioner.lastName,
  ].join(' ');

  return (
    <>
      <PageHeader showBack onClickBack={onClickBack} />
      <div className="appointment-view">
        <Content>
          <TextTitle type="sub">Your Appointment</TextTitle>

          <TextBody emphasis>Appointment Date</TextBody>
          <TextBody>{startAt.format('dddd Do MMMM YYYY')}</TextBody>

          <TextBody emphasis>Appointment Time</TextBody>
          <TextBody>
            {startAt.format('h:mm A')} - {endAt.format('h:mm A')}
          </TextBody>

          <TextBody emphasis>Doctor</TextBody>
          <TextBody>{practitionerFullname}</TextBody>

          <div className="appointment-view__content">{renderContent()}</div>
        </Content>
        <NavBar desktopOnly />
      </div>
    </>
  );
};

export default View;
