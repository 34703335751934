import { createSlice } from '@reduxjs/toolkit';
import { getAccount } from '../services/api/apiPatient';
import { replaceMedicalHistories } from './medicalHistoriesSlice';
import { replaceContraceptions } from './contraceptionsSlice';
import { replaceHrts } from './hrtsSlice';
import { replaceMedicalProcedures } from './medicalProceduresSlice';
import { replaceMenopauseSymptoms } from './menopauseSymptomsSlice';
import { replaceAppointments } from './appointmentsSlice';
import { replacePractitioners } from './practitionersSlice';
import { replaceSettings } from './settingsSlice';
import { setUserId } from '../services/tracking';

export const PatientRegisteredWithGp = {
  YES: 'YES',
  NO: 'NO',
};

export const PatientGpDetailsToHand = {
  YES: 'YES',
  NO: 'NO',
};

export const PatientHasAllergies = {
  YES: 'YES',
  NO: 'NO',
  DONT_KNOW: 'DONT_KNOW',
};

export const PatientTakingMedication = {
  YES: 'YES',
  NO: 'NO',
};

export const PatientTakingHrt = {
  YES: 'YES',
  NO: 'NO',
};

export const PatientTakingContraception = {
  YES: 'YES',
  NO: 'NO',
};

export const PatientHadSmearTest = {
  YES: 'YES',
  NO: 'NO',
};

export const PatientSmoking = {
  CURRENT_SMOKER: 'CURRENT_SMOKER',
  EX_SMOKER: 'EX_SMOKER',
  NEVER_SMOKED: 'NEVER_SMOKED',
};

export const PatientAlcohol = {
  YES: 'YES',
  NO: 'NO',
};

export const PatientLastPeriodRange = {
  'Less than 1 month ago': '<1',
  '1-3 months ago': '1-3',
  '3-6 months ago': '3-6',
  '6-9 months ago': '6-9',
  '9-12 months ago': '9-12',
  'Over 12 months ago': '>12',
};

export const patientSlice = createSlice({
  name: 'patient',
  initialState: {
    fetched: false,
    isAuthenticated: false,
    entity: null,
  },
  // Redux Toolkit allows us to write "mutating" logic in reducers. It
  // doesn't actually mutate the state because it uses the Immer library,
  // which detects changes to a "draft state" and produces a brand new
  // immutable state based off those changes
  reducers: {
    patientLoading(state) {
      state.fetched = false;
      state.entity = null;
    },
    patientReceived(state, action) {
      state.fetched = true;
      state.isAuthenticated = true;
      state.entity = action.payload;
    },
    patientNotAuthenticated(state) {
      state.fetched = true;
      state.isAuthenticated = false;
      state.entity = null;
    },
    replacePatient(state, action) {
      state.entity = action.payload;
    },
  },
});

export const {
  patientLoading,
  patientReceived,
  patientNotAuthenticated,
  replacePatient,
} = patientSlice.actions;

export const fetchPatient = () => async (dispatch) => {
  dispatch(patientLoading());

  try {
    const {
      medicalHistories,
      contraceptions,
      hrts,
      hrtCategories,
      medicalProcedures,
      symptoms,
      patient,
      appointments,
      practitioners,
      recentBookings,
    } = await getAccount();
    dispatch(replaceMedicalHistories(medicalHistories));
    dispatch(replaceContraceptions(contraceptions));
    dispatch(replaceHrts({ hrtCategories, hrts }));
    dispatch(replaceMedicalProcedures(medicalProcedures));
    dispatch(replaceMenopauseSymptoms(symptoms));

    dispatch(replacePractitioners(practitioners));
    dispatch(replaceAppointments(appointments));
    dispatch(patientReceived(patient));
    dispatch(replaceSettings({ recentBookings }));

    setUserId(patient._id);
  } catch (err) {
    dispatch(patientNotAuthenticated());
  }
};

// Selectors ******************************************************************
// The functions below are called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`

export const selectPatient = (state) => {
  return state.patient.entity;
};

export const selectPatientFetched = (state) => {
  return state.patient.fetched;
};

export const selectPatientIsAuthenticated = (state) => {
  return state.patient.fetched && state.patient.isAuthenticated;
};

export const selectMedicalHistoryIds = (state) => {
  return state.patient.entity.medicalHistory.map((el) => el);
};

export const selectSymptomIds = (state) => {
  if (!state.patient.entity.menopauseSymptoms) {
    return [];
  }

  return state.patient.entity.menopauseSymptoms.map((el) => el);
};

export const selectContraceptionIds = (state) => {
  return state.patient.entity.takingContraceptionDetails.map((el) => el);
};

export const selectHrtIds = (state) => {
  return state.patient.entity.takingHrtDetails.map((el) => el);
};

export const selectPreviousHrtIds = (state) => {
  if (!state.patient.entity.previousHrtDetails) {
    return [];
  }

  return state.patient.entity.previousHrtDetails.map((el) => el);
};

export default patientSlice.reducer;
