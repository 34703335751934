import './Success.scss';
import { useEffect, useCallback } from 'react';
import dayjs from 'dayjs';
import { useHistory, Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PageHeader from '../../elements/PageHeader';
import TextTitle from '../../elements/TextTitle';
import TextBody from '../../elements/TextBody';
import Content from '../../elements/Content';
import Button from '../../elements/Button';
import {
  selectAllAppointments,
  AppointmentStatuses,
} from '../../../slices/appointmentsSlice';
import { selectAllPractitioners } from '../../../slices/practitionersSlice';
import NavBar from '../../navigation/NavBar';
import { selectPatient } from '../../../slices/patientSlice';
import AlertError from '../../elements/alert/Error';
import { trackEvent, TrackEventNames } from '../../../services/tracking';
import { getPaymentDetails } from '../../../services/api/apiAppointment';

const Success = () => {
  const history = useHistory();
  const appointments = useSelector(selectAllAppointments);
  const practitioners = useSelector(selectAllPractitioners);
  const patient = useSelector(selectPatient);
  const { appointmentId } = useParams();
  const appointment = appointments.find((el) => el._id === appointmentId);

  const trackPayment = useCallback(async () => {
    if (!appointment) {
      return;
    }

    const paymentDetails = await getPaymentDetails({
      appointmentId: appointment._id,
    });

    if (!paymentDetails) {
      return;
    }

    const purchaseDetails = {
      value: paymentDetails.amountTotal / 100,
      currency: paymentDetails.currency.toUpperCase(),
      items: [
        {
          id: 'gp-consultation-35-minutes',
          name: 'GP Consultation (35 minutes)',
          brand: 'Health & Her',
          category: 'GP Consultation',
          quantity: 1,
          price: paymentDetails.amountTotal / 100,
          discount: paymentDetails.amountDiscount / 100,
        },
      ],
      // This is needed to show event in GA
      transaction_id: paymentDetails.paymentId,
    };

    trackEvent(TrackEventNames.BOOK_V2__STEP_14__PAYMENT__COMPLETED, {
      purchaseDetails,
    });
  }, [appointment]);

  useEffect(() => {
    trackPayment();
  }, [trackPayment]);

  const onCloseAlertError = () => {
    history.push('/');
  };

  if (!appointment || appointment.status !== AppointmentStatuses.BOOKED) {
    return (
      <AlertError
        label="Unable to load appointment, the appointment could not be found."
        onClose={onCloseAlertError}
      />
    );
  }

  const practitioner = practitioners.find(
    (el) => el._id === appointment.practitionerId
  );
  if (!practitioner) {
    return (
      <AlertError
        label="Unable to load appointment, the appointment could not be found."
        onClose={onCloseAlertError}
      />
    );
  }

  const startAt = dayjs(appointment.startAt);

  const practitionerFullname = [
    practitioner.title,
    practitioner.firstName,
    practitioner.lastName,
  ].join(' ');

  return (
    <>
      <PageHeader title="Appointment Booked" />
      <div className="appointment-success">
        <Content>
          <TextTitle type="sub">
            Thanks {patient.firstName.trim()}, your appointment is booked!
          </TextTitle>
          <TextBody>
            Your appointment will take place on{' '}
            <strong>{startAt.format('dddd Do MMMM YYYY')}</strong> at{' '}
            <strong>{startAt.format('h:mm A')}</strong> with{' '}
            <strong>{practitionerFullname}</strong>.
          </TextBody>

          <TextBody>
            A confirmation of your booking has also been sent to{' '}
            <em>{patient.email}</em>, with information on how to attend your
            appointment.
          </TextBody>

          <TextBody>
            Please arrive at your appointment 15 minutes before the start time
            to allow the system to check that you have good internet
            connectivity, audio and video. You will then proceed to the virtual
            waiting room where your doctor will meet you.
          </TextBody>

          <TextBody>
            For more information about our clinic and to prepare for your
            consultation, please read our video consultation guide.
          </TextBody>

          <div>
            <Link
              to="/files/health-and-her-clinic-video-consultation-guide.pdf"
              target="_blank"
            >
              <Button color="primary">Video Consultation Guide</Button>
            </Link>
          </div>

          <Link to="/">
            <Button color="primary-link">Return to Home</Button>
          </Link>
        </Content>
        <NavBar desktopOnly />
      </div>
    </>
  );
};

export default Success;
