import './Gp.scss';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Content from '../../elements/Content';
import PageHeader from '../../elements/PageHeader';
import Button from '../../elements/Button';
import { patch } from '../../../services/api/apiPatient';
import {
  selectPatient,
  replacePatient,
  PatientRegisteredWithGp,
} from '../../../slices/patientSlice';
import AlertError from '../../elements/alert/Error';
import FormGpDetails from '../../forms/GpDetails';
import TextTitle from '../../elements/TextTitle';

const Gp = (props) => {
  const { onClickBack } = props;
  const dispatch = useDispatch();
  const [apiError, setApiError] = useState();
  const patient = useSelector(selectPatient);
  const { register, handleSubmit, errors, setValue, watch } = useForm({
    defaultValues: {
      registeredWithGp: patient.registeredWithGp,
      gpName: patient.gp && patient.gp.gpName,
      surgeryName: patient.gp && patient.gp.surgeryName,
      surgeryAddress: patient.gp && patient.gp.surgeryAddress,
      emergencyContact: patient.gp && patient.gp.emergencyContact,
      confirmSharePatientOwnGp:
        patient.gp && patient.gp.confirmSharePatientOwnGpAt,
    },
  });

  const patchRequest = async (data) => {
    try {
      const response = await patch(data);
      dispatch(replacePatient(response.patient));
      onClickBack();
    } catch (err) {
      setApiError(
        'There was an error updating your GP details. Please try again.'
      );
    }
  };

  const onSubmitForm = (data) => {
    const { registeredWithGp, ...gpDetails } = data;
    const attributesToUpdate = {
      registeredWithGp,
      gp:
        registeredWithGp === PatientRegisteredWithGp.YES
          ? { ...gpDetails }
          : null,
    };

    patchRequest({ attributesToUpdate });
  };

  const onCloseAlertError = () => {
    setApiError(null);
  };

  const patientName = patient.middleName
    ? [patient.firstName, patient.middleName, patient.lastName].join(' ')
    : [patient.firstName, patient.lastName].join(' ');

  return (
    <>
      <PageHeader showBack onClickBack={onClickBack} />
      <div className="medical-gp">
        <Content>
          {apiError && (
            <AlertError label={apiError} onClose={onCloseAlertError} />
          )}

          <TextTitle type="sub">GP Name and Address</TextTitle>

          <form onSubmit={handleSubmit(onSubmitForm)}>
            <FormGpDetails
              errors={errors}
              register={register}
              watch={watch}
              setValue={setValue}
              patientName={patientName}
              showGpDetailsToHandQuestion={false}
            />

            <Button type="submit">Save</Button>
          </form>
        </Content>
      </div>
    </>
  );
};

Gp.propTypes = {
  onClickBack: PropTypes.func.isRequired,
};

export default Gp;
