import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { getGpLetter } from '../services/api/apiAppointment';

export const AppointmentStatuses = {
  BOOKING_HELD: 'BOOKING_HELD',
  BOOKED: 'BOOKED',
  PATIENT_NO_SHOW: 'PATIENT_NO_SHOW',
  IN_PROGRESS: 'IN_PROGRESS',
  WAITING_ON_PRACTITIONER: 'WAITING_ON_PRACTITIONER',
  COMPLETED: 'COMPLETED',
  CANCELLED: 'CANCELLED',
};

export const AppointmentTypes = {
  INITIAL: 'INITIAL',
  FOLLOW_UP: 'FOLLOW_UP',
};

export const getGpLetterByAccountId = createAsyncThunk(
  'gpLetter/fetch',
  async ({ appointmentId }) => {
    try {
      const gpLetter = await getGpLetter(appointmentId);
      return { url: gpLetter.url, appointmentId };
    } catch (error) {
      throw error.response?.data?.error
        ? new Error(error.response.data.error)
        : error;
    }
  }
);

export const appointmentsSlice = createSlice({
  name: 'appointments',
  initialState: {
    entities: [],
  },
  // Redux Toolkit allows us to write "mutating" logic in reducers. It
  // doesn't actually mutate the state because it uses the Immer library,
  // which detects changes to a "draft state" and produces a brand new
  // immutable state based off those changes
  reducers: {
    replaceAppointments: (state, action) => {
      state.entities = action.payload;
    },
    addAppointment: (state, action) => {
      state.entities.push(action.payload);
    },
    replaceAppointment: (state, action) => {
      const index = state.entities.findIndex(
        (el) => el._id === action.payload._id
      );
      if (index !== -1) {
        state.entities[index] = action.payload;
      } else {
        state.entities.push(action.payload);
      }
    },
    addBookingHeldAppointment: (state, action) => {
      // Remove any BOOKING_HELD appointments
      const appointments = state.entities.filter(
        (el) => el.status !== AppointmentStatuses.BOOKING_HELD
      );

      appointments.push(action.payload);
      state.entities = appointments;
    },
  },
  extraReducers: {
    [getGpLetterByAccountId.fulfilled]: (state, action) => {
      const { url, appointmentId } = action.payload;
      const index = state.entities.findIndex((el) => el._id === appointmentId);
      if (index !== -1) state.entities[index].gpLetterUrl = url;
    },
  },
});

export const {
  replaceAppointments,
  addAppointment,
  addBookingHeldAppointment,
  replaceAppointment,
} = appointmentsSlice.actions;

const sortAppointmentsByStartAt = (appointments) => {
  return appointments.sort((a, b) =>
    dayjs(a.startAt).isBefore(b.startAt) ? -1 : 1
  );
};

// Selectors ******************************************************************
// The functions below are called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`

export const selectAllAppointments = (state) => {
  return state.appointments.entities;
};

export const selectBookingHeldAppointment = (state) => {
  return state.appointments.entities.find(
    (el) => el.status === AppointmentStatuses.BOOKING_HELD
  );
};

export const selectUpcomingAppointments = (state) => {
  const filtered = state.appointments.entities.filter((el) => {
    const isBooked = [
      AppointmentStatuses.BOOKED,
      AppointmentStatuses.IN_PROGRESS,
    ].includes(el.status);
    const isFuture = dayjs(el.endAt).isAfter(dayjs());
    return isBooked && isFuture;
  });
  return sortAppointmentsByStartAt(filtered);
};

export const selectPastAppointments = (state) => {
  const filtered = state.appointments.entities
    .filter((el) => {
      const statuses = [
        AppointmentStatuses.PATIENT_NO_SHOW,
        AppointmentStatuses.WAITING_ON_PRACTITIONER,
        AppointmentStatuses.COMPLETED,
      ];
      return statuses.includes(el.status);
    })
    .sort((a, b) => (dayjs(a.startAt).isBefore(b.startAt) ? 1 : -1));
  return filtered;
};

export const selectCancelledAppointments = (state) => {
  const filtered = state.appointments.entities
    .filter((el) => {
      const statuses = [AppointmentStatuses.CANCELLED];
      return statuses.includes(el.status);
    })
    .sort((a, b) => (dayjs(a.startAt).isBefore(b.startAt) ? 1 : -1));
  return filtered;
};

export default appointmentsSlice.reducer;
