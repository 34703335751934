import './MenstrualCycle.scss';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import TextTitle from '../../../elements/TextTitle';
import TextBody from '../../../elements/TextBody';
import Content from '../../../elements/Content';
import Button from '../../../elements/Button';
import LoadingSpinner from '../../../elements/LoadingSpinner';
import {
  replacePatient,
  PatientLastPeriodRange,
  selectPatient,
} from '../../../../slices/patientSlice';
import AlertError from '../../../elements/alert/Error';
import { patch } from '../../../../services/api/apiPatient';
import { trackEvent, TrackEventNames } from '../../../../services/tracking';
import StepHeader from '../../../elements/StepHeader';
import Radio from '../../../elements/form/Radio';

const MenstrualCycle = (props) => {
  const { onStageComplete } = props;
  const patient = useSelector(selectPatient);
  const dispatch = useDispatch();
  const { register, errors, handleSubmit } = useForm({
    defaultValues: {
      lastPeriodRange: patient.lastPeriodRange,
    },
  });

  const [requestInProgress, setRequestInProgress] = useState(false);
  const [apiError, setApiError] = useState();

  const patchRequest = async (data) => {
    setRequestInProgress(true);

    try {
      const response = await patch(data);
      dispatch(replacePatient(response.patient));

      trackEvent(TrackEventNames.BOOK_V2__STEP_9__MENSTRUAL__COMPLETED);

      onStageComplete();
    } catch (err) {
      setApiError(
        'There was an error updating your menstrual details. Please try again.'
      );

      window.scrollTo(0, 0);
      setRequestInProgress(false);
    }
  };

  const onSubmitForm = (data) => {
    if (requestInProgress) {
      return;
    }
    patchRequest({
      attributesToUpdate: {
        lastPeriodRange: data.lastPeriodRange,
        lastPeriodSelectedDate: Math.floor(Date.now() / 1000),
      },
    });
  };

  const onCloseAlertError = () => {
    setApiError(null);
  };

  return (
    <div className="book-menstrual-cycle">
      <Content>
        <StepHeader currentStep={7} />
        <TextTitle type="sub" className="book-menstrual-cycle__sub-title">
          Your female health history
        </TextTitle>
      </Content>
      <Content>
        {apiError && (
          <AlertError label={apiError} onClose={onCloseAlertError} />
        )}

        <form onSubmit={handleSubmit(onSubmitForm)}>
          <TextBody>What date did your last period start?</TextBody>
          <Radio
            name="lastPeriodRange"
            values={Object.keys(PatientLastPeriodRange).map((key) => {
              return {
                label: key,
                value: PatientLastPeriodRange[key],
              };
            })}
            error={errors.lastPeriodRange}
            registerRef={register({ required: 'This field is required.' })}
            threeColumn
          />

          {requestInProgress === true ? (
            <LoadingSpinner />
          ) : (
            <Button type="submit">Continue</Button>
          )}
        </form>
      </Content>
    </div>
  );
};

MenstrualCycle.propTypes = {
  onStageComplete: PropTypes.func.isRequired,
};

export default MenstrualCycle;
