import Bowser from 'bowser';

const browser = Bowser.getParser(window.navigator.userAgent);

// AttendAnywhere is only supported for Safari and Chrome.
// We allow a larger list of browser for general clinic usage so appointments
// can be booked but user will need to use one of the following browsers
// for the appointment video call
// eslint-disable-next-line import/prefer-default-export
export const isSupportedBrowser = () => {
  const { name } = browser.getBrowser();
  return name !== 'Internet Explorer';
};
