import { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { Auth } from '@aws-amplify/auth';
import TextTitle from '../../elements/TextTitle';
import TextBody from '../../elements/TextBody';
import Button from '../../elements/Button';
import TextInput from '../../elements/form/TextInput';
import FormError from '../../elements/form/FormError';
import LoadingSpinner from '../../elements/LoadingSpinner';

import { trackEvent, TrackEventNames } from '../../../services/tracking';

const ForgottenPasswordEmail = (props) => {
  const { onStageComplete } = props;
  const { register, handleSubmit, errors } = useForm({});
  const [globalFormError, setGlobalFormError] = useState(null);
  const [requestInProgress, setRequestInProgress] = useState(false);

  const forgottenPasswordInitRequest = async (email) => {
    setRequestInProgress(true);
    try {
      await Auth.forgotPassword(email);
      // Auth.for
      // await forgottenPasswordInit(email, password);

      trackEvent(TrackEventNames.FORGOT_PASSWORD__STEP_1__COMPLETED);

      onStageComplete({ email });
    } catch (err) {
      setGlobalFormError(
        <p>
          Something went wrong, please try again. If the problem continues
          please email{' '}
          <a href="mailto:clinic@healthandher.com">clinic@healthandher.com</a>.
        </p>
      );
      setRequestInProgress(false);
      window.scrollTo(0, 0);
    }
  };

  const onSubmitForm = (data) => {
    if (requestInProgress) {
      return;
    }

    const { email, password } = data;
    setGlobalFormError(null);
    forgottenPasswordInitRequest(email, password);
  };

  return (
    <>
      <TextTitle centerText>Forgotten Password</TextTitle>

      <TextBody centerText>
        Please enter the email address of your account to request a password
        reset.
      </TextBody>

      <form
        className="forgotten-password-form"
        onSubmit={handleSubmit(onSubmitForm)}
      >
        {globalFormError !== null && <FormError>{globalFormError}</FormError>}

        <TextInput
          label="Email Address"
          type="text"
          name="email"
          error={errors.email}
          registerRef={register({
            required: 'This field is required.',
            maxLength: {
              value: 254,
              message: 'Too many characters (max 254).',
            },
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'Must be a valid email address.',
            },
          })}
        />

        <div className="forgotten-password__actions">
          {requestInProgress === true ? (
            <LoadingSpinner />
          ) : (
            <Button type="submit">Request Password Reset</Button>
          )}
        </div>
      </form>
    </>
  );
};

ForgottenPasswordEmail.propTypes = {
  onStageComplete: PropTypes.func.isRequired,
};

export default ForgottenPasswordEmail;
