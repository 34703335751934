import './Faq.scss';
import { useHistory } from 'react-router-dom';
import Content from '../elements/Content';
import PageHeader from '../elements/PageHeader';
import FaqContent from '../content/Faq';
import NavBar from '../navigation/NavBar';
import TextTitle from '../elements/TextTitle';

const Faq = () => {
  const history = useHistory();

  const onClickBack = () => {
    history.push('/help');
  };

  return (
    <>
      <PageHeader showBack onClickBack={onClickBack} />
      <div className="help-faq">
        <Content>
          <TextTitle type="sub">FAQs</TextTitle>

          <FaqContent />
        </Content>
      </div>
      <NavBar active="HELP" />
    </>
  );
};

export default Faq;
