import { configureStore } from '@reduxjs/toolkit';
import registerReducer from '../slices/registerSlice';
import patientReducer from '../slices/patientSlice';
import practitionersReducer from '../slices/practitionersSlice';
import medicalHistoriesReducer from '../slices/medicalHistoriesSlice';
import contraceptionsReducer from '../slices/contraceptionsSlice';
import hrtsReducer from '../slices/hrtsSlice';
import medicalProceduresReducer from '../slices/medicalProceduresSlice';
import menopauseSymptomsReducer from '../slices/menopauseSymptomsSlice';
import scheduleReducer from '../slices/scheduleSlice';
import appointmentsReducer from '../slices/appointmentsSlice';
import contactTopicReducer from '../slices/contactTopicsSlice';
import settingsReducer from '../slices/settingsSlice';

export default configureStore({
  reducer: {
    register: registerReducer,
    patient: patientReducer,
    practitioners: practitionersReducer,
    medicalHistories: medicalHistoriesReducer,
    contraceptions: contraceptionsReducer,
    hrts: hrtsReducer,
    medicalProcedures: medicalProceduresReducer,
    menopauseSymptoms: menopauseSymptomsReducer,
    schedule: scheduleReducer,
    appointments: appointmentsReducer,
    contactTopics: contactTopicReducer,
    settings: settingsReducer,
  },
});
