import './Profile.scss';
import { useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import Content from '../../elements/Content';
import PageHeader from '../../elements/PageHeader';
import Button from '../../elements/Button';
import { selectPatient, replacePatient } from '../../../slices/patientSlice';
import { patch } from '../../../services/api/apiPatient';
import AlertError from '../../elements/alert/Error';
import FormProfile from '../../forms/PatientProfile';
import TextTitle from '../../elements/TextTitle';

dayjs.extend(utc);

const Profile = (props) => {
  const { onClickBack } = props;
  const dispatch = useDispatch();
  const [apiError, setApiError] = useState();
  const patient = useSelector(selectPatient);

  const dobDate = dayjs(patient.dob);
  const defaultValueDob = {
    day: dobDate.date(),
    month: dobDate.month(),
    year: dobDate.year(),
  };

  const { register, handleSubmit, errors, formState, getValues } = useForm({
    defaultValues: {
      firstName: patient.firstName,
      middleName: patient.middleName,
      lastName: patient.lastName,
      email: patient.email,
      dob: defaultValueDob,
      phoneNumber: patient.phoneNumber,
    },
  });
  const { dirtyFields } = formState;

  const saveRequest = async (data) => {
    try {
      const response = await patch(data);
      dispatch(replacePatient(response.patient));
      onClickBack();
    } catch (err) {
      setApiError(
        'There was an error updating your profile. Please try again.'
      );
    }
  };

  const onSubmitForm = (data) => {
    const attributesToUpdate = Object.keys(dirtyFields).reduce((acc, cur) => {
      if (cur === 'dob') {
        const dob = dayjs()
          .utc()
          .startOf('day')
          .month(data.dob.month)
          .year(data.dob.year)
          .date(data.dob.day)
          .toISOString();

        acc[cur] = dob;
      } else {
        acc[cur] = data[cur];
      }
      return acc;
    }, {});
    saveRequest({ attributesToUpdate });
  };

  const onCloseAlertError = () => {
    setApiError(null);
  };

  return (
    <>
      <PageHeader showBack onClickBack={onClickBack} />
      <div className="account-profile">
        <Content>
          {apiError && (
            <AlertError label={apiError} onClose={onCloseAlertError} />
          )}

          <TextTitle type="sub">Your Profile</TextTitle>

          <form onSubmit={handleSubmit(onSubmitForm)}>
            <FormProfile
              errors={errors}
              register={register}
              getValues={getValues}
            />

            <Button type="submit">Save</Button>
          </form>
        </Content>
      </div>
    </>
  );
};

Profile.propTypes = {
  onClickBack: PropTypes.func.isRequired,
};

export default Profile;
