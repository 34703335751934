import './PageHeader.scss';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Logo from './Logo';
import TextTitle from './TextTitle';

const PageHeader = (props) => {
  const { title, showBack, onClickBack } = props;
  const history = useHistory();

  const onClickLogo = () => {
    history.push('/');
  };

  let pageTitle = title;
  if (showBack && pageTitle.length === 0) {
    pageTitle = <span className="page-header__back-title">Back</span>;
  }

  return (
    <div className="page-header">
      {showBack && !!onClickBack ? (
        <ArrowBackIosIcon
          className="page-header__back-icon"
          onClick={onClickBack}
        />
      ) : null}
      <TextTitle className="page-header__title" onClick={onClickBack}>
        {pageTitle}
      </TextTitle>
      <button type="button" className="page-header__logo" onClick={onClickLogo}>
        <Logo />
      </button>
    </div>
  );
};

PageHeader.propTypes = {
  title: PropTypes.string,
  showBack: PropTypes.bool,
  onClickBack: PropTypes.func,
};

PageHeader.defaultProps = {
  title: '',
  showBack: false,
  onClickBack: null,
};

export default PageHeader;
