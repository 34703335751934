import './Login.scss';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Auth } from '@aws-amplify/auth';
import { Login as LoginForm } from '@hh/clinic-app-common';
import packageInfo from '../../../../package.json';
import { fetchPatient } from '../../../slices/patientSlice';
import Logo from '../../elements/Logo';
import TextTitle from '../../elements/TextTitle';
import TextBody from '../../elements/TextBody';
import Button from '../../elements/Button';
import { trackEvent, TrackEventNames } from '../../../services/tracking';

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [userCache, setUserCache] = useState({});

  const { version } = packageInfo;

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log(`Clinic App: v${version}`);
  }, []);

  const onComplete = () => {
    dispatch(fetchPatient());
    history.push('/');
  };

  const loginEmailPasswordRequest = async (
    email,
    password,
    setRequestInProgress,
    onStageComplete,
    setGlobalFormError
  ) => {
    setRequestInProgress(true);
    try {
      let user;
      await Auth.signIn(email, password).then((u) => {
        user = u;
      });
      trackEvent(TrackEventNames.LOGIN__STEP_1__COMPLETED);
      setUserCache({ email, password }); // Cache this, as we might have to retry quietly in 2fa challenge if MFA token takes too long to arrive
      onStageComplete({ email, user });
    } catch (err) {
      trackEvent(TrackEventNames.LOGIN__STEP_1__FAILED);

      const errorMessages = [
        <p key="incorrect">
          The email address and password you have entered do not match our
          records. Please try again or{' '}
          <Link to="/register">register a new account</Link>
        </p>,
      ];

      setGlobalFormError(errorMessages);
      window.scrollTo(0, 0);
      setRequestInProgress(false);
    }
  };

  const login2FactorRequest = async (
    user,
    login2FactorCode,
    setRequestInProgress,
    onStageComplete,
    setGlobalFormError
  ) => {
    setRequestInProgress(true);
    try {
      await Auth.sendCustomChallengeAnswer(user, login2FactorCode);
      trackEvent(TrackEventNames.LOGIN__STEP_2__COMPLETED);
      onStageComplete();

      // If we failed, did we fail due to a cognito session timeout?
    } catch (err) {
      console.log('Error occured', err);

      // to help our user journey, and let them re-use the new persistent tokens, let's retry auth and quietly refresh the session if we have timed out
      if (err.toString().includes('NotAuthorizedException')) {
        try {
          // Try the process again using the cached creds
          const { email, password } = userCache;
          await Auth.signIn(email, password).then((u) => {
            user = u;
            Auth.sendCustomChallengeAnswer(user, login2FactorCode).then(
              () => {
                console.log('Retrying challenge');
                trackEvent(TrackEventNames.LOGIN__STEP_2__COMPLETED);
                onStageComplete();
              },
              () => {
                trackEvent(TrackEventNames.LOGIN__STEP_2__FAILED);

                setGlobalFormError(
                  <p>
                    Incorrect login code. Please check your email and try again.
                    If the problem continues please email{' '}
                    <a href="mailto:clinic@healthandher.com">
                      clinic@healthandher.com
                    </a>
                    .
                  </p>
                );
                setRequestInProgress(false);
                window.scrollTo(0, 0);
              }
            );
          });

          return;
        } catch (err2) {
          console.log('silent code rechallenge failed');
        }
      }

      trackEvent(TrackEventNames.LOGIN__STEP_2__FAILED);

      setGlobalFormError(
        <p>
          Incorrect login code. Please check your email and try again. If the
          problem continues please email{' '}
          <a href="mailto:clinic@healthandher.com">clinic@healthandher.com</a>.
        </p>
      );
      setRequestInProgress(false);
      window.scrollTo(0, 0);
    }
  };

  const beforeLoginForm = () => (
    <>
      <TextTitle centerText>Login</TextTitle>

      <TextBody centerText>
        Please enter the email address and password used when creating your
        Health &amp; Her Clinic account.
      </TextBody>

      <TextBody centerText>
        <strong>
          <u>Please note</u>
        </strong>
        <br />
        If you are trying to log in with existing account details linked to the
        Health &amp; Her website or app, you will need to{' '}
        <Link to="/register">register a new account</Link> for the Health &amp;
        Her Clinic.
      </TextBody>
    </>
  );
  const afterLoginForm = () => (
    <Link to="/forgotten-password" className="login__forgotten">
      <Button color="primary-link">Forgot your password?</Button>
    </Link>
  );
  const pageHeader = () => (
    <div className="login__logo">
      <Logo />
    </div>
  );
  return (
    <div className="login">
      <LoginForm
        useState={useState}
        useForm={useForm}
        pageHeader={pageHeader}
        onComplete={onComplete}
        beforeLoginForm={beforeLoginForm}
        afterLoginForm={afterLoginForm}
        login2FactorRequest={login2FactorRequest}
        loginEmailPasswordRequest={loginEmailPasswordRequest}
      />
    </div>
  );
};

export default Login;
