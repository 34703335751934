import { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import TextTitle from '../../elements/TextTitle';
import TextBody from '../../elements/TextBody';
import Button from '../../elements/Button';
import StepHeader from '../../elements/StepHeader';
import AlertError from '../../elements/alert/Error';
import Content from '../../elements/Content';
import LoadingSpinner from '../../elements/LoadingSpinner';
import FormPatientAddress from '../../forms/PatientAddress';
import { trackEvent, TrackEventNames } from '../../../services/tracking';
import { selectPatient, replacePatient } from '../../../slices/patientSlice';
import { patch } from '../../../services/api/apiPatient';

const YourAddress = (props) => {
  const { onStageComplete } = props;
  const patient = useSelector(selectPatient);
  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      line1: patient.address && patient.address.line1,
      line2: patient.address && patient.address.line2,
      city: patient.address && patient.address.city,
      region: patient.address && patient.address.region,
      postcode: patient.address && patient.address.postcode,
      confirmAddressResidentUk:
        patient.address && patient.address.country === 'GB',
    },
  });

  const [requestInProgress, setRequestInProgress] = useState(false);
  const [apiError, setApiError] = useState();
  const dispatch = useDispatch();

  const patchRequest = async (data) => {
    setRequestInProgress(true);

    try {
      const response = await patch(data);
      dispatch(replacePatient(response.patient));

      trackEvent(TrackEventNames.BOOK_V2__STEP_3__YOUR_ADDRESS__COMPLETED);

      onStageComplete();
    } catch (err) {
      console.log(err);
      setApiError(
        'There was an error updating your address. Please try again.'
      );

      window.scrollTo(0, 0);
      setRequestInProgress(false);
    }
  };

  const onSubmitForm = (data) => {
    if (requestInProgress) {
      return;
    }

    const { confirmAddressResidentUk, ...restOfAddress } = data;

    patchRequest({
      attributesToUpdate: {
        address: {
          ...restOfAddress,
          country: 'GB',
        },
      },
    });
  };

  const onCloseAlertError = () => {
    setApiError(null);
  };

  const hasAddress =
    patient.address &&
    patient.address.line1 &&
    patient.address.line1.length > 0;

  return (
    <div className="book-address">
      <Content>
        <StepHeader currentStep={1} />
        <TextBody>
          {/* Check if patient is coming back through booking process, ask them to check all details are correct if so */}
          {hasAddress
            ? 'To confirm your booking, please check and update your profile to ensure that we hold the most accurate and up to date information.'
            : 'To confirm this booking and complete your profile, we just need a few more details.'}
        </TextBody>

        <TextTitle type="sub" className="book-about-gp__sub-title">
          Please complete your address
        </TextTitle>
      </Content>
      <Content>
        {apiError && (
          <AlertError label={apiError} onClose={onCloseAlertError} />
        )}

        <form onSubmit={handleSubmit(onSubmitForm)}>
          <FormPatientAddress errors={errors} register={register} />

          <div className="book-actions">
            {requestInProgress === true ? (
              <LoadingSpinner />
            ) : (
              <Button type="submit">Continue</Button>
            )}
          </div>
        </form>
      </Content>
    </div>
  );
};

YourAddress.propTypes = {
  onStageComplete: PropTypes.func.isRequired,
};

export default YourAddress;
