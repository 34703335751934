import TextBody from '../elements/TextBody';

const EmergencyContactInfo = () => {
  return (
    <TextBody>
      Please provide here the contact details for a partner, spouse, relative or
      close friend whom we can contact in the very unlikely event of us needing
      to liaise with them regarding a medical emergency. Please be reassured
      that we will only use these contact details in the event of an
      emergency/very urgent situation, i.e. when we are concerned about your
      safety
    </TextBody>
  );
};

export default EmergencyContactInfo;
