import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { trackPageView } from '../../../services/tracking';

// eslint-disable-next-line import/prefer-default-export
export function GoogleAnalytics() {
  const location = useLocation();

  useEffect(() => {
    if (!location) return;
    // Initialize google analytics page view tracking

    // Track page views manually
    trackPageView(window.location.href, location.pathname);
  }, [location]);

  // see app-patient/.env.*
  const googleAnalyticsGa4Id = process.env.REACT_APP_GOOGLE_ANALYTICS_V4_ID;
  const googleAnalyticsUaId = process.env.REACT_APP_GOOGLE_ANALYTICS_UA_ID;
  const googleAdwordsId = process.env.REACT_APP_GOOGLE_ADWORDS_ID;

  if (!googleAnalyticsGa4Id || !googleAnalyticsUaId) {
    return null;
  }

  // Setup the gtag code and configure google analytics Id
  let code = `window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                // App will track these manually
                gtag('config', '${googleAnalyticsGa4Id}', {
                  send_page_view: false
                });
                gtag('config', '${googleAnalyticsUaId}', {
                  send_page_view: false
                });`;

  // Add in google adwords Id if it's set
  if (googleAdwordsId) {
    code += `gtag('config', '${googleAdwordsId}');`;
  }

  return (
    <Helmet>
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsGa4Id}`}
      />
      <script>{code}</script>
    </Helmet>
  );
}
