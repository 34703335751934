import PropTypes from 'prop-types';
import TextInput from '../elements/form/TextInput';
import FormDob from './Dob';

const PatientProfile = (props) => {
  const { errors, register, getValues } = props;

  return (
    <>
      <TextInput
        label="First Name"
        type="text"
        name="firstName"
        error={errors.firstName}
        registerRef={register({
          required: 'This field is required.',
          maxLength: {
            value: 200,
            message: 'Too many characters (max 200).',
          },
        })}
        required
      />

      <TextInput
        label="Middle Name"
        type="text"
        name="middleName"
        error={errors.middleName}
        registerRef={register({
          maxLength: {
            value: 200,
            message: 'Too many characters (max 200).',
          },
        })}
      />

      <TextInput
        label="Last Name"
        type="text"
        name="lastName"
        error={errors.lastName}
        registerRef={register({
          required: 'This field is required.',
          maxLength: {
            value: 200,
            message: 'Too many characters (max 200).',
          },
        })}
        required
      />

      <FormDob
        register={register}
        error={errors.dob}
        getValues={getValues}
        required
      />

      <TextInput
        label="Phone Number"
        type="text"
        name="phoneNumber"
        error={errors.phoneNumber}
        registerRef={register({
          required: 'This field is required.',
          maxLength: {
            value: 20,
            message: 'Too many characters (max 20).',
          },
        })}
        required
      />
    </>
  );
};

PatientProfile.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  errors: PropTypes.object.isRequired,
  register: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
};

export default PatientProfile;
